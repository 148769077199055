define("bizbase3/mixins/categories-controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Evented = Ember.Evented,
      get = Ember.get,
      set = Ember.set,
      log = Ember.Logger,
      RSVP = Ember.RSVP;
  exports.default = Ember.Mixin.create(_notable.default, Evented, {
    actions: {
      createNode: function createNode(name, parentId) {
        if (!parentId) {
          name = name.name;
          parentId = name.parentId;
        }

        this.send("createCategory", name, parentId);
      },
      deleteNode: function deleteNode(node) {
        var _this = this;

        var name = get(node, "name");
        node.destroyRecord().then(function () {
          _this.success("Kategorie \"" + name + "\" gel\xF6scht");
        }).catch(function (reason) {
          log.error(reason);
          _this.error("Fehler beim l\xF6schen der Kategorie \"" + name + "\"");
        });
      },
      renameNode: function renameNode(node, newName) {
        var _this2 = this;

        var oldName = get(node, "name");

        set(node, "name", newName);

        node.save().then(function () {
          _this2.success("\"" + oldName + "\" in \"" + newName + "\" umbenannt");
        }).catch(function () {
          _this2.error("Fehler beim Umbennen der Kategorie \"" + oldName + "\"");
        });
      },
      dropAction: function dropAction(action, params, nodeId) {
        // TODO: upload to category
        var actionFn = this.get("dropActions." + action);

        if (typeof actionFn !== "function") {
          Ember.assert("Unknown dropAction \"" + action + "\"", false);
        }

        return actionFn.apply(this, [nodeId].concat(params));
      }
    },

    dropActions: {
      moveCategory: function moveCategory(categoryId, movedCategory) {
        var _this3 = this;

        var newParent = this.get("store").peekRecord("category", categoryId),
            category = this.get("store").peekRecord("category", movedCategory),
            childIds = get(category, "descendants").mapBy("id");

        if (get(category, "id") === categoryId || childIds.includes(categoryId)) {
          return RSVP.reject();
        }

        category.set("parent", newParent);
        return category.save().then(function () {
          return _this3.success(get(category, "name") + " nach " + get(newParent, "name") + " verschoben");
        }).catch(function () {
          return _this3.error("Fehler beim Verschieben von " + get(category, "name"));
        });
      },
      moveToCategory: function moveToCategory(categoryId, dataIds) {
        var _this4 = this;

        var category = this.get("store").peekRecord("category", categoryId);
        return RSVP.all(dataIds.map(function (dataId) {
          var record = _this4.get("store").peekRecord(_this4.get("dataModelName"), dataId);
          record.set("category", category);

          return record.save();
        })).then(function (result) {
          _this4.categoryMoveSuccess(category, result);
          return result;
        }).catch(function (error) {
          _this4.categoryMoveError(category, error);
          return error;
        });
      }
    },

    pageTitle: computed(function () {
      Ember.assert("You need to specify a pageTitle when using the categories-controller mixin", false);
    }),

    targetRoute: computed(function () {
      Ember.assert("You need to specify a targetRoute when using the categories-controller mixin", false);
    }),

    titleIcon: computed(function () {
      Ember.assert("You need to specify a titleIcon when using the categories-controller mixin", false);
    }),

    rootCategories: computed("model.@each.isRoot", function () {
      return this.get("model").filterBy("isRoot", true);
    }),

    currentCategory: computed.reads("detailModel")
  });
});
define("bizbase3/pods/group/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    indexController: inject.controller("group/index"),
    editFormIsDirty: computed.reads("indexController.editFormIsDirty")
  });
});
define("bizbase3/mirage/factories/contact", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    firstName: _emberCliMirage.faker.name.firstName,
    lastName: _emberCliMirage.faker.name.lastName,
    company: _emberCliMirage.faker.company.bs,
    street1: _emberCliMirage.faker.address.streetName,
    street2: _emberCliMirage.faker.address.secondaryAddress,
    postcode: function postcode() {
      return _emberCliMirage.faker.address.zipCode("#####");
    },
    city: function city() {
      return _emberCliMirage.faker.address.city();
    },

    country: _emberCliMirage.faker.address.country,

    email: _emberCliMirage.faker.internet.email,
    phone: function phone() {
      return _emberCliMirage.faker.phone.phoneNumber("+49 ##########");
    },
    mobile: function mobile() {
      return _emberCliMirage.faker.phone.phoneNumber("+49 17# #######");
    },
    fax: function fax() {
      return _emberCliMirage.faker.phone.phoneNumber("+49 ##########");
    },


    mediaType: _emberCliMirage.faker.name.jobTitle,
    mediaTitle: _emberCliMirage.faker.hacker.phrase,
    department: _emberCliMirage.faker.name.jobArea,

    notes: _emberCliMirage.faker.lorem.paragraph,

    category: null
  });
});
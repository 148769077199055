define("bizbase3/mirage/fixtures/settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var navigation = [{
    permission: "dashboard.read",
    icon: "home",
    linkTo: "dashboard",
    title: "modules.dashboard.name"
  }, {
    permission: "classic.read",
    icon: "th-large",
    linkTo: "classic",
    title: "modules.dashboard.name"
  }, {
    permission: "knowledgebase.read",
    icon: "book",
    linkTo: "knb-categories",
    title: "modules.knb.name"
  }, {
    permission: "contacts.read",
    icon: "address-book",
    linkTo: "contact-categories",
    title: "modules.contacts.name"
  }, {
    permission: "press.read",
    icon: "comment",
    linkTo: "press-requests",
    title: "modules.pressrequests.name",
    params: {
      seg1: 1
    }
  }, {
    permission: "press.read",
    icon: "camera",
    linkTo: "press-requests",
    params: {
      seg1: 16
    },
    title: "modules.pressrequests.shooting"
  }, {
    permission: "filemanager.read",
    icon: "folder-open",
    linkTo: "asset-categories",
    title: "modules.filemanager.name"
  }, {
    permission: "filemanager.read",
    icon: "newspaper-o",
    linkTo: "assets",
    params: {
      seg1: 2
    },
    title: "Clippings"
  }, {
    permission: "stores.read",
    icon: "shopping-cart",
    linkTo: "store-categories",
    title: "modules.stores.name"
  }, {
    permission: "calendar.read",
    icon: "calendar",
    linkTo: "calendar-projects",
    title: "modules.calendar.name"
  }, {
    permission: "glm.write",
    icon: "users",
    linkTo: "glm",
    title: "modules.glm.name"
  }, {
    permission: "globalsearch",
    icon: "search",
    linkTo: "search",
    title: "modules.calendar.name"
  }];

  var classicnavigation = [{
    permission: "knowledgebase.read",
    icon: "book",
    linkTo: "knb-categories",
    title: "modules.knb.name"
  }, {
    permission: "contacts.read",
    icon: "address-book",
    linkTo: "contact-categories",
    title: "modules.contacts.name"
  }, {
    permission: "press.read",
    icon: "comment",
    linkTo: "press-requests",
    title: "modules.pressrequests.name",
    params: {
      seg1: 1
    }
  }, {
    permission: "press.read",
    icon: "camera",
    linkTo: "press-requests",
    params: {
      seg1: 16
    },
    title: "modules.pressrequests.shooting"
  }, {
    permission: "filemanager.read",
    icon: "folder-open",
    linkTo: "asset-categories",
    title: "modules.filemanager.name"
  }, {
    permission: "filemanager.read",
    icon: "newspaper-o",
    linkTo: "assets",
    params: {
      seg1: 2
    },
    title: "Clippings"
  }, {
    permission: "stores.read",
    icon: "shopping-cart",
    linkTo: "store-categories",
    title: "modules.stores.name"
  }, {
    permission: "calendar.read",
    icon: "calendar",
    linkTo: "calendar-projects",
    title: "modules.calendar.name"
  }, {
    permission: "globalsearch",
    icon: "search",
    linkTo: "search",
    title: "modules.calendar.name"
  }, {
    permission: "settings.read",
    icon: "wrench",
    linkTo: "settings",
    title: "modules.settings.name"
  }, {
    permission: "glm.write",
    icon: "users",
    linkTo: "glm",
    title: "modules.glm.name"
  }];

  exports.default = [{
    key: "BIZBASE3.assets.usageConfig",
    content: "{\"config\": [\"Intern + Extern\", \"Intern\", \"Extern\"] }"
  }, {
    key: "BIZBASE3.press-requests.stateConfig",
    content: "{\"config\": [\"Offen\", \"In Bearbeitung\", \"Beantwortet\", \"Erledigt\"] }"
  }, {
    key: "BIZBASE3.press-requests.stateConfigClass",
    content: "{\"config\": [\"primary\", \"warning\", \"success\", \"success\"] }"
  }, {
    key: "BIZBASE3.main-navigation",
    content: "{\"config\":" + JSON.stringify(navigation) + "}"
  }, {
    key: "BIZBASE3.classic-navigation",
    content: "{\"config\":" + JSON.stringify(classicnavigation) + "}"
  }];
});
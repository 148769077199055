define("bizbase3/components/modal-close-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ["dataDismiss:data-dismiss", "title", "type"],

    tagName: "button",

    title: "Schließen",

    type: "button",

    dataDismiss: "modal"
  });
});
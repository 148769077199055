define("bizbase3/pods/lostpassword/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      isEmpty = Ember.isEmpty;
  exports.default = Ember.Controller.extend({
    actions: {
      resetpassword: function resetpassword() {
        var _this = this;

        var _getProperties = this.getProperties("email"),
            email = _getProperties.email,
            emptyEmail = isEmpty(email);

        this.setProperties({ emptyEmail: emptyEmail, errorMessage: false });

        if (!this.get("hasError")) {
          this.set("isPending", true);

          this.get("password").reset(email).then(function () {
            _this.set("isPending", false);
            _this.set("email", "");
            _this.set("error", false);
            _this.set("success", true);
            setTimeout(function () {
              _this.transitionToRoute("login");
            }, 2000);
          }, function () {
            _this.set("isPending", false);
            _this.set("error", true);
            _this.set("success", false);
          });
        }
      }
    },

    error: false,
    success: false,

    hasError: computed.or("emptyEmail"),

    isPending: false,

    password: inject.service()
  });
});
define("bizbase3/services/press-requests-columns", ["exports", "moment", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service"], function (exports, _moment, _settingsHandler, _columnsService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed,
      service = Ember.inject.service;


  function formatDate(date) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "LL";

    return date ? (0, _moment.default)(date).format(format) : "-";
  }

  function formatDateTime(date) {
    return formatDate(date, "LLL");
  }

  var COLUMNS = [{ key: "contact.fullName", linkable: true }, { key: "externalContact.fullName", linkable: true }, { key: "responsibleContact.fullName", linkable: true },
  // TODO: path can be undefined when category is async and not loaded  yet
  { key: "category.path", format: function format(path) {
      return (path ? path : []).join(" / ");
    }, linkable: false }, { key: "receiptDate", format: formatDate, linkable: true }, { key: "publicationDate", format: formatDate }, { key: "respondUntil", format: formatDateTime }, { key: "personCount", format: formatDateTime }, { key: "duration", format: formatDateTime }, {
    key: "tags",
    format: function format(tag) {
      return tag.map(function (t) {
        return t.get("tag");
      }).join(", ");
    }
  }, { key: "doneAt", format: formatDate, linkable: true }];

  exports.COLUMNS = COLUMNS;


  var STORAGE_KEY = "BIZBASE3.press-requests.columnConfig";

  var MODULE_NAME = "pressrequests";

  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    pressRequestStateLabels: service(),

    storageKey: STORAGE_KEY,

    moduleName: MODULE_NAME,

    _columns: computed("pressRequestStateLabels.config.[]", function () {
      var config = this.get("pressRequestStateLabels.config");

      return [{
        key: "state",
        format: function format(state) {
          return config[state];
        }
      }].concat(COLUMNS);
    })
  });
});
define("bizbase3/pods/components/autofocus-datepicker/component", ["exports", "bizbase3/components/bootstrap-datepicker"], function (exports, _bootstrapDatepicker) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = _bootstrapDatepicker.default.extend({
    autoFocus: on("didInsertElement", function () {
      this.$().focus();
    })
  });
});
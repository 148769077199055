define("bizbase3/pods/components/header-flyout-search/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend({
    actions: {
      search: function search() {
        this.update();
      },
      changePage: function changePage(page) {
        this.set("page", page);
        this.update();
      }
    },

    q: "",

    page: 1,

    error: false,

    tagName: "li",

    classNames: ["notifications", "dropdown"],

    search: inject.service(),

    assetUsageLabels: inject.service(),

    iconTypes: {
      knb_entries: "book",
      contacts: "address-book",
      pressinquiry: "comment",
      stores: "shopping-cart",
      knb_assets: "folder-open"
    },

    textTypes: {
      knb_entries: "modules.knb.name",
      contacts: "modules.contacts.name",
      pressinquiry: "modules.pressrequests.name",
      stores: "modules.stores.name",
      knb_assets: "modules.filemanager.name"
    },

    linkTypes: {
      knb_entries: "knb-entry",
      contacts: "contact",
      pressinquiry: "press-request",
      stores: "store",
      knb_assets: "asset"
    },

    update: function update() {
      this.set("data", this.get("search").globalSearch(this.get("q"), this.get("page")));
    }
  });
});
define("bizbase3/components/drop-upload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PARAMNAME = "inputFile";

  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    attributeBindings: ["target:action"],

    /**
     * Data to be appended to the `FormData` object that is sent to the server by
     * the Dropzone plugin
     *
     * @param data
     * @default {}
     * @type Object
     */
    data: computed(function () {
      return {};
    }),

    classNames: ["dropzone", "drop-upload", "panel", "panel-default"],

    initDropzone: function () {
      var _this = this;

      var dropzone = new Dropzone("#" + this.$().attr("id"), { paramName: PARAMNAME }),
          data = this.get("data");

      dropzone.on("sending", function (file, xhr, formData) {
        for (var key in data) {
          formData.append(key, data[key]);
        }

        formData.append("alias", file.name);
      });

      dropzone.on("success", function (file, response) {
        _this.triggerUploadfile(response);
      });

      dropzone.on("queuecomplete", function () {
        _this.triggerDidFinishQueue();
      });

      this.set("dropzone", dropzone);
    }.on("didInsertElement"),

    destroyDropzone: function () {
      var dropzone = this.get("dropzone");

      dropzone.off("sending");
      dropzone.off("success");
      dropzone.off("queuecomplete");

      dropzone.disable();
    }.on("willDestroyElement"),

    tagName: "form",

    triggerUploadfile: function triggerUploadfile(response) {
      var didUploadFile = this.get("didUploadFile");

      if (typeof didUploadFile === "function") {
        return didUploadFile(response);
      }

      this.sendAction("didUploadFile", response);
    },
    triggerDidFinishQueue: function triggerDidFinishQueue() {
      var didFinishQueue = this.get("didFinishQueue");

      if (typeof didFinishQueue === "function") {
        return didFinishQueue();
      }

      this.sendAction("didFinishQueue");
    },


    target: computed(function () {
      var store = this.get("store");

      return store.adapterFor("asset").buildURL("asset");
    }),

    store: service()
  });
});
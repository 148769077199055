define("bizbase3/pods/calendar-projects/controller", ["exports", "bizbase3/mixins/user-proxy", "bizbase3/mixins/notable", "bizbase3/mixins/modal-dialog"], function (exports, _userProxy, _notable, _modalDialog) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed,
      setProperties = Ember.setProperties;
  exports.default = Ember.Controller.extend(_userProxy.default, _notable.default, _modalDialog.default, {
    startDate: null,
    viewName: "month",

    calendarHeader: {
      left: "title",
      center: "month,agendaWeek,agendaDay",
      right: "today prev,next"
    },

    rawAppointments: computed("model", "model.appointments", "model.appointments.@each.{id,title,project,start,end,user}", function () {
      return this.get("model.appointments").map(function (appointment) {
        return appointment;
      });
    }),

    appointments: computed("hiddenProjects", "hiddenUsers", "model", "model.appointments", "model.appointments.@each.{id,title,project,start,end,user}", function () {
      var _this = this;

      var appointments = this.get("model.appointments").filter(function (appointment) {
        var found = true;
        if (_this.get("hiddenProjects") && _this.get("hiddenProjects").includes(appointment.get("project.id"))) {
          return false;
        }
        if (_this.get("hiddenUsers") && _this.get("hiddenUsers").includes(appointment.get("user.id"))) {
          return false;
        }

        return found;
      }).map(function (appointment) {
        return {
          id: appointment.get("id"),
          title: appointment.get("title"),
          start: appointment.get("start"),
          end: appointment.get("end"),
          user: appointment.get("user"),
          className: appointment.get("project.color")
        };
      });

      return appointments;
    }),

    newAppointment: null,

    actions: {
      smallSelect: function smallSelect(date) {
        this.set("startDate", date.format("YYYY-MM-DD"));
        this.set("viewName", "basicDay");
      },
      updateHiddenProjects: function updateHiddenProjects(selections) {
        this.set("hiddenProjects", selections.mapBy("id"));
      },
      updateHiddenUsers: function updateHiddenUsers(selections) {
        this.set("hiddenUsers", selections.mapBy("id"));
      },
      eventClick: function eventClick(event) {
        this.set("selectedAppointment", this.get("model.appointments").findBy("id", event.id));
        $("#calenderEdit").modal();
      },
      dayClick: function dayClick(date) {
        this.set("selectedDate", date);
        this.set("selectedAppointment", false);
        $("#calenderEdit").modal();
      },


      saveCalendar: function saveCalendar(data) {
        var _this2 = this;

        var appointment = this.get("selectedAppointment");
        if (!appointment) {
          appointment = this.store.createRecord("calendar-appointment");
        }
        setProperties(appointment, data);
        appointment.set("start", data.start.toDate ? data.start.toDate() : data.start);
        appointment.set("end", data.end.toDate ? data.end.toDate() : data.end);

        appointment.save().then(function () {
          _this2.success("Eintrag gespeichert");
          _this2.set("selectedAppointment", appointment);
          $("#calenderEdit").modal("hide");
        }).catch(function () {
          _this2.error("Fehler beim Speichern des Eintrags");
        });
      },

      deleteCalendar: function deleteCalendar() {
        var _this3 = this;

        var appointment = this.get("selectedAppointment");
        appointment.destroyRecord().then(function () {
          _this3.success("Eintrag wurde gelöscht");
          $("#calenderEdit").modal("hide");
        }).catch(function () {
          _this3.error("Fehler beim Löschen des Eintrags");
        });
      },
      newAppointment: function newAppointment() {
        this.set("selectedAppointment", false);
        $("#calenderEdit").modal();
      },
      focusAppointmentTitleInput: function focusAppointmentTitleInput() {
        $("#calenderEdit [placeholder=Title]").focus();
      }
    }
  });
});
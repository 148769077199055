define("bizbase3/components/off-canvas", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["off-canvas"],

    classNameBindings: ["onCanvasLeft", "onCanvasRight"],

    onCanvasLeft: true
  });
});
define("bizbase3/pods/components/request-conversation/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed,
      _get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      isEqual = Ember.isEqual,
      on = Ember.on;


  $.fn.textWidth = function textWidth() {
    var fake = $("<span>" + $(this).val() + "</span>").hide().appendTo(document.body);

    fake.css("font", $(this).css("font"));
    fake.css("white-space", "no-wrap");

    var width = fake.width();
    fake.remove();

    return width;
  };

  exports.default = Ember.Component.extend({
    actions: {
      submit: function submit() {
        var _this = this;

        if (!isEmpty(this.get("messageText"))) {
          var _getProperties = this.getProperties("messageText", "messageSenderContact", "messageDate", "isFinished"),
              messageText = _getProperties.messageText,
              messageSenderContact = _getProperties.messageSenderContact,
              messageDate = _getProperties.messageDate,
              isFinished = _getProperties.isFinished;

          this.get("onMessage")(messageText, messageSenderContact, messageDate, isFinished).then(function () {
            return _this.reset();
          });
        }
      },
      toggleExpand: function toggleExpand() {
        this.toggleExpand();
      },
      toggleAnswer: function toggleAnswer() {
        this.toggleAnswer();
      },
      onInput: function onInput(e) {
        this.set("messageText", e.target.value);

        var input = this.$("input.form-control");

        if (input.length && input.textWidth() > input.width() + 50 && !this.get("messageInputIsExpanded")) {
          this.toggleExpand();
        }
      }
    },

    toggleExpand: function toggleExpand() {
      this.toggleProperty("messageInputIsExpanded");
      this.focusInput();
    },
    toggleAnswer: function toggleAnswer() {
      this.set("messageInputIsExpanded", true);
      this.set("answer", true);
      this.focusInput();
    },
    focusInput: function focusInput() {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.$(".conversation-input").focus();
      });
    },


    classNames: ["conversation"],

    messages: computed(function () {
      return [];
    }),

    i18n: service(),

    messagesSorting: ["date:desc"],

    answer: false,

    sortedMessages: computed.sort("messages", "messagesSorting"),

    newMessage: computed("messages.@each.isNew", function () {
      return this.get("messages").findBy("isNew");
    }),

    placeholder: computed("messageSenderContact", "currentUser.user", "currentUser.fullName", {
      get: function get() {
        var messageSender = this.get("messageSenderContact");

        if (messageSender && !isEqual(messageSender, this.get("currentUser.user"))) {
          return this.get("i18n").t("modules.pressrequests.form.messageFrom", {
            name: _get(messageSender, "fullName")
          });
        }

        return this.get("i18n").t("modules.pressrequests.form.anwerAs", {
          name: this.get("currentUser.fullName")
        });
      }
    }),

    messageSenderContact: computed("messageSender", "contacts.@each.id", {
      get: function get() {
        return this.getWithDefault("contacts.data", []).findBy("id", this.get("messageSender"));
      }
    }),

    currentUser: service(),

    contacts: service(),

    loadContacts: on("didInsertElement", function () {
      this.get("contacts").retrieve();
    }),

    widgetPositioning: { vertical: "bottom" },

    reset: function reset() {
      this.setProperties({
        messageText: "",
        messageDate: null,
        messageSender: null,
        answer: false
      });
    }
  });
});
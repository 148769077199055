define("bizbase3/services/knb-columns", ["exports", "ember-data", "moment", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service"], function (exports, _emberData, _moment, _settingsHandler, _columnsService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      on = Ember.on;
  var PromiseArray = _emberData.default.PromiseArray;


  var MODULE_NAME = "knb";

  var STORAGE_KEY = "BIZBASE3.knb.columnConfig";

  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    storageKey: STORAGE_KEY,
    moduleName: MODULE_NAME,

    loadTypes: on("init", function () {
      var promise = this.get("store").findAll("custom-information-type");
      this.set("types", PromiseArray.create({ promise: promise }));
    }),

    store: inject.service(),

    _columns: computed("types.@each.{id,title,order}", function () {
      var columns = [{ key: "id", linkable: true }, { key: "title", linkable: true }, { key: "category.path", format: function format(path) {
          return path.join(" / ");
        } }];

      columns.addObjects(this.get("types").sortBy("order").map(function (type) {
        var typeId = get(type, "id");
        return { label: get(type, "title"), key: "custom-information-type-" + typeId, typeId: typeId };
      }));

      function dateFormat(date) {
        return (0, _moment.default)(date).format("LL");
      }

      columns.addObjects([{ key: "validFrom", format: dateFormat, linkable: true }, { key: "validTill", format: dateFormat, linkable: true }, { key: "created_at", format: dateFormat, linkable: false }, { key: "views", linkable: false, notSearchable: true }, { key: "user.fullName" }]);

      return columns;
    })
  });
});
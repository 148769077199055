define("bizbase3/pods/components/category-node-title/component", ["exports", "bizbase3/mixins/draggable"], function (exports, _draggable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_draggable.default, {
    actions: {
      createNode: function createNode() {
        this.get("createNode")(this.get("newName"), this.get("id"));
        this.set("newName", "");
      }
    },

    attributeBindings: ["draggable"],

    classNames: ["node-title"],

    classNameBindings: ["expanded"],

    draggable: true,

    dragData: computed("node.id", function () {
      return {
        action: "moveCategory",
        params: [this.get("node.id")]
      };
    }),

    id: computed.reads("node.id"),

    name: computed("node.name", {
      get: function get() {
        return this.get("node.name");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    createNode: function createNode() {},
    makeDragIcon: function makeDragIcon() {
      return $("<span class=\"label label-default\"><i class=\"fa fa-folder\"></i> " + this.get("node.name") + "</span>");
    }
  });
});
define("bizbase3/pods/protected/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    i18n: service(),

    splitview: service(),

    selectedLocale: null,

    init: function init() {
      var _this = this;

      this._super();
      var current = this.get("locales").find(function (loc) {
        return loc.id === _this.get("i18n.locale");
      });
      this.set("selectedLocale", current);
    },

    locales: computed("i18n.locale", "i18n.locales", function () {
      var i18n = this.get("i18n");
      return this.get("i18n.locales").map(function (loc) {
        return { id: loc, text: i18n.t("language-select." + loc) };
      });
    }),
    actions: {
      toggleView: function toggleView() {
        this.get("splitview").toggleActive();
        window.location.reload(true);
      },
      setLocale: function setLocale(locale) {
        this.set("selectedLocale", locale);
        this.set("i18n.locale", locale.id);
      }
    }
  });
});
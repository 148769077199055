define("bizbase3/services/search", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      $ = Ember.$;
  var PromiseArray = _emberData.default.PromiseArray,
      PromiseObject = _emberData.default.PromiseObject;
  exports.default = Ember.Service.extend({
    ajax: service(),

    store: service(),

    reindexUrl: "/api/v3/search/index",
    deleteUrl: "/api/v3/search/index",

    search: function search(type, query, fields, category_id) {
      var promise = this.get("store").query(type, {
        category_id: category_id,
        search: { fields: fields, query: query }
      });

      return PromiseArray.create({ promise: promise });
    },
    reindex: function reindex(type) {
      return this._callSearchMaintainAPi(type, this.get("reindexUrl"));
    },
    deleteIndex: function deleteIndex(type) {
      return this._callSearchMaintainAPi(type, this.get("deleteUrl"));
    },
    globalSearch: function globalSearch(query, page, perPage) {
      page = parseInt(page);
      perPage = parseInt(perPage);
      if (query.indexOf("exp:") === -1) {
        query = "exp:" + query;
      }
      var data = JSON.stringify({ query: query, page: page || 1, perPage: perPage || 10 });
      var promise = this.get("ajax").authorized("/api/v3/search/global", {
        type: "POST",
        contentType: "application/json",
        data: data
      });
      return PromiseObject.create({ promise: promise });
    },
    _callSearchMaintainAPi: function _callSearchMaintainAPi(type, url) {
      return $.ajax({
        type: "POST",
        url: url,
        data: JSON.stringify({ name: type, limit: 0 }),
        contentType: "application/json"
      });
    }
  });
});
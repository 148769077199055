define("bizbase3/services/modal-dialog", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed;

  var defer = Ember.RSVP.defer;

  exports.default = Ember.Service.extend({
    _modalOpenPromise: null,

    message: null,

    isOpenDialog: computed("message", function () {
      return !!this.get("message");
    }),

    confirm: function confirm(message) {
      this._modalOpenPromise = defer();
      this.open(message);

      return this._modalOpenPromise.promise;
    },
    doOk: function doOk() {
      if (this.get("isOpenDialog")) {
        this.close();
        this._modalOpenPromise.resolve();
      }
    },
    doCancel: function doCancel() {
      if (this.get("isOpenDialog")) {
        this.close();
        this._modalOpenPromise.reject();
      }
    },
    open: function open(message) {
      this.set("message", message);
    },
    close: function close() {
      this.set("message", null);
    }
  });
});
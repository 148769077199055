define("bizbase3/pods/contacts/new/controller", ["exports", "bizbase3/mixins/contact-controller"], function (exports, _contactController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_contactController.default, {
    afterSave: function afterSave() {
      this.get("contacts").updateEntries();
      this.transitionToRoute("contacts");
    }
  });
});
define("bizbase3/pods/assets/route", ["exports", "bizbase3/mixins/categorized-route", "bizbase3/mixins/notable", "bizbase3/utils/pagination-array"], function (exports, _categorizedRoute, _notable, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject,
      set = Ember.set;
  exports.default = Ember.Route.extend(_categorizedRoute.default, _notable.default, {
    ajax: inject.service(),
    actions: {
      importClippings: function importClippings(asset) {
        var _this = this;

        this.get("ajax").authorized("/api/v3/assets/" + asset.get("id") + "/import/clippings", {
          type: "GET",
          contentType: "application/json"
        }).then(function () {
          _this.success("Import abgesclossen");
          setTimeout(function () {
            _this.refresh();
          }, 3000);
        }, function () {
          _this.error("Import, Fehler");
        });
      },
      shareAsset: function shareAsset(asset) {
        var share = asset.get("shares").createRecord({
          shared: "asset",
          sharedId: asset.id
        });

        this.controller.set("newShare", share);

        share.save();
      },
      resetModel: function resetModel() {
        this.refresh();
      }
    },

    missingCategoryRoute: "asset-categories",

    categoriesControllerName: "asset-categories",

    filemanagerColumns: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("filemanagerColumns").load();
    },
    model: function model(params) {
      var category_id = params.category_id;

      var assetQuery = {
        include: "files,contacts",
        sortKey: params.sortKey,
        sortDir: params.sortDir
      },
          promiseHash = {};

      if (category_id.startsWith("album-")) {
        var album_id = category_id.replace(/^album-/, "");
        assetQuery.album_id = album_id;
        this.set("detailModelKey", "album");
        promiseHash.album = this.get("store").peekRecord("album", album_id);
      } else {
        assetQuery.category_id = category_id;
        this.set("detailModelKey", "category");
        promiseHash.category = this.get("store").peekRecord("category", category_id);
      }

      promiseHash.assets = (0, _paginationArray.makePaginationQuery)(this.get("store"), "asset", assetQuery, params.page, params.perPage);

      return hash(promiseHash);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var masterController = this.controllerFor(this.get("masterControllerName"));

      set(masterController, "detailModelKey", this.get("detailModelKey"));
    }
  });
});
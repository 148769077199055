define("bizbase3/pods/groups/controller", ["exports", "bizbase3/mixins/data-table", "bizbase3/mixins/remote-pagination", "bizbase3/mixins/master-controller", "bizbase3/mixins/item-selection", "bizbase3/mixins/sortable"], function (exports, _dataTable, _remotePagination, _masterController, _itemSelection, _sortable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend(_dataTable.default, _remotePagination.default, _masterController.default, _sortable.default, _itemSelection.default, {
    collection: computed.reads("paginationArray"),

    columnsConfig: inject.service("groups-columns"),

    customFields: computed.reads("columnsConfig.columns"),

    queryParams: [{ page: "requestPage" }, "perPage"],

    currentRoute: "groups",

    detailRoute: "group",

    dataModelName: "group",

    paginationArray: computed.reads("model.groups")
  });
});
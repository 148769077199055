define("bizbase3/pods/components/modal-dialog/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = Ember.Component.extend({
    ariaHidden: true,

    attributeBindings: ["role", "labelledby:data-labelledby", "ariaHidden:aria-hidden"],

    classNames: ["modal", "fade"],

    role: "dialog",

    setupBindings: on("didInsertElement", function () {
      var _this = this;

      this.$().on("hidden.bs.modal", function () {
        return _this.sendAction("modalHidden");
      });
      this.$().on("shown.bs.modal", function () {
        return _this.sendAction("modalShow");
      });
    })
  });
});
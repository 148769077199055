define("bizbase3/mirage/factories/message", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    text: function text() {
      var method = _emberCliMirage.faker.random.arrayElement(["paragraphs", "sentences", "sentence"]);

      if (method === "paragraphs") {
        return _emberCliMirage.faker.lorem[method](_emberCliMirage.faker.random.number({ max: 3, min: 1 }), "\n"); // default breaks tests
      }

      return _emberCliMirage.faker.lorem[method]();
    },

    date: _emberCliMirage.faker.date.past,

    author: null
  });
});
define("bizbase3/authorizers/bizbase", ["exports", "ember-simple-auth/authorizers/base", "bizbase3/session-stores/application"], function (exports, _base, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isEmpty = Ember.isEmpty;

  exports.default = _base.default.extend({
    authorize: function authorize(data, block) {
      var token = data[_application.KEY];

      if (!isEmpty(token)) {
        block("Authorization", token);
      }
    }
  });
});
define("bizbase3/session-stores/application", ["exports", "ember-simple-auth/session-stores/local-storage"], function (exports, _localStorage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.KEY = undefined;


  var KEY = "jwt";

  var isEmpty = Ember.isEmpty,
      RSVP = Ember.RSVP;
  exports.KEY = KEY;
  exports.default = _localStorage.default.extend({
    restore: function restore() {
      var data = JSON.parse(localStorage.getItem(this.key));

      if (!data) {
        data = { authenticated: { authenticator: "authenticator:bizbase" } };
        data.authenticated[KEY] = localStorage.getItem(KEY);
      }

      return RSVP.resolve(data);
    },
    persist: function persist(data) {
      var token = data.authenticated[KEY];

      if (!isEmpty(token)) {
        localStorage.setItem(KEY, token);
      }

      return this._super(data);
    }
  });
});
define("bizbase3/components/sortable-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    createWidget: function () {
      var _this = this;

      this.$().sortable(this.get("options")).on("sortstop", function () {
        _this.sendAction("sorted", _this.getNewOrder());
      });
    }.on("didInsertElement"),

    destroyWidget: function () {
      try {
        this.$().sortable("destroy");
      } catch (e) {
        //ignore error
      }
    }.on("willDestroyElement"),

    getNewOrder: function getNewOrder() {
      return this.$("[data-sort-id]").toArray().map(function (item) {
        return Ember.$(item).data("sort-id");
      });
    },


    mergedProperties: ["options"],

    options: {
      items: "> :not(.not-sortable)",
      cancel: ".not-sortable"
    },

    tagName: "ul"
  });
});
define("bizbase3/pods/components/confirm-dialog/component", ["exports", "bizbase3/mixins/modal-dialog"], function (exports, _modalDialog) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on,
      inject = Ember.inject,
      observer = Ember.observer,
      computed = Ember.computed;
  exports.default = Ember.Component.extend(_modalDialog.default, {
    actions: {
      ok: function ok() {
        this.hide();
        this.fire("Ok");
      },
      cancel: function cancel() {
        this.hide();
        this.fire("Cancel");
      }
    },

    classNames: ["confirm-dialog"],

    okLabel: "Ok",

    cancelLabel: "Abbrechen",

    isPageDialog: false,

    modalDialog: inject.service(),

    modalMessage: computed.reads("modalDialog.message"),

    openPageDialog: observer("modalMessage", "isPageDialog", function () {
      var _getProperties = this.getProperties("modalMessage", "isPageDialog"),
          modalMessage = _getProperties.modalMessage,
          isPageDialog = _getProperties.isPageDialog;

      if (isPageDialog) {
        if (modalMessage) {
          this.show();
        } else {
          this.hide();
        }
      }
    }),

    fire: function fire(event) {
      var isPageDialog = this.get("isPageDialog");

      if (isPageDialog) {
        this.get("modalDialog")["do" + event]();
      } else {
        this.sendAction("on" + event);
      }
    },


    setupBindings: on("didInsertElement", function () {
      var _this = this;

      this.$().on("hidden.bs.modal", function () {
        return _this.sendAction("modalHidden");
      });
      this.$().on("shown.bs.modal", function () {
        return _this.sendAction("modalShow");
      });
    })
  });
});
define("bizbase3/pods/asset-categories/controller", ["exports", "moment", "bizbase3/mixins/categories-controller"], function (exports, _moment, _categoriesController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend(_categoriesController.default, {
    actions: {
      shareAlbum: function shareAlbum(album) {
        var share = album.get("shares").createRecord({
          shared: "album",
          sharedId: album.get("id")
        });

        this.set("assets.newShare", share);

        return share.save();
      },
      showAlbumShare: function showAlbumShare(album) {
        this.set("assets.newShare", album.get("shares.firstObject"));
      },
      setAlbumExpiration: function setAlbumExpiration(album, expirationDate) {
        var _this = this;

        album.set("expirationDate", expirationDate);

        var promise = album.save();

        promise.then(function () {
          var dateFormatted = (0, _moment.default)(expirationDate).format("LL");
          _this.set("assets.expirationAlertVisible", true);
          _this.success("modules.filemanager.categories.notify.expireSaved", {
            title: album.get("title"),
            date: dateFormatted
          });
        }).catch(function () {
          _this.error("modules.filemanager.categories.notify.expireSavedFail", {
            title: album.get("title")
          });
        });

        return promise;
      },
      deleteAlbum: function deleteAlbum(album) {
        var _this2 = this;

        album.destroyRecord().then(function () {
          _this2.success("modules.filemanager.categories.notify.deleteSuccess", {
            title: album.get("title")
          });
        }).catch(function () {
          _this2.error("modules.filemanager.categories.notify.deleteFailure", {
            title: album.get("title")
          });
        });
      }
    },

    assets: inject.controller(),

    dataModelName: "asset",

    i18n: inject.service(),

    pageTitle: computed(function () {
      return this.get("i18n").t("modules.filemanager.name");
    }),

    targetRoute: "assets",

    titleIcon: "folder-open",

    albums: computed.filterBy("model.albums", "isNew", false),

    sortedAlbums: computed.sort("albums", "sortingAlbum"),

    sortingAlbum: ["title:asc"],

    categoryMoveSuccess: function categoryMoveSuccess(category, result) {
      this.success("modules.filemanager.categories.notify.moveSuccess", {
        name: get(result[0], "alias"),
        category: get(category, "name"),
        count: result.length
      });
      this.get("assets").updateEntries();
    },
    categoryMoveError: function categoryMoveError() /*category, error*/{
      this.error("modules.filemanager.categories.notify.moveFailure");
    },


    write: "filemanager.write"
  });
});
define("bizbase3/pods/components/header-flyout/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = Ember.Component.extend({
    tagName: "li",

    classNames: ["notifications", "dropdown"],

    _initScroller: on("didInsertElement", function () {
      Ember.run.scheduleOnce("afterRender", this, function () {
        var nicescroll_defaults = {
          cursorcolor: "#d4d4d4",
          cursorborder: "1px solid #ccc",
          autohidemode: true,
          sensitiverail: true
        };

        var s = this.$().find(".scroller");

        s.niceScroll(nicescroll_defaults);

        this.$().on("shown.bs.dropdown", function () {
          s.getNiceScroll().resize();
          s.getNiceScroll().show();
        });
      });
    })
  });
});
define("bizbase3/mixins/categories-index-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isArray = Ember.isArray;
  exports.default = Ember.Mixin.create({
    redirect: function redirect(categories) {
      if (isArray(categories)) {
        var root = categories.findBy("isRoot");
        if (root) {
          this.replaceWith(this.get("categorizedRouteName"), root.get("id"));
        }
      }
    }
  });
});
define("bizbase3/pods/press-requests/controller", ["exports", "bizbase3/mixins/data-table", "bizbase3/mixins/remote-pagination", "bizbase3/mixins/master-controller", "bizbase3/mixins/item-selection", "bizbase3/mixins/sortable", "bizbase3/mixins/searchable"], function (exports, _dataTable, _remotePagination, _masterController, _itemSelection, _sortable, _searchable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend(_dataTable.default, _remotePagination.default, _masterController.default, _sortable.default, _itemSelection.default, _searchable.default, {
    actions: {
      deleteRequests: function deleteRequests() {
        var _this = this;

        this.set("paginationArray.isPending", true);
        this.deleteSelected().finally(function () {
          return _this.set("paginationArray.isPending", false);
        });
      }
    },

    deleteRequestsConfirmMessage: computed("selection.length", "selection.firstObject.contact.fullName", function () {
      var selectedItems = this.get("selectedItems"),
          fullName = get(selectedItems, "firstObject.fullName"),
          length = get(selectedItems, "length");

      if (length !== 1) {
        return "Wollen Sie " + length + " Anfragen wirklich l\xF6schen?";
      }

      return "Soll die Anfrage von \"" + fullName + "\" wirklich gel\xF6scht werden?";
    }),

    deleteErrorMessage: "Fehler beim Löschen der Anfragen",

    getPartlyDeleteMessage: function getPartlyDeleteMessage(count, countDeleted) {
      var diff = count - countDeleted;
      return diff + " Anfrage" + (diff !== 1 ? "n" : "") + " konnte" + (diff !== 1 ? "n" : "") + " nicht gel\xF6scht werden.";
    },
    getDeleteSuccessMessage: function getDeleteSuccessMessage(count) {
      return count + " Anfrage" + (count !== 1 ? "n" : "") + " gel\xF6scht";
    },


    collection: computed.reads("paginationArray"),

    columnsConfig: inject.service("press-requests-columns"),

    customFields: computed("columnsConfig.columns", function () {
      var columns = this.get("columnsConfig.columns");
      return columns.concat([{ key: "messages.text", label: "Nachrichten", checked: true }]);
    }),

    queryParams: [{ page: "requestPage" }, "perPage"],

    currentRoute: "press-requests",

    detailRoute: "press-request",

    dataModelName: "press-request",

    paginationArray: computed.reads("model.press-requests")
  });
});
define("bizbase3/mirage/scenarios/default", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    var root = server.create("category"),
        informationTypes = server.createList("custom-information-type", 5);

    /* contacts */
    var contactGroups = server.createList("contact-group", 3),
        groupIds = contactGroups.map(function (g) {
      return g.id;
    });

    for (var i = 0; i < 100; i++) {
      server.create("contact", { category: root.id, group: _emberCliMirage.faker.random.arrayElement(groupIds) });
    }

    var contactIds = server.db.contacts.mapBy("id");

    /* categories */
    for (var _i = 0; _i < 10; _i++) {
      var child = server.create("category", { parent: root.id }),
          childchild = server.create("category", { parent: child.id });

      var cid = _emberCliMirage.faker.random.arrayElement(contactIds);

      root = server.db.categories.find(root.id);

      server.db.categories.update(root.id, {
        contact: cid,
        children: root.children.concat([child.id])
      });
      server.db.categories.update(child.id, {
        contact: cid,
        children: child.children.concat([childchild.id])
      });
    }

    var userGroups = server.createList("group", 10);

    var userGroupIds = userGroups.mapBy("id");

    for (var _i2 = 0; _i2 < 100; _i2++) {
      var _cid = _emberCliMirage.faker.random.arrayElement(contactIds);
      contactIds.removeObject(_cid);
      server.create("user", {
        group: _emberCliMirage.faker.random.arrayElement(userGroupIds),
        contact: _cid,
        ldap: _i2 === 4 ? true : false
      });
    }

    var userIds = server.db.users.mapBy("id");

    /* knb entries */
    function createRandomInformation() {
      return informationTypes.map(function (type, index) {
        var payload = { type: type.id };

        if (index === 0) {
          payload.versions = server.createList("custom-information-version", 15).mapBy("id");
        } else if (index === 1) {
          payload.versions = [server.create("custom-information-version").id];
        }

        return server.create("custom-information", payload).id;
      });
    }

    for (var _i3 = 0; _i3 < _emberCliMirage.faker.random.number({ min: 16, max: 46 }); _i3++) {
      server.create("knb-entry", {
        category: _i3 < 12 ? root.id : root.children[0],
        information: createRandomInformation(),
        user: _emberCliMirage.faker.random.arrayElement(userIds),
        archive: Math.random() < 0.2,
        updatedBy: _emberCliMirage.faker.random.arrayElement(userIds)
      });
    }

    var numAssets = _emberCliMirage.faker.random.number({ min: 32, max: 92 });
    for (var _i4 = 0; _i4 < numAssets; _i4++) {
      server.create("asset", {
        category: _i4 < numAssets / 2 ? root.id : root.children[0],
        files: [server.create("file").id],
        creator: _emberCliMirage.faker.random.arrayElement(userIds),
        updater: _emberCliMirage.faker.random.arrayElement(userIds)
      });
    }

    var numClippings = _emberCliMirage.faker.random.number({ min: 32, max: 92 });
    for (var _i5 = 0; _i5 < numClippings; _i5++) {
      server.create("asset", {
        category: 10,
        type: _emberCliMirage.faker.random.arrayElement(["clipping", "clippingblanko"]),
        creator: _emberCliMirage.faker.random.arrayElement(userIds),
        updater: _emberCliMirage.faker.random.arrayElement(userIds)
      });
    }

    var albumAssets = server.db.assets.slice(0, 5);

    var _loop = function _loop(_i6) {
      var album = server.create("album", { assets: albumAssets.mapBy("id") });
      albumAssets.forEach(function (asset) {
        server.db.assets.update(asset.id, { album: album.id });
      });
    };

    for (var _i6 = 0; _i6 < 3; _i6++) {
      _loop(_i6);
    }

    var asset = server.db.assets[0],
        tags = server.createList("tag", 10),
        shares = [1, 2, 3, 4, 5].map(function () {
      return server.create("share", {
        asset: asset.id,
        createdBy: _emberCliMirage.faker.random.arrayElement(userIds)
      });
    });

    asset.shares = shares.mapBy("id");
    asset.tags = tags.mapBy("id");
    server.db.assets.update(asset.id, asset);
    server.db["knb-entries"].update(1, { assets: server.db.assets.mapBy("id").slice(0, 5) });

    /* press-requests */
    var requests = [];
    for (var _i7 = 0; _i7 < 25; _i7++) {
      requests.push(server.create("press-request", {
        category: _i7 < 12 ? root.children[0] : root.id,
        contact: _emberCliMirage.faker.random.arrayElement(server.db.contacts.mapBy("id")),
        externalContact: _emberCliMirage.faker.random.arrayElement(server.db.contacts.mapBy("id"))
      }));
    }

    var messages = server.createList("message", 5, { pressRequest: requests[0].id }).mapBy("id");

    messages.forEach(function (message) {
      server.db.messages.update(message, {
        author: _emberCliMirage.faker.random.arrayElement(server.db.contacts.mapBy("id"))
      });
    });

    server.db["press-requests"].update(requests[0].id, {
      messages: messages,
      assets: server.db.assets.mapBy("id").slice(0, 5)
    });

    server.loadFixtures("calendar-project-types");

    server.createList("calendar-project", 8);
    var calendarProjectIds = server.db["calendar-projects"].mapBy("id");

    calendarProjectIds.forEach(function (project) {
      server.db["calendar-projects"].update(project, {
        calendarProjectType: project > 6 ? 2 : 1
      });
    });

    var appointments = server.createList("calendar-appointment", 20).mapBy("id");

    appointments.forEach(function (appointment) {
      server.db["calendar-appointments"].update(appointment, {
        project: _emberCliMirage.faker.random.arrayElement(calendarProjectIds),
        user: _emberCliMirage.faker.random.arrayElement(userIds)
      });
    });

    server.loadFixtures("settings");
    server.loadFixtures("permissions");

    for (var _i8 = 0; _i8 < 25; _i8++) {
      server.create("store", {
        archive: Math.random() < 0.2
      });
    }

    var stores = server.db["stores"];

    server.db["knb-entries"].update(server.db["press-requests"][0].id, {
      pressRequests: requests.mapBy("id").slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 })),
      stores: stores.mapBy("id").slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 }))
    });

    server.db["press-requests"].update(server.db["press-requests"][0].id, {
      stores: stores.mapBy("id").slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 })),
      knbEntries: requests.mapBy("id").slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 }))
    });

    var someContact = server.db.contacts[0];
    var someRequests = server.db["press-requests"].slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 }));

    someRequests.forEach(function (request) {
      server.db["press-requests"].update(request.id, { externalContact: someContact.id });
    });

    server.db.contacts.update(someContact.id, {
      pressRequests: someRequests.mapBy("id")
    });

    var someStoreId = server.db.stores[0].id;
    var someEntries = server.db["knb-entries"].mapBy("id").filter(function (id) {
      return id !== 1;
    }).slice(0, _emberCliMirage.faker.random.number({ min: 2, max: 10 }));

    someEntries.forEach(function (id) {
      return server.db["knb-entries"].update(id, { stores: [someStoreId] });
    });

    server.db.stores.update(someStoreId, { knbEntries: someEntries });

    var reminders = server.createList("reminder", 5);

    reminders.forEach(function (reminder) {
      server.db["reminders"].update(reminder.id, { owner: someContact.id });
    });

    server.db["reminders"].update(1, { market: 5 });
    server.db["reminders"].update(2, { knbEntry: 4 });
    server.db["reminders"].update(3, { pressRequest: 1 });
    server.db["reminders"].update(4, { asset: 4 });
    server.db["reminders"].update(5, { contact: 3 });
  };
});
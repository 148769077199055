define("bizbase3/utils/debounced-observer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (fn, delay) {
    for (var _len = arguments.length, keys = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      keys[_key - 2] = arguments[_key];
    }

    return observer.apply(undefined, keys.concat([function () {
      run.debounce(this, fn, delay);
    }]));
  };

  var observer = Ember.observer;

  var run = Ember.run;
});
define("bizbase3/mixins/contact-controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      controller = Ember.inject.controller;
  exports.default = Ember.Mixin.create(_notable.default, {
    errors: [],

    actions: {
      createNewContact: function createNewContact() {
        this.set("contactModel", this.get("store").createRecord("contact"));
      },
      cancelNewContact: function cancelNewContact() {
        var newContact = this.get("contactModel");

        if (newContact) {
          newContact.deleteRecord();
        }

        this.set("contactModel", null);
      },
      saveContact: function saveContact(data) {
        var _this = this;

        var contact = this.get("contactModel");

        contact.setProperties(data);

        var fullName = get(contact, "fullName"),
            promise = contact.save();

        promise.then(function () {
          _this.afterSave(contact);
          _this.success(fullName + " erfolgreich gespeichert.");
        }).catch(function (e) {
          //eslint-disable-next-line no-console
          console.error(e); // jshint ignore: line
          _this.error("Fehler beim Speichern von " + fullName + ".");
        }).finally(function () {
          _this.finallySave(contact);
        });

        return promise;
      }
    },

    contacts: controller(),

    contactModel: computed.reads("model"),

    afterSave: function afterSave() {},
    finallySave: function finallySave() {}
  });
});
define("bizbase3/pods/components/calendar-users-list/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      on = Ember.on;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    listClasses: ["list-group"],

    actions: {
      toggleSelect: function toggleSelect(project) {
        project = !project;
        return project;
      }
    },

    classNames: ["calendar-user-list"],

    selectedUsers: [],

    userByAppointments: computed("appointments", "appointments.@each.{user}", function () {
      var users = [];
      if (this.get("appointments")) {
        this.get("appointments").forEach(function (appointment) {
          if (appointment.get("user")) {
            if (!users.includes(appointment.get("user"))) {
              users.push(appointment.get("user"));
            }
          }
        });
      }
      return users;
    }),

    _loadProjects: on("didInsertElement", function () {
      //this.get("calendarProjects").retrieve();
    })
  });
});
define("bizbase3/components/create-category-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isEmpty = Ember.isEmpty;

  exports.default = Ember.Component.extend({
    submit: function submit(e) {
      e.preventDefault();

      var newCategoryName = this.get("newCategoryName");

      if (isEmpty(newCategoryName)) {
        this.$("#createCategoryMessage").modal();
      } else {
        this.sendAction("action", newCategoryName);
        this.$("input").select();
      }
    },


    tagName: "form"
  });
});
define("bizbase3/mixins/unpersist-tab", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    actions: {
      didTransition: function didTransition() {
        this.get("detailTab").unpersist();
      }
    },

    detailTab: inject.service()
  });
});
define("bizbase3/pods/login/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      isEmpty = Ember.isEmpty;
  exports.default = Ember.Controller.extend({
    actions: {
      login: function login() {
        var _this = this;

        var _getProperties = this.getProperties("username", "password"),
            username = _getProperties.username,
            password = _getProperties.password,
            emptyUsername = isEmpty(username),
            emptyPassword = isEmpty(password);

        this.setProperties({ emptyUsername: emptyUsername, emptyPassword: emptyPassword, errorMessage: false });

        if (!this.get("hasError")) {
          this.set("isPending", true);

          var authPromise = this.get("session").authenticate("authenticator:bizbase", username, password);

          authPromise.catch(function (reason) {
            return _this.set("errorMessage", reason.responseJSON.message);
          }).finally(function () {
            return _this.set("isPending", false);
          });

          this.transitionToRoute("dashboard");
        }
      }
    },

    hasError: computed.or("emptyUsername", "emptyPassword"),

    isPending: false,

    session: inject.service()
  });
});
define("bizbase3/pods/press-requests/new/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.get("contacts").retrieve();
    },
    model: function model(params, transition) {
      var store = this.get("store"),
          request = store.createRecord("press-request");

      request.set("category", store.peekRecord("category", transition.params["press-requests"].category_id));

      return hash({
        request: request,
        user: this.get("currentUser").load()
      });
    },


    contacts: service(),

    currentUser: service(),

    renderTemplate: function renderTemplate() {
      if (this.get("splitview.active")) {
        this.render();
      } else {
        this.render({ into: "categories-page" });
      }
    }
  });
});
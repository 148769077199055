define("bizbase3/utils/computed/days-since", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key) {
    return computed(key, function () {
      var date = this.get(key);

      if (!date) {
        return Number.MAX_VALUE;
      }

      var diff = (0, _moment.default)(date).diff(new Date());

      return _moment.default.duration(diff).asDays();
    });
  };

  var computed = Ember.computed;
});
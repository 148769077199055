define("bizbase3/pods/press-requests/route", ["exports", "bizbase3/mixins/categorized-route", "bizbase3/utils/pagination-array"], function (exports, _categorizedRoute, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_categorizedRoute.default, {
    missingCategoryRoute: "press-request-categories",

    categoriesControllerName: "press-request-categories",

    pressRequestsColumns: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("pressRequestsColumns").load();
    },
    model: function model(params) {
      var pressRequestsQuery = {
        category_id: params.category_id,
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      return hash({
        category: this.modelFor("press-request-categories").findBy("id", params.category_id),
        "press-requests": (0, _paginationArray.makePaginationQuery)(this.get("store"), "press-request", pressRequestsQuery, params.page, params.perPage)
      });
    }
  });
});
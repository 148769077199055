define("bizbase3/mixins/categories-route", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create(_notable.default, {
    actions: {
      createCategory: function createCategory(name, parentId) {
        var _this = this;

        if (!parentId) {
          var categorizedModel = this.modelFor(this.get("categorizedRouteName")) || {};

          parentId = get(categorizedModel, "category.id");
        }

        var type = this.get("categoryType"),
            parent = this.store.peekRecord("category", parentId);

        if (!parent) {
          parent = this.getParent();
        }

        var category = this.store.createRecord("category", { name: name, parent: parent, type: type });

        category.save().then(function () {
          _this.success("Kategorie \"" + name + "\" gespeichert");
          _this.update();
        }).catch(function () {
          _this.store.unloadRecord(category);
          _this.error("Fehler beim Speichern der Kategorie \"" + name + "\"");
        });
      }
    },

    model: function model() {
      var type = this.get("categoryType");
      return this.store.query("category", { type: type });
    },
    renderTemplate: function renderTemplate() {
      this.render("categories-page");
      this.render("category-crumbs", {
        into: "categories-page",
        outlet: "bread-crumbs"
      });
    },
    getParent: function getParent() {
      return this.currentModel.findBy("isRoot", true);
    },
    update: function update() {
      this.currentModel.update();
    }
  });
});
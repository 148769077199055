define("bizbase3/mixins/detail-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var detailModelKey = this.get("detailModelKey"),
          detailModel = detailModelKey ? get(model, detailModelKey) : model,
          masterController = this.controllerFor(this.get("masterControllerName"));
      masterController.set("detailModel", detailModel);

      detailModel.on("didDelete", function () {
        masterController.trigger("recordsDeleted");
      });
    }
  });
});
define("bizbase3/mixins/taggable-route", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Mixin.create(_notable.default, {
    actions: {
      assignTag: function assignTag(tag) {
        this.assignTag(tag);
      },
      removeTag: function removeTag(tag) {
        this.get("tags").removeTag(this.currentModel, tag);
      }
    },

    assignTag: function assignTag(tag) {
      this.get("tags").assignTag(this.currentModel, tag);
    },


    tags: inject.service()
  });
});
define("bizbase3/pods/classic/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    classicNavigation: service(),

    session: service(),

    store: service(),

    items: computed("classicNavigation.config.[]", function () {
      var config = this.get("classicNavigation.config");
      return config;
    })
  });
});
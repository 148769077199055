define("bizbase3/models/contact", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "bizbase3/utils/computed/fields", "bizbase3/services/contacts-columns"], function (exports, _model, _attr, _relationships, _fields, _contactsColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _model.default.extend({
    firstName: (0, _attr.default)("string"),
    lastName: (0, _attr.default)("string"),
    company: (0, _attr.default)("string"),
    street1: (0, _attr.default)("string"),
    street2: (0, _attr.default)("string"),
    postcode: (0, _attr.default)("string"),
    city: (0, _attr.default)("string"),
    country: (0, _attr.default)("string"),

    email: (0, _attr.default)("string"),
    phone: (0, _attr.default)("string"),
    mobile: (0, _attr.default)("string"),
    fax: (0, _attr.default)("string"),

    mediaType: (0, _attr.default)("string"),
    mediaTitle: (0, _attr.default)("string"),
    department: (0, _attr.default)("string"),

    notes: (0, _attr.default)("string"),

    updatedAt: (0, _attr.default)("date"),
    createdAt: (0, _attr.default)("date"),

    group: (0, _relationships.belongsTo)("contact-group"),

    pressRequests: (0, _relationships.hasMany)({ inverse: "externalContact" }),

    reminders: (0, _relationships.hasMany)("reminder", {
      inverse: "owner"
    }),

    category: (0, _relationships.belongsTo)(),

    columnsConfig: inject.service("contacts-columns"),

    fields: (0, _fields.default)(_contactsColumns.COLUMNS.mapBy("key")),

    fullName: computed("firstName", "lastName", function () {
      return this.get("firstName") + " " + this.get("lastName");
    }),

    initials: computed("firstName", "lastName", function () {
      var firstName = this.get("firstName") ? this.get("firstName").substring(0, 1) : "";
      var lastName = this.get("lastName") ? this.get("lastName").substring(0, 1) : "";
      return "" + firstName + lastName;
    }),

    title: computed.reads("fullName"),

    links: computed("pressRequests", function () {
      return [{ model: this.get("pressRequests"), moduleName: "pressrequests", route: "press-request" }];
    })

    // TODO: information:
  });
});
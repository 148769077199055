define("bizbase3/models/press-request", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "bizbase3/utils/computed/fields", "bizbase3/services/press-requests-columns"], function (exports, _attr, _model, _relationships, _fields, _pressRequestsColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      alias = Ember.computed.alias,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = _model.default.extend({
    receiptDate: (0, _attr.default)("date"),
    publicationDate: (0, _attr.default)("date"),
    respondUntil: (0, _attr.default)("date"),
    doneAt: (0, _attr.default)("date"),

    state: (0, _attr.default)("number"),

    note: (0, _attr.default)("string"),

    type: (0, _attr.default)("string"),

    team: (0, _attr.default)("string"),

    duration: (0, _attr.default)("string"),

    personCount: (0, _attr.default)("number"),

    isFinished: (0, _attr.default)("boolean", { defaultValue: false }),

    category: (0, _relationships.belongsTo)(),

    contact: (0, _relationships.belongsTo)(),

    externalContact: (0, _relationships.belongsTo)("contact"),

    responsibleContact: (0, _relationships.belongsTo)("contact"),

    tags: (0, _relationships.hasMany)(),

    reminders: (0, _relationships.hasMany)(),

    messages: (0, _relationships.hasMany)(),

    assets: (0, _relationships.hasMany)(),

    stores: (0, _relationships.hasMany)(),

    knbEntries: (0, _relationships.hasMany)(),

    columnsConfig: service("press-requests-columns"),

    columns: alias("columnsConfig.columns"),

    fields: (0, _fields.default)(_pressRequestsColumns.COLUMNS.mapBy("key").concat("state")),

    title: computed("contact.fullName", function () {
      return "Anfrage von " + this.get("contact.fullName");
    }),

    links: computed("stores", "knbEntries", function () {
      return [{ model: this.get("stores"), moduleName: "stores", route: "store" }, { model: this.get("knbEntries"), moduleName: "knb", route: "knb-entry" }];
    }),

    hasLinks: computed("links.@each.model.length", function () {
      return !!this.get("links").some(function (link) {
        return get(link, "model.length");
      });
    })
  });
});
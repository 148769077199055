define("bizbase3/models/custom-information", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    content: attr("string"),
    plaintext: attr("string"),

    type: belongsTo("custom-information-type", { async: false }),

    versions: hasMany("custom-information-version"),

    title: computed.reads("type.title"),

    typeId: computed.reads("type.id"),

    isDirtyTitle: computed("title", "hasDirtyAttributes", function () {
      var _getProperties = this.getProperties("title", "hasDirtyAttributes"),
          title = _getProperties.title,
          hasDirtyAttributes = _getProperties.hasDirtyAttributes;

      if (hasDirtyAttributes) {
        title = title + " *";
      }

      return title;
    }),

    order: computed.reads("type.order"),

    versionTitle: computed.reads("title")
  });
});
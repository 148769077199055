define("bizbase3/mirage/factories/share", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function shuffle(o) {
    o = o || ["a", "b", "c"];
    return o.map(function () {
      return o[_emberCliMirage.faker.random.number(o.length)];
    });
  }

  function token() {
    return shuffle("a b c d e f 0 1 2 3 4 5 6 7 8 9".w()).join("");
  }

  exports.default = _emberCliMirage.default.Factory.extend({
    token: token,
    url: function url() {
      return "http://example.com/" + token();
    },
    info: function info() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    expiresAt: function expiresAt() {
      return _emberCliMirage.faker.date[_emberCliMirage.faker.random.arrayElement(["past", "future", "recent"])]();
    },

    createdBy: null
  });
});
define("bizbase3/pods/components/user-password-form/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_notable.default, {
    newPassword: "",
    oldPassword: "",
    verifyPassword: "",
    error: false,

    didRender: function didRender() {
      if (this.get("user.forceNewPassword")) {
        this.set("passwordModal", true);
      }
    },

    strength: computed("newPassword", function () {
      return zxcvbn(this.get("newPassword"));
    }),

    score: computed("strength", function () {
      if (this.get("strength")) {
        return this.get("strength").score + 1;
      } else {
        return 1;
      }
    }),

    strengthClass: computed("strength", function () {
      if (this.get("score") >= 4) {
        return "success";
      } else if (this.get("score") >= 2) {
        return "warning";
      } else {
        return "danger";
      }
    }),

    modalId: computed("id", "params.[]", function (id, params) {
      return id = id || params[0];
    }),

    actions: {
      hide: function hide() {
        if (this.get("user.forceNewPassword")) {
          return false;
        }
        this.set("passwordModal", false);
      },
      close: function close() {
        this.set("passwordModal", false);
        this.set("newPassword", "");
        this.set("oldPassword", "");
        this.set("verifyPassword", "");
      },
      submit: function submit() {
        var _this = this;

        var user = this.get("user");
        user.set("password", this.get("oldPassword"));
        user.set("newPassword", this.get("newPassword"));
        user.set("verifyPassword", this.get("verifyPassword"));
        return user.save().then(function () {
          _this.success("Das Passwort wurde geändert");
          _this.set("newPassword", "");
          _this.set("oldPassword", "");
          _this.set("verifyPassword", "");
          _this.set("error", false);
          _this.set("user.forceNewPassword", false);
          _this.set("passwordModal", false);
        }).catch(function (e) {
          _this.set("errorException", e);
          _this.set("error", "Das neue Passwort konnte nicht gespeichert werden.");
        });
      }
    }
  });
});
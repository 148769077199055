define("bizbase3/pods/group/permissions/controller", ["exports", "bizbase3/mixins/group-controller", "bizbase3/mixins/notable"], function (exports, _groupController, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend(_groupController.default, _notable.default, {
    permissions: computed.reads("model.permissions"),

    session: inject.service(),

    actions: {
      testPermissions: function testPermissions() {
        var permissions = this.get("model.group.permissions").map(function (permission) {
          return permission.get("permission");
        });
        this.get("session").set("data.testpermissions", permissions);
        window.location.reload(true);
        this.get("session").set("data.testpermissions", permissions);
      },
      resetAllPermissions: function resetAllPermissions() {
        var _this = this;

        var group = this.get("model.group");
        group.set("permissions", []);
        group.save().then(function () {
          _this.success("modules.settings.groups.notify.resetPermissionsSuccess");
        }).catch(function () {
          _this.error("modules.settings.groups.notify.resetPermissionsFailed");
        });
      },
      updateSelection: function updateSelection(newSelection) {
        var _this2 = this;

        this.get("model.group").save({ permissions: newSelection }).then(function () {
          _this2.success("modules.settings.groups.notify.saveSuccess");
        }).catch(function () {
          _this2.error("modules.settings.groups.notify.saveFailed");
        });
      }
    }
  });
});
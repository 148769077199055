define("bizbase3/mixins/data-retrieval-service", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  var PromiseArray = _emberData.default.PromiseArray;
  exports.default = Ember.Mixin.create({
    retrieve: function retrieve() {
      var promise = this.get("store").findAll(this.get("_modelKey"));
      return this.set(this.get("_dataKey"), PromiseArray.create({ promise: promise }));
    },
    query: function query(_query) {
      var promise = this.get("store").query(this.get("_modelKey"), {
        perPage: 200,
        search: { query: "exp:" + _query }
      });

      return PromiseArray.create({ promise: promise });
    },


    store: inject.service(),

    _dataKey: "data"
  });
});
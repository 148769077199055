define("bizbase3/mixins/drop-upload-controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create(_notable.default, {
    actions: {
      pushAsset: function pushAsset(model, asset) {
        if (!asset) {
          asset = model;
          model = null;
        }

        var store = this.get("store");

        store.pushPayload(asset);
        if (model) {
          get(model, "assets").addObject(store.peekRecord("asset", asset.asset.id));
        }
      },
      uploadFinished: function uploadFinished() {
        this.didFinishUpload();
      }
    },

    didFinishUpload: function didFinishUpload() {
      this.transitionToRoute(this.get("parentRoute"));
      this.success("Dateien erfolgreich hochgeladen");
    }
  });
});
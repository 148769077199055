define("bizbase3/pods/asset/index/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      saveAsset: function saveAsset() {
        var _this = this;

        this.get("model").save().then(function () {
          _this.success("modules.filemanager.tabs.info.notify.saveSuccess");
        }).catch(function () {
          _this.error("modules.filemanager.tabs.info.notify.saveError");
        });
      },
      deleteAsset: function deleteAsset() {
        var _this2 = this;

        var categoryId = this.get("model.category.id");

        this.get("model").destroyRecord().then(function () {
          _this2.transitionToRoute("assets", categoryId);
          _this2.success("modules.filemanager.tabs.info.notify.deleteSuccess");
        }).catch(function (e) {
          _this2.error("modules.filemanager.tabs.info.notify.deleteFailure");
          throw e;
        });
      },
      toggleUnlimitedAccess: function toggleUnlimitedAccess() {
        this.toggleProperty("model.hasUnlimitedAccess");
      },
      tagRemove: function tagRemove(tag) {
        this.send("removeTag", tag);
      },
      tagAssign: function tagAssign(tag) {
        this.send("assignTag", tag);
      }
    }
  });
});
define("bizbase3/pods/store/index/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      saveStore: function saveStore() {
        var _this = this;

        this.get("model").save().then(function () {
          _this.success("modules.knb.notify.saveSuccess");
        }).catch(function () {
          _this.error("modules.knb.notify.saveFailed");
        });
      }
    }
  });
});
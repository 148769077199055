define("bizbase3/services/users-columns", ["exports", "moment", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service"], function (exports, _moment, _settingsHandler, _columnsService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed;


  var STORAGE_KEY = "BIZBASE3.users.columnConfig";

  var MODULE_NAME = "users";

  var TRANSLATION_PATH = "modules.settings.sub.users.columns.";

  function formatDate(date) {
    return date ? (0, _moment.default)(date).format("LL") : "-";
  }

  var COLUMNS = [{ key: "firstName" }, { key: "lastName" }, { key: "email" }, { key: "group.name" }, { key: "lastLogin", format: formatDate }];

  exports.COLUMNS = COLUMNS;
  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    storageKey: STORAGE_KEY,
    moduleName: MODULE_NAME,
    translationPath: TRANSLATION_PATH,
    _columns: computed(function () {
      return COLUMNS;
    })
  });
});
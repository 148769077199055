define("bizbase3/pods/components/resizable-column/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      on = Ember.on,
      _Ember$run = Ember.run,
      bind = _Ember$run.bind,
      schedule = _Ember$run.schedule;


  var STYLEID = "dragResizeStyle";

  var MINWIDTH = 20;

  exports.default = Ember.Component.extend({
    classNames: ["resizable-column"],

    classNameBindings: ["resizing"],

    setupResizable: on("didInsertElement", function () {
      var _this = this;

      schedule("afterRender", function () {
        return _this.$().width(_this.$().width());
      });

      this.$().resizable({
        resizeHeight: false,
        handleSelector: "> .resizable-column-handle",
        onDragStart: bind(this, this.onDragStart),
        onDragEnd: bind(this, this.onDragEnd),
        onDrag: bind(this, this.syncColumns)
      });
    }),

    onDragStart: function onDragStart() {
      $("head").append("<style type=\"text/css\" id=\"" + STYLEID + "\">* { cursor: ew-resize; }</style>");

      var myWidth = this.$().width(),
          nextWidth = this.$().next().width();

      this.set("resizing", true);

      this.set("widthSum", myWidth + nextWidth + 1);
    },
    onDragEnd: function onDragEnd() {
      $("#" + STYLEID).remove();

      this.set("resizing", false);

      this.set("widthSum", false);
    },
    syncColumns: function syncColumns(e, $el, newWidth) {
      var newNextWidth = this.get("widthSum") - newWidth - 1;
      if (newWidth < MINWIDTH || newNextWidth < MINWIDTH) {
        return false;
      }

      this.$().next().width(newNextWidth);

      this.get("onResize")(this.$().index(), newWidth, newNextWidth);
    },
    onResize: function onResize() {}
  });
});
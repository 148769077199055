define("bizbase3/pods/components/pressrequest-latest-table/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),

    fetchdata: true,

    requests: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set("requests", Ember.ArrayProxy.create({ content: [] }));
      if (this.get("fetchdata")) {
        this.get("store").query("press-request", {
          page: 1,
          perPage: 10,
          category_id: this.get("category"),
          type: this.get("type"),
          multiSort: [{
            sortKey: "state",
            sortDir: "asc"
          }, {
            sortKey: "receipt_date",
            sortDir: "desc"
          }]
        }).then(function (requests) {
          return _this.get("requests").pushObjects(requests.toArray());
        });
      }
    }
  });
});
define("bizbase3/pods/contacts/new/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params, transition) {
      var store = this.get("store"),
          contact = store.createRecord("contact");

      contact.set("category", store.peekRecord("category", transition.params.contacts.category_id));

      return contact;
    }
  });
});
define("bizbase3/pods/components/search-form/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      on = Ember.on,
      set = Ember.set;


  function checkFunction(checked) {
    return function (field) {
      var updatedField = Object.create(field);

      set(updatedField, "checked", checked === "toggle" ? !get(field, "checked") : checked);

      return updatedField;
    };
  }

  function checkAction(action) {
    return function () {
      var updated = this.getWithDefault("searchFields", []).map(action);
      this.updateSearchFields(updated);
    };
  }

  exports.default = Ember.Component.extend({
    actions: {
      checkAll: checkAction(checkFunction(true)),

      checkNone: checkAction(checkFunction(false)),

      checkToggle: checkAction(checkFunction("toggle")),

      resetFilter: function resetFilter() {
        this.set("searchFilter", "");
        this.get("resetSearch")();
      }
    },

    showResetFilter: computed.or("isResetable", "searchFilter"),

    searchKeys: computed("searchFields.@each.{key,checked}", function () {
      return this.get("searchFields").filterBy("checked").map(function (_ref) {
        var key = _ref.key,
            searchPath = _ref.searchPath;
        return searchPath || key;
      });
    }),

    resetSearch: function resetSearch() {},
    updateSearchFields: function updateSearchFields(searchFields) {
      var updateSearchConfig = this.get("updateSearchConfig");

      if (typeof updateSearchConfig === "function") {
        updateSearchConfig(searchFields);
      } else {
        this.set("searchFields", searchFields);
      }
    },


    searchOnInsert: on("didInsertElement", function () {
      var searchQuery = this.get("searchQuery");
      var searchKeys = this.get("searchKeys");
      var search = this.get("search");

      if (searchQuery) {
        search(searchQuery, searchKeys);
      }
    })
  });
});
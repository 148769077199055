define("bizbase3/pods/components/pdf-viewer/component", ["exports", "bizbase3/mixins/modal-dialog"], function (exports, _modalDialog) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalDialog.default, {
    actions: {
      close: function close() {
        this.hide();
      }
    },

    dialogId: "pdfViewer",

    title: "PDF :",

    tagName: "div"
  });
});
define("bizbase3/services/asset-usage-labels", ["exports", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/readonly-settings-handler"], function (exports, _settingsHandler, _readonlySettingsHandler) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STORAGE_KEY = "BIZBASE3.assets.usageConfig";

  exports.default = _settingsHandler.default.extend(_readonlySettingsHandler.default, {
    storageKey: STORAGE_KEY
  });
});
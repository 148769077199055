define("bizbase3/pods/contact-categories/route", ["exports", "bizbase3/mixins/categories-route"], function (exports, _categoriesRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_categoriesRoute.default, {
    categorizedRouteName: "contacts",

    categoryType: "contacts"
  });
});
define("bizbase3/mixins/data-table", ["exports", "bizbase3/mixins/notable", "bizbase3/mixins/sortable"], function (exports, _notable, _sortable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var compare = Ember.compare,
      computed = Ember.computed,
      Evented = Ember.Evented,
      get = Ember.get;
  exports.default = Ember.Mixin.create(_notable.default, Evented, {
    actions: {
      showAllColumns: function showAllColumns() {
        this.get("columnsConfig").showAll();
      },
      toggleColumn: function toggleColumn(column) {
        this.get("columnsConfig").toggleVisibility(column);
      }
    },

    selection: computed(function () {
      return [];
    }),

    selectedItems: computed("collection.@each.id", "selection.[]", function () {
      var selection = this.get("selection");

      return this.get("collection").filter(function (item) {
        return selection.includes(get(item, "id"));
      });
    }),

    sortedCollection: computed("collection.[]", "sortKey", "sortDir", function () {
      var _getProperties = this.getProperties("collection", "sortKey", "sortDir"),
          collection = _getProperties.collection,
          sortKey = _getProperties.sortKey,
          sortDir = _getProperties.sortDir;

      collection = collection.slice();

      if (!sortKey) {
        return collection;
      } else {
        return collection.sort(function (i1, i2) {
          var field1 = get(get(i1, "fields").findBy("key", sortKey), "value"),
              field2 = get(get(i2, "fields").findBy("key", sortKey), "value");

          if (!isNaN(field1)) {
            field1 = +field1;
          }

          if (!isNaN(field2)) {
            field2 = +field2;
          }

          if (sortDir === _sortable.ASC) {
            return compare(field1, field2);
          } else {
            return compare(field2, field1);
          }
        });
      }
    })
  });
});
define("bizbase3/pods/press-requests/new/controller", ["exports", "bizbase3/mixins/contact-controller"], function (exports, _contactController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      _Ember$inject = Ember.inject,
      controller = _Ember$inject.controller,
      service = _Ember$inject.service,
      setProperties = Ember.setProperties;
  exports.default = Ember.Controller.extend(_contactController.default, {
    senderIsExternalContact: true,
    actions: {
      saveRequest: function saveRequest(formData) {
        var _this = this;

        var pressRequest = this.get("model.request");
        setProperties(pressRequest, formData);
        if (formData.externalContact && this.get("senderIsExternalContact")) {
          this.set("messageAuthor", formData.externalContact);
        }

        pressRequest.save().then(function () {
          var _getProperties = _this.getProperties("messageText", "messageDate", "messageAuthor"),
              text = _getProperties.messageText,
              date = _getProperties.messageDate,
              author = _getProperties.messageAuthor;

          return get(pressRequest, "messages").createRecord({ pressRequest: pressRequest, author: author, text: text, date: date }).save();
        }).then(function () {
          _this.setProperties({ messageText: "", messageDate: null, messageAuthorId: null });
          _this.transitionToRoute("press-request.index", pressRequest);
          _this.success("modules.pressrequests.notify.saveSuccess");
          _this.get("pressRequests").updateEntries();
        }).catch(function () {
          return _this.error("modules.pressrequests.notify.saveFailed");
        });
      }
    },

    // overwrites controller:contacts
    contacts: service(),

    contactModel: null,

    widgetPositioning: { vertical: "bottom" },

    pressRequests: controller(),

    messageAuthor: computed("messageAuthorId", "contacts.data.@each.{id}", function () {
      return this.get("contacts.data").findBy("id", this.get("messageAuthorId"));
    }),

    afterSave: function afterSave(contact) {
      this.get("newContactDeferred").resolve(contact);
    },

    //TODO: check cateogry

    finallySave: function finallySave() {
      this.set("contactModel", null);
    }
  });
});
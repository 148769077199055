define("bizbase3/services/file-uploader", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    upload: function upload() /*type, files, categoryId*/{
      // TODO: user ajax to upload file
    }
  });
});
define("bizbase3/mixins/permission-controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      controller = Ember.inject.controller;
  exports.default = Ember.Mixin.create({
    actions: {
      createNewPermission: function createNewPermission() {
        this.set("permissionModel", this.get("store").createRecord("permission"));
      },
      cancelNewPermission: function cancelNewPermission() {
        var newPermission = this.get("permissionModel");

        if (newPermission) {
          newPermission.deleteRecord();
        }

        this.set("permissionModel", null);
      },
      savePermission: function savePermission(data) {
        var _this = this;

        var permission = this.get("permissionModel");

        permission.setProperties(data);

        var name = data.name;

        var promise = permission.save({ adapterOptions: { permission: { name: name } } });

        promise.then(function () {
          return _this.afterSave(permission);
        }).catch(function (e) {
          permission.rollbackAttributes();
          throw e;
        }).finally(function () {
          return _this.finallySave(permission);
        });

        return promise;
      }
    },

    permissions: controller(),

    permissionModel: computed.alias("model"),

    afterSave: function afterSave() {},
    finallySave: function finallySave() {}
  });
});
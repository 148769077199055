define("bizbase3/pods/users/new/controller", ["exports", "bizbase3/mixins/user-controller"], function (exports, _userController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_userController.default, {
    afterSave: function afterSave() {
      this.get("users").updateEntries();
      this.transitionToRoute("user", this.get("model"));
    }
  });
});
define("bizbase3/models/asset", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "bizbase3/utils/computed/validity", "bizbase3/utils/computed/days-since", "bizbase3/utils/computed/fields", "bizbase3/services/filemanager-columns"], function (exports, _model, _attr, _relationships, _validity, _daysSince, _fields, _filemanagerColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      isEmpty = Ember.isEmpty;


  var VALID_USAGE_INTERNAL = "usage-internal";

  var USAGE_INTERNAL_ID = 1;

  exports.default = _model.default.extend({
    alias: (0, _attr.default)("string"),
    title: (0, _attr.default)("string"),
    usage: (0, _attr.default)("number"),
    info: (0, _attr.default)("string"),
    previewImage: (0, _attr.default)("string"),
    version: (0, _attr.default)("number"),
    updated_at: (0, _attr.default)("date"),
    created_at: (0, _attr.default)("date"),
    expires_at: (0, _attr.default)("date"),
    category: (0, _relationships.belongsTo)(),
    album: (0, _relationships.belongsTo)({ async: false }),
    files: (0, _relationships.hasMany)(),
    tags: (0, _relationships.hasMany)(),
    shares: (0, _relationships.hasMany)(),

    knbEntries: (0, _relationships.hasMany)(),
    stores: (0, _relationships.hasMany)(),
    pressRequests: (0, _relationships.hasMany)(),

    creator: (0, _relationships.belongsTo)("user", { async: false }),
    updater: (0, _relationships.belongsTo)("user", { async: false }),

    exif: computed.reads("files.firstObject.exif"),

    filetype: computed.reads("files.firstObject.filetype"),

    fields: (0, _fields.default)(_filemanagerColumns.COLUMNS.mapBy("key")),

    columnsConfig: inject.service("filemanager-columns"),

    size: computed.reads("files.lastObject.size"),

    daysValid: (0, _daysSince.default)("expires_at"),

    validity: computed("daysValid", "usage", function () {
      var daysValid = this.get("daysValid");

      if (daysValid > 5) {
        return this.get("usage") === USAGE_INTERNAL_ID ? VALID_USAGE_INTERNAL : null;
      }

      return (0, _validity.validity)(daysValid);
    }),

    usageInternal: computed("usage", function () {
      return this.get("usage") === USAGE_INTERNAL_ID;
    }),

    sharesPending: computed("shares.@each.isLoading", function () {
      return this.get("shares").any(function (share) {
        return get(share, "isLoading") || get(share, "isSaving");
      });
    }),

    hasUnlimitedAccess: computed("expires_at", {
      get: function get() {
        return isEmpty(this.get("expires_at"));
      },
      set: function set(key, value) {
        if (value) {
          this.set("expires_at", null);
        } else {
          this.set("expires_at", new Date());
        }

        return value;
      }
    }),

    links: computed("knbEntries", "pressRequests", "stores", function () {
      var links = [];
      if (this.get("pressRequests").length) {
        links.push({
          model: this.get("pressRequests"),
          moduleName: "pressrequests",
          route: "press-request"
        });
      }

      if (this.get("knbEntries").length) {
        links.push({ model: this.get("knbEntries"), moduleName: "knb", route: "knb-entry" });
      }

      if (this.get("stores").length) {
        links.push({ model: this.get("stores"), moduleName: "stores", route: "store" });
      }
      return links;
    }),

    hasLinks: computed("links.@each.model.length", function () {
      return !!this.get("links").some(function (link) {
        return get(link, "model.length");
      });
    })
  });
});
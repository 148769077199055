define("bizbase3/models/contact-group", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    title: attr("string")
  });
});
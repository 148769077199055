define("bizbase3/mirage/factories/reminder", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var colors = ["bg-navy", "bg-blue", "bg-aqua", "bg-teal", "bg-olive", "bg-green", "bg-lime", "bg-yellow", "bg-orange", "bg-red", "bg-fuchsia", "bg-purple", "bg-maroon", "bg-gray", "bg-silver", "bg-black"];

  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title() {
      var words = _emberCliMirage.faker.lorem.words(3).join(" ");
      return words.charAt(0).toUpperCase() + words.slice(1);
    },
    notice: function notice() {
      var notice = _emberCliMirage.faker.lorem.words(20).join(" ");
      return _emberCliMirage.faker.random.arrayElement([null, notice]);
    },
    color: function color() {
      return _emberCliMirage.faker.random.arrayElement(colors);
    },

    done: _emberCliMirage.faker.random.boolean,
    notification: _emberCliMirage.faker.random.boolean,
    doneAt: function doneAt() {
      return _emberCliMirage.faker.date[_emberCliMirage.faker.random.arrayElement(["past", "future", "recent"])]();
    },
    notificationAt: function notificationAt() {
      return _emberCliMirage.faker.date[_emberCliMirage.faker.random.arrayElement(["past", "future", "recent"])]();
    },
    notificationBeforeMinutes: function notificationBeforeMinutes() {
      return _emberCliMirage.faker.random.arrayElement([0, 15, 30, 60]);
    },
    createdAt: function createdAt() {
      return _emberCliMirage.faker.date.past();
    },
    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.past();
    }
  });
});
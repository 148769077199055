define("bizbase3/mirage/factories/calendar-appointment", ["exports", "ember-cli-mirage", "moment"], function (exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title() {
      var words = _emberCliMirage.faker.lorem.words(3).join(" ");
      return words.charAt(0).toUpperCase() + words.slice(1);
    },
    start: function start() {
      this.startDate = _emberCliMirage.faker.date[_emberCliMirage.faker.random.arrayElement(["past", "future", "recent"])]();
      return this.startDate;
    },
    end: function end() {
      var date = new _moment.default(this.startDate);

      return date.add(60, "m");
    },
    status: function status() {
      return _emberCliMirage.faker;
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.paragraphs(_emberCliMirage.faker.random.number({ min: 1, max: 3 }));
    }
  });
});
define("bizbase3/pods/asset/shares/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      saveShare: function saveShare(share) {
        var _this = this;

        share.save().then(function () {
          return _this.success("modules.filemanager.tabs.share.notify.saved");
        });
      }
    }
  });
});
define("bizbase3/pods/asset/route", ["exports", "bizbase3/mixins/taggable-route", "bizbase3/mixins/detail-route"], function (exports, _taggableRoute, _detailRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_taggableRoute.default, _detailRoute.default, {
    masterControllerName: "assets",
    splitview: service(),
    renderTemplate: function renderTemplate() {
      if (this.get("splitview.active")) {
        this.render();
      } else {
        this.render({ into: "categories-page" });
      }
    }
  });
});
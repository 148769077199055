define("bizbase3/pods/components/editable-date/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      hide: function hide() {
        this.set("editing", false);
        this.sendAction("on-hide");
      }
    },

    click: function click() {
      this.set("editing", true);
      this.$("input").focus();
    },


    classNames: ["editable-date", "editable"],

    classNameBindings: ["editing"]
  });
});
define("bizbase3/services/users", ["exports", "bizbase3/mixins/data-retrieval-service"], function (exports, _dataRetrievalService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Service.extend(_dataRetrievalService.default, {
    _modelKey: "user",

    users: computed("data.@each.{id,fullName}", function () {
      return this.getWithDefault("data", []).sortBy("lastName", "firstName").map(function (contact) {
        return contact;
      });
    })
  });
});
define("bizbase3/services/information-order", ["exports", "bizbase3/utils/services/settings-handler"], function (exports, _settingsHandler) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STORAGE_KEY = "BIZBASE3.customInformationOrder";

  exports.default = _settingsHandler.default.extend({
    storageKey: STORAGE_KEY,

    saveOrder: function saveOrder(newOrder) {
      this.set("config", newOrder);
      return this.save();
    }
  });
});
define("bizbase3/mirage/factories/custom-information-type", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title(i) {
      return "Custom " + i;
    },
    order: function order(i) {
      return i;
    },
    visible: function visible() {
      return true;
    }
  });
});
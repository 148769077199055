define("bizbase3/pods/user/index/controller", ["exports", "bizbase3/mixins/user-controller", "bizbase3/mixins/notable"], function (exports, _userController, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_userController.default, _notable.default, {
    actions: {
      onResetPassword: function onResetPassword() {
        var _this = this;

        this.get("model").save({ sendWelcome: true }).then(function () {
          _this.success("Passwort wurde zurückgsetzt");
        }).catch(function () {
          _this.error("Passwort konnte nicht zurückgesetzt werden");
        });
      },
      deleteUser: function deleteUser() {
        var _this2 = this;

        var contact = this.get("model"),
            fullName = contact.get("fullName"),
            categoryId = contact.get("category.id");

        contact.destroyRecord().then(function () {
          _this2.transitionToRoute("contacts", categoryId);
          _this2.success(fullName + " erfolgreich gel\xF6scht");
        }).catch(function () {
          contact.rollbackAttributes();
          _this2.error("Fehler beim L\xF6schen von " + fullName);
        });
      }
    },

    afterSave: function afterSave() {
      this.get("users").updateEntries();
    }
  });
});
define("bizbase3/pods/components/store-contact-tab/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_notable.default, {
    store: service(),

    actions: {
      chooseContact: function chooseContact(id) {
        var contact = null;
        if (id) {
          contact = this.get("store").peekRecord("contact", id);
        }

        this.set("model.contact", contact);
        this.set("model.category.contact", contact);
      },
      saveContacty: function saveContacty() {
        var _this = this;

        this.get("model.category").save().then(function () {
          _this.set("chooseEditor", false);
          _this.success("modules.filemanager.tabs.info.notify.saveSuccess");
        }).catch(function () {
          _this.error("modules.filemanager.tabs.info.notify.saveError");
        });
      }
    }
  });
});
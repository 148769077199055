define("bizbase3/pods/components/bb-text-input/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      update: function update(value) {
        this.get("update")(value);
      }
    }
  });
});
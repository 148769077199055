define("bizbase3/utils/computed/fields", ["exports", "bizbase3/utils/data-table-helpers"], function (exports, _dataTableHelpers) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fields;
  var computed = Ember.computed,
      getProperties = Ember.getProperties;


  function makeField(column) {
    var _getProperties = getProperties(column, "key", "type", "visible", "format", "linkable"),
        key = _getProperties.key,
        type = _getProperties.type,
        visible = _getProperties.visible,
        format = _getProperties.format,
        linkable = _getProperties.linkable;

    var value = this.get(key);

    if (typeof _dataTableHelpers.default[type] === "function") {
      value = _dataTableHelpers.default[type](value);
    }

    if (typeof format === "function") {
      value = format(value);
    }

    return { key: key, value: value, visible: visible, linkable: linkable };
  }

  function fields(keys) {
    function theFields() {
      return this.get("columnsConfig.columns").map(makeField.bind(this));
    }

    return computed.apply(this, keys.concat(["columnsConfig.columns.@each.visible", theFields]));
  }
});
define("bizbase3/pods/press-request/index/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      resolve = Ember.RSVP.resolve,
      setProperties = Ember.setProperties;
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      saveRequest: function saveRequest(formData) {
        var _this = this;

        var model = this.get("model");

        setProperties(model, formData);

        model.save().then(function () {
          return _this.success("Presseanfrage erfolgreich gespeichert");
        }).catch(function () {
          return _this.error("Fehler beim Speichern der Presseanfragen");
        });
      },
      postNewMessage: function postNewMessage(text, author, date, isFinished) {
        var _this2 = this;

        var request = this.get("model");
        var message = get(request, "messages").createRecord({
          text: text,
          author: author,
          date: date
        });

        var promise = resolve();

        if (isFinished) {
          promise = request.save({ adapterOptions: { finish: true } });
          promise.then(function () {
            return _this2.success("Presseanfrage erfolgreich abgeschlossen");
          });
        }

        promise.then(function () {
          return message.save({ adapterOptions: { isFinished: isFinished } });
        }).catch(function () {
          return _this2.error("Fehler beim versenden der Nachricht");
        });

        return promise;
      },
      updateMessage: function updateMessage(message, data) {
        message.setProperties(data);
        return message.save();
      }
    }
  });
});
define("bizbase3/helpers/css-width", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cssWidth = cssWidth;


  var htmlSafe = Ember.String.htmlSafe;

  function cssWidth(width) {
    return "width: " + width + "px";
  }

  exports.default = Ember.Helper.helper(function (params /*, hash*/) {
    return htmlSafe(cssWidth(params[0]));
  });
});
define("bizbase3/serializers/user", ["exports", "bizbase3/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      this._super(hash, typeClass, snapshot, options);

      if (snapshot.adapterOptions && snapshot.adapterOptions.contact) {
        var _snapshot$adapterOpti = snapshot.adapterOptions.contact,
            firstName = _snapshot$adapterOpti.firstName,
            lastName = _snapshot$adapterOpti.lastName;

        var email = hash.email;

        hash.contact = { firstName: firstName, lastName: lastName, email: email };
      }
    }
  });
});
define("bizbase3/pods/permission/index/controller", ["exports", "bizbase3/mixins/permission-controller"], function (exports, _permissionController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissionController.default, {
    actions: {
      deletePermission: function deletePermission() {
        var _this = this;

        var contact = this.get("model"),
            name = contact.get("name");

        contact.destroyRecord().then(function () {
          _this.transitionToRoute("permissions");
          _this.success(name + " erfolgreich gel\xF6scht");
        }).catch(function () {
          contact.rollbackAttributes();
          _this.error("Fehler beim L\xF6schen von " + name);
        });
      }
    },

    afterSave: function afterSave() {
      this.get("permissions").updateEntries();
    }
  });
});
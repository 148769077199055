define("bizbase3/mixins/tabbed-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    actions: {
      childTransition: function childTransition(childRouteName, childId) {
        var routeName = this.get("routeName");

        this.get("detailTab").routeChange({ routeName: routeName, childRouteName: childRouteName, childId: childId });
      }
    },

    afterModel: function afterModel(model) {
      this.get("detailTab").restore(get(model, "information"), this);
    },
    beforeModel: function beforeModel() {
      return this.get("detailTab").load();
    },


    detailTab: inject.service()
  });
});
define("bizbase3/components/sidebar-toggle", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "a",

    classNames: ["sidebar-toggle"],

    click: function click() {
      this.toggleProperty("isOn");
    }
  });
});
define("bizbase3/serializers/asset-comment", ["exports", "bizbase3/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      createdByUser: { deserialize: "records" },
      updatedByUser: { deserialize: "records" }
    }
  });
});
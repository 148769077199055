define("bizbase3/pods/components/user-form/component", ["exports", "bizbase3/mixins/notable", "bizbase3/utils/dirty-form-observer"], function (exports, _notable, _dirtyFormObserver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      service = Ember.inject.service,
      on = Ember.on,
      set = Ember.set;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    actions: {
      toggleUserSendWelcome: function toggleUserSendWelcome() {
        this.toggleProperty("user.sendWelcome");
        this.set("data.sendWelcome", this.get("user.sendWelcome"));
      }
    },

    classNames: ["user-form"],

    userGroupOptions: computed("userGroups.groups.@each.{id,name}", function () {
      return this.get("userGroups.groups").map(function (group) {
        return {
          label: get(group, "name"),
          value: get(group, "id")
        };
      });
    }),

    userGroups: service(),

    _loadGroups: on("didInsertElement", function () {
      this.get("userGroups").retrieve();
    }),

    data: computed(function () {
      return {};
    }),

    checkDirtyness: (0, _dirtyFormObserver.default)("firstName", "lastName", "email"),

    submit: function submit(e) {
      var _this = this;

      e.preventDefault();

      var newGroupId = this.get("newGroupId"),
          data = this.get("data"),
          onSave = this.get("onSave");

      if (newGroupId) {
        set(data, "group", this.get("userGroups._groups").findBy("id", newGroupId));
      }

      onSave(data).then(function () {
        return _this.success("generic.notify.saveSuccess", { name: _this.get("user.name") });
      }).catch(function () {
        return _this.error("generic.notify.saveFailed", { name: _this.getFullName() });
      });
    },
    getFullName: function getFullName() {
      return this.get("data.firstName") + " " + this.get("data.lastName");
    },
    onDelete: function onDelete() {},
    onDirt: function onDirt() {}
  });
});
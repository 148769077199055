define("bizbase3/mirage/factories/tag", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    tag: function tag() {
      return _emberCliMirage.faker.lorem.sentence(1, 2).replace(/\.$/, "");
    }
  });
});
define("bizbase3/pods/contact-categories/controller", ["exports", "bizbase3/mixins/categories-controller"], function (exports, _categoriesController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend(_categoriesController.default, {
    dataModelName: "contact",

    i18n: inject.service(),

    pageTitle: computed(function () {
      return this.get("i18n").t("modules.contacts.name");
    }),

    targetRoute: "contacts",

    titleIcon: "address-book",

    write: "contacts.write",

    contacts: inject.controller(),

    categoryMoveSuccess: function categoryMoveSuccess(category, result) {
      this.success("modules.contacts.notify.movedSuccess", {
        name: get(result[0], "fullName"),
        to: get(category, "name"),
        count: result.length
      });
      this.get("contacts").updateEntries();
    },
    categoryMoveError: function categoryMoveError() /*category, error*/{
      this.error("generic.notify.movedFailed");
    }
  });
});
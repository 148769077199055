define("bizbase3/utils/computed/indexed-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = indexedOptions;
  var map = Ember.computed.map;
  function indexedOptions(key) {
    return map(key, function (option, index) {
      return {
        value: index,
        label: option
      };
    });
  }
});
define("bizbase3/pods/knb-entry/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed;

  var inject = Ember.inject;

  exports.default = Ember.Controller.extend({
    sortedInformation: computed("model.information", "informationOrder.config", function () {
      var information = this.get("model.information").slice(),
          order = this.get("informationOrder.config");

      if (order.length !== information.length) {
        order = information.mapBy("type.id");
      }

      return order.map(function (id) {
        return information.findBy("type.id", "" + id);
      });
    }),

    informationOrder: inject.service()
  });
});
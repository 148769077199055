define("bizbase3/models/knb-entry", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment", "bizbase3/utils/data-table-helpers", "bizbase3/helpers/truncate-text"], function (exports, _model, _attr, _relationships, _moment, _dataTableHelpers, _truncateText) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VALID_BAD = exports.VALID_WARN = exports.VALID_GOOD = undefined;
  var computed = Ember.computed,
      alias = Ember.computed.alias,
      get = Ember.get,
      getProperties = Ember.getProperties,
      inject = Ember.inject,
      service = Ember.inject.service;


  var WARNVALIDITY = 5,
      VALID_GOOD = "success",
      VALID_WARN = "warning",
      VALID_BAD = "danger";

  exports.VALID_GOOD = VALID_GOOD;
  exports.VALID_WARN = VALID_WARN;
  exports.VALID_BAD = VALID_BAD;
  exports.default = _model.default.extend({
    viewUrl: computed("id", function () {
      return "/api/v3/knb/entries/" + this.get("id") + "/views";
    }),
    title: (0, _attr.default)("string"),
    views: (0, _attr.default)("number"),
    information: (0, _relationships.hasMany)("custom-information", { async: false }),
    assets: (0, _relationships.hasMany)("asset"),
    archive: (0, _attr.default)("boolean", { defaultValue: false }),
    validFrom: (0, _attr.default)("date"),
    validTill: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    createdAt: (0, _attr.default)("date"),

    category: (0, _relationships.belongsTo)(),
    tags: (0, _relationships.hasMany)(),
    updatedBy: (0, _relationships.belongsTo)("user"),
    user: (0, _relationships.belongsTo)(),

    stores: (0, _relationships.hasMany)(),
    pressRequests: (0, _relationships.hasMany)(),

    categoryId: computed.reads("category.id"),

    daysValid: computed("validTill", function () {
      var diff = (0, _moment.default)(this.get("validTill")).diff(new Date());
      return _moment.default.duration(diff).asDays();
    }),

    fields: computed("title", "archive", "views", "validFrom", "validTill", "user.fullName", "information.@each.{content,typeId}", "category.name", "category.id", "knbColumns.columns.@each.{key,typeId,linkable,type,format,visible}", function () {
      var _this = this;

      var columns = this.get("knbColumns.columns");

      return columns.map(function (column) {
        var _getProperties = getProperties(column, "key", "typeId", "type", "linkable", "format", "visible"),
            key = _getProperties.key,
            typeId = _getProperties.typeId,
            type = _getProperties.type,
            linkable = _getProperties.linkable,
            format = _getProperties.format,
            visible = _getProperties.visible;

        var information = _this.get("information");

        var value = void 0;

        if (!typeId) {
          value = _this.get(key);
        } else {
          var info = information.findBy("type.id", typeId);
          if (!info) {
            info = {};
          }
          value = (0, _truncateText.truncate)(get(info, "plaintext"), 20);
        }

        if (typeof _dataTableHelpers.default[type] === "function") {
          value = _dataTableHelpers.default[type](value);
        }

        if (typeof format === "function" && value) {
          value = format(value);
        }

        return { key: key, value: value, visible: visible, linkable: linkable };
      });
    }),

    validity: computed("daysValid", "validTill", function () {
      if (this.get("validTill")) {
        var daysValid = this.get("daysValid");

        if (daysValid >= 0 && daysValid <= WARNVALIDITY) {
          return VALID_WARN;
        } else if (daysValid < 0) {
          return VALID_BAD;
        }
      }
    }),

    knbColumns: inject.service(),

    columns: alias("knbColumns.columns"),

    links: computed("stores", "pressRequests", function () {
      return [{ model: this.get("stores"), moduleName: "stores", route: "store" }, { model: this.get("pressRequests"), moduleName: "pressrequests", route: "press-request" }];
    }),

    hasLinks: computed("links.@each.model.length", function () {
      return !!this.get("links").some(function (link) {
        return get(link, "model.length");
      });
    }),

    session: service(),

    logView: function logView() {
      var url = this.get("viewUrl");
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: this.get("session.data.authenticated.jwt")
        }
      });
    }
  });
});
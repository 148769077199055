define("bizbase3/services/groups-columns", ["exports", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service"], function (exports, _settingsHandler, _columnsService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed;


  var STORAGE_KEY = "BIZBASE3.groups.columnConfig";

  var MODULE_NAME = "groups";

  var TRANSLATION_PATH = "modules.settings.sub.groups.columns.";

  var COLUMNS = [{ key: "id" }, { key: "name" }, { key: "info" }];

  exports.COLUMNS = COLUMNS;
  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    storageKey: STORAGE_KEY,
    moduleName: MODULE_NAME,
    translationPath: TRANSLATION_PATH,

    _columns: computed(function () {
      return COLUMNS;
    })
  });
});
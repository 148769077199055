define("bizbase3/services/reminder", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Service.extend(_notable.default, {
    reminders: [],
    createReminder: function createReminder() {
      return this.get("store").createRecord("reminder", { isNew: true });
    },
    pushReminder: function pushReminder(reminder) {
      if (reminder.get("isNew")) {
        reminder.set("isNew", null);
        this.get("reminders").pushObject(reminder);
      }
    },
    fetch: function fetch() {
      var _this = this;

      this.get("store").query("reminder", {
        page: 1,
        perPage: 20,
        multiSort: [{
          sortKey: "done",
          sortDir: "asc"
        }, {
          sortKey: "doneAt",
          sortDir: "desc"
        }, {
          sortKey: "notificationAt",
          sortDir: "desc"
        }]
      }).then(function (reminders) {
        _this.get("reminders").addObjects(reminders);
      }, function (error) {
        _this.set("error", error);
      });
    },


    store: inject.service(),

    remindersSorted: computed("reminders", "reminders.[]", "reminders.@each.notification", "reminders.@each.deleted", function () {
      if (this.get("reminders.length") > 0) {
        return this.get("reminders").sortBy("notification");
      } else {
        return [];
      }
    }),

    badgeCount: computed("reminders.@each", "reminders.@each.done", "reminders.@each.doneAt", "reminders.@each.deleted", function () {
      var date = new Date();
      if (this.get("reminders")) {
        return this.get("reminders").filter(function (reminder) {
          return !reminder.get("done") && !reminder.get("deleted") && reminder.get("doneAt") < date;
        }).length;
      } else {
        return 0;
      }
    })
  });
});
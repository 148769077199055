define('bizbase3/initializers/ember-cli-mirage', ['exports', 'ember-cli-mirage/utils/read-modules', 'bizbase3/config/environment', 'bizbase3/mirage/config', 'ember-cli-mirage/server'], function (exports, _readModules, _environment, _config, _server) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-cli-mirage',
    initialize: function initialize(application) {
      if (arguments.length > 1) {
        // Ember < 2.1
        var container = arguments[0],
            application = arguments[1];
      }
      var environment = _environment.default.environment;

      if (_shouldUseMirage(environment, _environment.default['ember-cli-mirage'])) {
        var modules = (0, _readModules.default)(_environment.default.modulePrefix);
        var options = _.assign(modules, { environment: environment, baseConfig: _config.default, testConfig: _config.testConfig });

        new _server.default(options);
      }
    }
  };


  function _shouldUseMirage(env, addonConfig) {
    var userDeclaredEnabled = typeof addonConfig.enabled !== 'undefined';
    var defaultEnabled = _defaultEnabled(env, addonConfig);

    return userDeclaredEnabled ? addonConfig.enabled : defaultEnabled;
  }

  /*
    Returns a boolean specifying the default behavior for whether
    to initialize Mirage.
  */
  function _defaultEnabled(env, addonConfig) {
    var usingInDev = env === 'development' && !addonConfig.usingProxy;
    var usingInTest = env === 'test';

    return usingInDev || usingInTest;
  }
});
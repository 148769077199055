define("bizbase3/pods/knb-entry/information/index/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      save: function save() {
        var _this = this;

        this.get("model").save().then(function (information) {
          _this.success("Information gespeichert");
          _this.send("informationSaved", information);
        }).catch(function () {
          _this.error("Fehler beim Speichern der Information");
        });
      },
      cancel: function cancel() {
        this.toggleProperty("isEditing");
        this.get("model").rollbackAttributes();
      }
    }
  });
});
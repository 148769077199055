define("bizbase3/services/ican", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    session: service(),

    authorized: function authorized(permission) {
      if (this.get("session.data.testpermissions")) {
        return this.get("session.data.testpermissions").includes(permission);
      } else if (this.get("session.data.authenticated.permissions")) {
        return this.get("session.data.authenticated.permissions").includes(permission);
      }
      return false;
    }
  });
});
define("bizbase3/mixins/searchable", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Evented = Ember.Evented,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      getProperties = Ember.getProperties,
      merge = Ember.merge,
      isEmpty = Ember.isEmpty;
  exports.default = Ember.Mixin.create(_notable.default, Evented, {
    actions: {
      resetSearch: function resetSearch() {
        var paginationArray = this.get("paginationArray");

        if (paginationArray.get("isSearched")) {
          paginationArray.resetSearch();
        }

        this.notifyPropertyChange("searchFields"); // recompute from default fields
        this.set("searchQuery", undefined);
      },
      searchItems: function searchItems(query, keys) {
        this.set("searchQuery", query);
        this.query(query, keys);
      }
    },

    queryParams: [{ searchQuery: "q" }],

    query: function query(_query, keys) {
      var _this = this;

      var paginationArray = this.get("paginationArray");

      if (!isEmpty(_query) && !get(paginationArray, "isPending")) {
        paginationArray.search(_query, keys).then(function () {
          return _this.set("page", 1);
        });
      }
    },


    isSearchResetable: computed("paginationArray.isSearched", "searchFields.@each.{key,checked}", "defaultSearchFields.@each.{key,checked}", function () {
      var _this2 = this;

      return this.get("paginationArray.isSearched") || this.get("defaultSearchFields").any(function (defaultField) {
        var field = _this2.get("searchFields").findBy("key", get(defaultField, "key"));
        return get(field, "checked") !== get(defaultField, "checked");
      });
    }),

    // CAVEAT: This gets recomputed when `customFields` comes from columns, has dependent keys and the
    // service reloads the column config. When the service is told to do that upon page load, search
    // fields are NOT persisted during page visits. See #114
    searchFields: computed("defaultSearchFields.@each.{key,label,checked,searchPath}", {
      get: function get() {
        return this.get("defaultSearchFields").map(function (field) {
          return getProperties(field, "key", "label", "searchPath", "checked");
        });
      },
      set: function set(key, value) {
        return value;
      }
    }),

    searchKeys: computed("searchFields.@each.{key,checked}", function () {
      return this.get("searchFields").filterBy("checked").mapBy("key");
    }),

    defaultSearchFields: computed("customFields.@each.{key,label,notSearchable,searchPath,checked}", function () {
      return this.get("customFields").filter(function (field) {
        return !get(field, "notSearchable");
      }).map(function (field) {
        return merge({ checked: getWithDefault(field, "checked", true) }, getProperties(field, "key", "label", "searchPath"));
      });
    })
  });
});
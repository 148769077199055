define("bizbase3/mixins/group-controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      controller = Ember.inject.controller;
  exports.default = Ember.Mixin.create({
    actions: {
      createNewGroup: function createNewGroup() {
        this.set("groupModel", this.get("store").createRecord("group"));
      },
      cancelNewGroup: function cancelNewGroup() {
        var newGroup = this.get("groupModel");

        if (newGroup) {
          newGroup.deleteRecord();
        }

        this.set("groupModel", null);
      },
      saveGroup: function saveGroup(data) {
        var _this = this;

        var group = this.get("groupModel");

        group.setProperties(data);

        var name = data.name;

        var promise = group.save({ adapterOptions: { group: { name: name } } });

        promise.then(function () {
          return _this.afterSave(group);
        }).catch(function (e) {
          group.rollbackAttributes();
          throw e;
        }).finally(function () {
          return _this.finallySave(group);
        });

        return promise;
      }
    },

    groups: controller(),

    groupModel: computed.alias("model"),

    afterSave: function afterSave() {},
    finallySave: function finallySave() {}
  });
});
define("bizbase3/pods/press-request-categories/controller", ["exports", "bizbase3/mixins/categories-controller"], function (exports, _categoriesController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      controller = Ember.inject.controller;
  exports.default = Ember.Controller.extend(_categoriesController.default, {
    dataModelName: "press-request",

    pageTitle: "Anfragen",

    targetRoute: "press-requests",

    titleIcon: "comment",

    write: "press-requests.write",

    categoryMoveSuccess: function categoryMoveSuccess(category, result) {
      var count = get(result, "length");

      var title = count + " Anfragen";

      if (count === 1) {
        title = "Anfrage von " + get(result[0], "contact.fullName");
      }

      this.success(title + " nach " + get(category, "name") + " verschoben");

      this.get("pressRequests").updateEntries();
    },
    categoryMoveError: function categoryMoveError() /*category, error*/{
      this.error("Fehler beim Verschieben der Anfragen");
    },


    pressRequests: controller()
  });
});
define("bizbase3/models/asset-comment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    text: (0, _attr.default)("string"),
    createdAt: (0, _attr.default)("date"),

    asset: (0, _relationships.belongsTo)({ async: false }),
    createdByUser: (0, _relationships.belongsTo)("user", { async: false }),
    updatedByUser: (0, _relationships.belongsTo)("user", { async: false })
  });
});
define("bizbase3/pods/store-categories/controller", ["exports", "bizbase3/mixins/categories-controller", "bizbase3/mixins/notable"], function (exports, _categoriesController, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend(_categoriesController.default, _notable.default, {
    dataModelName: "store",

    /**
     * Specify no page title as the root category is the page title
     */
    i18n: inject.service(),

    stores: inject.controller(),

    pageTitle: computed(function () {
      return this.get("i18n").t("modules.stores.name");
    }),

    targetRoute: "stores",

    titleIcon: "cart",

    categoryMoveSuccess: function categoryMoveSuccess(category, result) {
      var title = void 0;
      if (result.length === 1) {
        title = get(result[0], "title");
      } else {
        title = result.length + " Eintr\xE4ge";
      }
      this.get("stores").updateEntries();

      this.success("generic.notify.movedItemToSuccess", { item: title, to: get(category, "name") });
    },
    categoryMoveError: function categoryMoveError() /*category, error*/{
      this.error("generic.notify.movedFailed");
    },


    write: "knowledgebase.write"
  });
});
define("bizbase3/pods/permissions/new/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get("store"),
          permission = store.createRecord("permission");
      return permission;
    }
  });
});
define("bizbase3/mixins/remote-pagination", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      observer = Ember.observer,
      on = Ember.on,
      run = Ember.run;


  var PER_PAGE = 10;

  exports.PER_PAGE = PER_PAGE;
  exports.default = Ember.Mixin.create({
    updatePage: observer("page", function () {
      var paginationArray = this.get("paginationArray"),
          page = this.get("page");

      if (paginationArray && get(paginationArray, "page") !== page) {
        run.debounce(paginationArray, "updatePage", page, 250);
      }
    }),

    updateSorting: observer("sortKey", "sortDir", function () {
      var paginationArray = this.get("paginationArray"),
          _getProperties = this.getProperties("sortKey", "sortDir"),
          sortKey = _getProperties.sortKey,
          sortDir = _getProperties.sortDir;


      if (paginationArray) {
        run.debounce(paginationArray, "updateSorting", sortKey, sortDir, 250);
      }
    }),

    updatePerPage: observer("perPage", function () {
      var paginationArray = this.get("paginationArray"),
          perPage = this.get("perPage");

      if (paginationArray) {
        run.debounce(paginationArray, "updatePerPage", perPage, 250);
      }
    }),

    updateEntries: on("recordsDeleted", function () {
      return this.get("paginationArray").update();
    }),

    showTable: computed("paginationArray.{length,total,isSearched}", function () {
      return this.get("paginationArray.length") > 0 || this.get("paginationArray.total") > 0 || this.get("paginationArray.isSearched");
    }),

    tooMuchEntries: computed("paginationArray.{length,total}", function () {
      return this.get("paginationArray.length") === 0 && this.get("paginationArray.total") > 0;
    }),

    page: 1,

    perPage: PER_PAGE,

    perPageOptions: computed(function () {
      return [{ label: 5, value: 5 }, { label: 10, value: 10 }, { label: 15, value: 15 }, { label: 20, value: 20 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }];
    })
  });
});
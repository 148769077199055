define("bizbase3/mixins/has-context-menu", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      on = Ember.on;
  exports.default = Ember.Mixin.create({
    triggerContextMenu: on("contextMenu", function (e) {
      this.get("contextMenuManager").trigger("showContextMenu", e, this.get("contextMenuId"), this.get("contextMenuData"));
    }),

    contextMenuManager: inject.service()
  });
});
define("bizbase3/models/custom-information-version", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    content: attr("string"),
    version: attr("number"),
    createdAt: attr("date")
  });
});
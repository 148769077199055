define("bizbase3/pods/asset/exif/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var keys = Object.keys;


  function makeSection(exif) {
    return function (title) {
      var exifKeys = keys(exif[title]).sort().map(function (key) {
        return { key: key, value: exif[title][key] };
      });

      return { title: title, exifKeys: exifKeys };
    };
  }

  exports.default = Ember.Controller.extend({
    data: computed("model.exif", function () {
      var exif = this.get("model.exif");

      return keys(exif).sort().map(makeSection(exif));
    })
  });
});
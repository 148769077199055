define("bizbase3/pods/group/permissions/route", ["exports", "bizbase3/utils/pagination-array"], function (exports, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject;
  exports.default = Ember.Route.extend({
    groupsColumns: inject.service(),

    model: function model(params) {
      var groupQuery = {
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };
      var group = this.modelFor("group");

      return hash({
        group: group,
        permissions: (0, _paginationArray.makePaginationQuery)(this.get("store"), "permission", groupQuery, 1, 200)
      });
    }
  });
});
define("bizbase3/mixins/columns-service", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      getProperties = Ember.getProperties,
      run = Ember.run,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Ember.Mixin.create(_notable.default, {
    i18n: service(),

    showAll: function showAll() {
      var _this = this;

      var config = this.get("config").map(function (column) {
        set(column, "visible", true);
        return column;
      });

      this.set("config", config);
      this.save().then(function () {
        return _this.success("Sichtbarkeit gespeichert");
      });
    },
    toggleVisibility: function toggleVisibility(column) {
      var _this2 = this;

      run.debounce(this, function () {
        var key = get(column, "key"),
            config = _this2.get("config");

        var columnConfig = config.findBy("key", key);

        if (!columnConfig) {
          config.pushObject(columnConfig = { key: key, visible: true });
        }

        set(columnConfig, "visible", !get(columnConfig, "visible"));

        _this2.set("config", config);
        _this2.save().then(function () {
          return _this2.success("Sichtbarkeit gespeichert");
        });
      }, 500, true);
    },


    columns: computed("moduleName", "config.@each.{key,visible}", "_columns.@each.{key,type,typeId,label,linkable,format,notSearchable,searchPath,searchview}", function () {
      var _this3 = this;

      return this.get("_columns").map(function (column) {
        var _getProperties = getProperties(column, "key", "type", "typeId", "label", "linkable", "format", "notSearchable", "searchPath", "searchview"),
            key = _getProperties.key,
            type = _getProperties.type,
            typeId = _getProperties.typeId,
            label = _getProperties.label,
            linkable = _getProperties.linkable,
            format = _getProperties.format,
            notSearchable = _getProperties.notSearchable,
            searchPath = _getProperties.searchPath,
            searchview = _getProperties.searchview;

        var config = _this3.get("config").findBy("key", key);

        if (!config) {
          config = { visible: true };
        }

        var moduleName = _this3.get("moduleName");
        var translationPath = _this3.get("translationPath");
        var translationLable = label;
        if (!label) {
          translationLable = key;
          if (moduleName) {
            var translation = translationPath ? translationPath + key : "modules." + moduleName + ".columns." + key;
            translationLable = _this3.get("i18n").t(translation);
          }
        }

        return {
          key: key,
          label: translationLable,
          linkable: linkable,
          type: type,
          typeId: typeId,
          format: format,
          searchview: searchview,
          notSearchable: notSearchable,
          searchPath: searchPath,
          visible: get(config, "visible")
        };
      });
    })
  });
});
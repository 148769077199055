define("bizbase3/services/settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var get = Ember.get;

  var inject = Ember.inject;

  exports.default = Ember.Service.extend({
    query: function query(key) {
      var store = this.get("store");

      return store.query("setting", { key: key }).then(function (settings) {
        if (get(settings, "length") === 1) {
          return get(settings, "firstObject");
        } else {
          return store.createRecord("setting", { key: key });
        }
      });
    },


    store: inject.service()
  });
});
define("bizbase3/pods/settings/search/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Controller.extend(_notable.default, {
    indices: {
      knb: "knb",
      contacts: "contacts",
      pressinquries: "pressrequests",
      stores: "stores",
      assets: "filemanager"
    },

    modalDialog: inject.service(),
    search: inject.service(),

    actions: {
      reindex: function reindex(type) {
        var _this = this;

        var name = this.get("indices." + type);
        this.get("search").reindex(type).then(function (data) {
          if (data.success) {
            _this.success("Indizierung " + name + " erfolgreich");
          } else {
            _this.error("Indizierung " + name + " konnte nicht durchgef\xFChrt werden");
          }
        }).fail(function () {
          _this.error("Indizierung " + name + " konnte nicht durchgef\xFChrt werden");
        });
      },
      deleteIndex: function deleteIndex(type) {
        var _this2 = this;

        var name = this.get("indices." + type);

        var message = "Wollen Sie wirklich alle Eintr\xE4ge aus  " + name + " l\xF6schen?";
        this.get("modalDialog").confirm(message).then(function () {
          _this2.get("search").deleteIndex(type).then(function (data) {
            if (data.success) {
              _this2.success("Sucheeintr\xE4ge aus " + name + " gel\xF6scht");
            } else {
              _this2.error("Sucheeintr\xE4ge  " + name + " konnten nicht gel\xF6scht werden");
            }
          }).fail(function () {
            _this2.error("Sucheeintr\xE4ge  " + name + " konnten nicht gel\xF6scht werden");
          });
        });
      }
    }
  });
});
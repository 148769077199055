define("bizbase3/helpers/invalidate-session", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.invalidateSession = invalidateSession;
  var service = Ember.inject.service;
  function invalidateSession() {
    var _this = this;

    return function () {
      return _this.get("session").invalidate();
    };
  }

  exports.default = Ember.Helper.extend({
    compute: invalidateSession,
    session: service()
  });
});
define("bizbase3/models/user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "bizbase3/utils/computed/fields", "bizbase3/services/users-columns"], function (exports, _model, _attr, _relationships, _fields, _usersColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _model.default.extend({
    email: (0, _attr.default)("string"),

    lastLogin: (0, _attr.default)("date"),

    password: (0, _attr.default)("string"),

    newPassword: (0, _attr.default)("string"),

    verifyPassword: (0, _attr.default)("string"),

    forceNewPassword: (0, _attr.default)("boolean", { defaultValue: false }),

    sendWelcome: (0, _attr.default)("boolean", { defaultValue: false }),

    ldap: (0, _attr.default)("boolean", { defaultValue: false }),

    group: (0, _relationships.belongsTo)(),

    contact: (0, _relationships.belongsTo)(),

    columnsConfig: inject.service("users-columns"),

    fields: (0, _fields.default)(_usersColumns.COLUMNS.mapBy("key")),

    firstName: computed.reads("contact.firstName"),

    lastName: computed.reads("contact.lastName"),

    fullName: computed.reads("contact.fullName")
  });
});
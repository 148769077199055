define("bizbase3/pods/search/controller", ["exports", "bizbase3/mixins/remote-pagination"], function (exports, _remotePagination) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_remotePagination.default, {
    actions: {
      search: function search(e) {
        if (e) {
          e.preventDefault();
        }

        this.set("q", this.get("newQ"));
        this.update();
      },
      changePage: function changePage(page) {
        this.set("page", page);
        this.update();
      }
    },

    search: service(),

    assetUsageLabels: service(),

    queryParams: ["q", "perPage", { page: "searchPage" }],

    q: "",

    error: false,

    data: computed.reads("model"),

    pressRequestStateLabels: service(),

    iconTypes: {
      knb_entries: "book",
      contacts: "address-book",
      pressinquiry: "comment",
      stores: "shopping-cart",
      knb_assets: "folder-open"
    },

    textTypes: {
      knb_entries: "modules.knb.name",
      contacts: "modules.contacts.name",
      pressinquiry: "modules.pressrequests.name",
      stores: "modules.stores.name",
      knb_assets: "modules.filemanager.name"
    },

    linkTypes: {
      knb_entries: "knb-entry",
      contacts: "contact",
      pressinquiry: "press-request",
      stores: "store",
      knb_assets: "asset"
    },

    update: function update() {
      this.set("data", this.get("search").globalSearch(this.get("q"), this.get("page")));
    }
  });
});
define("bizbase3/models/album", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    title: attr("string"),

    category: belongsTo("category"),

    expirationDate: attr("date"),

    assets: hasMany("asset"),

    shares: hasMany("share")
  });
});
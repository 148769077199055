define("bizbase3/services/user-groups", ["exports", "bizbase3/mixins/data-retrieval-service"], function (exports, _dataRetrievalService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Service.extend(_dataRetrievalService.default, {
    _dataKey: "_groups",

    _modelKey: "group",

    _groups: computed(function () {
      return [];
    }),

    groups: computed("_groups.@each.{id,name}", function () {
      return this.get("_groups").map(function (group) {
        return {
          id: get(group, "id"),
          name: get(group, "name"),
          checked: false
        };
      });
    })
  });
});
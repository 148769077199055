define("bizbase3/pods/permissions/new/controller", ["exports", "bizbase3/mixins/permission-controller"], function (exports, _permissionController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissionController.default, {
    afterSave: function afterSave() {
      this.get("permissions").updateEntries();
      this.transitionToRoute("permission", this.get("model"));
    }
  });
});
define("bizbase3/initializers/moment", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;


  function initialize() /* application */{
    _moment.default.locale("de");
  }

  var MomentInitializer = {
    name: "moment",
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = MomentInitializer;
});
define("bizbase3/pods/users/route", ["exports", "bizbase3/utils/pagination-array"], function (exports, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject;
  exports.default = Ember.Route.extend({
    usersColumns: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("usersColumns").load();
    },
    model: function model(params) {
      var userQuery = {
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      return hash({
        users: (0, _paginationArray.makePaginationQuery)(this.get("store"), "user", userQuery, params.page, params.perPage)
      });
    }
  });
});
define("bizbase3/pods/components/reminder-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    actions: {
      create: function create(item, peekname) {
        if (peekname) {
          item = this.get("store").peekRecord(peekname, Ember.isArray(item) ? item[0] : item);
        }
        var reminder = this.get("reminder").createReminder();
        reminder.set(this.get("relation"), item);
        this.set("newReminder", reminder);
        this.set("showModal", true);
      }
    },

    showModal: false,

    tagName: "",

    reminder: service(),
    store: service()
  });
});
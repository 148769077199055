define("bizbase3/pods/components/split-handle/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["split-handle"],

    classNameBindings: ["dragging"]
  });
});
define("bizbase3/helpers/truncate-text", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncate = truncate;
  exports.truncateText = truncateText;
  function truncate(string, length) {
    var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "...";

    string = "" + string;
    suffix = "" + suffix;

    if (string.length <= length) {
      return string;
    }

    return string.slice(0, length - suffix.length) + suffix;
  }

  function truncateText(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return truncate(params[0], params[1], hash.suffix);
  }

  exports.default = Ember.Helper.helper(truncateText);
});
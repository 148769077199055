define("bizbase3/mirage/fixtures/categories", ["exports", "bizbase3/mirage/factories/category"], function (exports, _category) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var factory = new _category.default();

  var category = factory.build(1);

  category.id = 1;

  exports.default = [category];
});
define("bizbase3/mirage/factories/category", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.sentence(2, 4).slice(0, -1);
    },


    parent: null,

    children: []
  });
});
define("bizbase3/pods/components/user-search/mixins/table-common", ["exports", "ember-light-table", "ember-concurrency"], function (exports, _emberLightTable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),

    page: 0,
    limit: 10,
    dir: "asc",
    sort: "firstName",

    isLoading: Ember.computed.oneWay("fetchRecords.isRunning"),
    canLoadMore: true,
    enableSync: true,
    meta: null,
    columns: null,
    table: null,

    init: function init() {
      this._super.apply(this, arguments);
      var table = new _emberLightTable.default(this.get("columns"), this.get("model"), {
        enableSync: this.get("enableSync")
      });
      var sortColumn = table.get("allColumns").findBy("valuePath", this.get("sort"));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set("sorted", true);
      }

      this.set("table", table);
    },


    fetchRecords: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var records;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this.get("store").query("contact", this.getProperties(["page", "limit", "sort", "dir"]));

            case 2:
              records = _context.sent;

              this.get("model").pushObjects(records.toArray());
              this.set("meta", records.get("meta"));
              this.set("canLoadMore", !Ember.isEmpty(records));

            case 6:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get("canLoadMore")) {
          this.incrementProperty("page");
          this.get("fetchRecords").perform();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? "asc" : "desc",
            sort: column.get("valuePath"),
            canLoadMore: true,
            page: 0
          });
          this.get("model").clear();
        }
      }
    }
  });
});
define("bizbase3/pods/components/thumbnail-gallery/component", ["exports", "bizbase3/mixins/download-action"], function (exports, _downloadAction) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_downloadAction.default, {
    classNames: ["thumbnail-gallery"],

    classNameBindings: ["isPending:pending"]
  });
});
define("bizbase3/utils/notify", ["exports", "ember-notify"], function (exports, _emberNotify) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.error = exports.success = undefined;


  function success(message) {
    _emberNotify.default.success(message, { radius: true });
  }

  function error(message) {
    _emberNotify.default.error(message, { radius: true });
  }

  exports.success = success;
  exports.error = error;
});
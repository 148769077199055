define("bizbase3/pods/components/contact-button/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_notable.default, {
    classNames: ["contact-button"],
    showModal: false,

    store: service(),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get("contactId") > 0) {
        this.set("contact", this.get("store").peekRecord("contact", this.get("contactId")));
      }
    },


    actions: {
      onselect: function onselect(row) {
        this.get("onChange")(row.get("id"));
        this.set("contact", this.get("store").peekRecord("contact", row.get("id")));
        this.set("showModal", false);
      },

      submit: function submit() {
        this.set("showModal", false);
      },
      close: function close() {
        this.set("showModal", false);
      },
      open: function open() {
        this.toggleProperty("showModal");
      },
      remove: function remove() {
        this.get("onChange")(null);
        this.set("contact", null);
      }
    }
  });
});
define("bizbase3/services/current-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      resolve = Ember.RSVP.resolve;
  exports.default = Ember.Service.extend({
    session: service(),

    store: service(),

    load: function load() {
      var _this = this;

      var userId = this.get("session.data.authenticated.uid");

      if (!isEmpty(userId)) {
        return this.get("store").findRecord("user", userId).then(function (user) {
          return _this.set("user", user);
        });
      }

      return resolve();
    },


    fullName: computed.reads("user.fullName")
  });
});
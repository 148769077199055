define("bizbase3/pods/components/asset-form/component", ["exports", "bizbase3/utils/computed/indexed-options"], function (exports, _indexedOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    assetUsageLabels: service(),

    types: ["file", "clipping", "clippingblanko"],

    usageOptions: (0, _indexedOptions.default)("assetUsageLabels.config")
  });
});
define("bizbase3/adapters/press-request", ["exports", "bizbase3/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    updateRecord: function updateRecord(store, type, snapshot) {
      if (!get(snapshot, "adapterOptions.finish")) {
        return this._super(store, type, snapshot);
      }

      var url = this.buildURL(type.modelName, snapshot.id, snapshot, "updateRecord") + "/finish";

      return this.ajax(url, "PUT");
    }
  });
});
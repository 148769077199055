define("bizbase3/utils/pagination-array", ["exports", "ember-data", "bizbase3/mixins/remote-pagination"], function (exports, _emberData, _remotePagination) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.makePaginationQuery = undefined;
  var ArrayProxy = Ember.ArrayProxy,
      computed = Ember.computed,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      RSVP = Ember.RSVP,
      setProperties = Ember.setProperties;
  var PromiseArray = _emberData.default.PromiseArray;


  function makePaginationQuery(store, modelName, query, page, perPage) {
    var paginationArray = PaginationArray.create({ currentQuery: { page: page, perPage: perPage } });

    paginationArray.set("store", store);
    paginationArray.set("modelName", modelName);

    return new RSVP.Promise(function (resolve, reject) {
      paginationArray.query(query, page, perPage).then(function () {
        return resolve(paginationArray);
      }).catch(reject);
    });
  }

  var PaginationArray = ArrayProxy.extend({
    _query: function _query() {
      var _this = this;

      this.set("isPending", true);

      var _getProperties = this.getProperties("modelName", "currentQuery"),
          modelName = _getProperties.modelName,
          currentQuery = _getProperties.currentQuery;

      Object.keys(currentQuery).forEach(function (key) {
        return currentQuery[key] == null && delete currentQuery[key];
      });

      var promise = this.get("store").query(modelName, currentQuery),
          newContent = PromiseArray.create({ promise: promise });

      promise.then(function (result) {
        var total = get(result, "meta.total"),
            page = get(result, "meta.page");

        if (page) {
          _this.set("currentQuery.page", page);
        }

        _this.set("total", total);
        _this.set("content", newContent);
      }).finally(function () {
        return _this.set("isPending", false);
      });

      return newContent;
    },
    query: function query(_query2) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _remotePagination.PER_PAGE;

      if (!_query2) {
        _query2 = this.get("currentQuery");
      }

      _query2.page = page;
      _query2.perPage = perPage;

      this.set("currentQuery", _query2);

      return this._query();
    },
    updateQuery: function updateQuery(query) {
      setProperties(this.get("currentQuery"), query);
      return this._query();
    },
    updatePage: function updatePage(page) {
      return this.updateQuery({ page: page });
    },
    updatePerPage: function updatePerPage(perPage) {
      return this.updateQuery({ perPage: perPage });
    },
    updateSorting: function updateSorting(sortKey, sortDir) {
      return this.updateQuery({ sortKey: sortKey, sortDir: sortDir });
    },
    update: function update() {
      return this._query();
    },
    queryPageOfRecordId: function queryPageOfRecordId(id) {
      var _this2 = this;

      if (!this.any(function (record) {
        return get(record, "id") === id;
      })) {
        this.set("currentQuery.page", null);
        this.set("currentQuery.recordId", id);

        var promise = this._query();

        promise.finally(function () {
          return _this2.set("currentQuery.recordId", null);
        });

        return promise;
      }
    },
    resetSearch: function resetSearch() {
      this.set("currentQuery.search", {});

      return this.update();
    },
    search: function search(query, fields) {
      if (!fields) {
        fields = [];
      }

      this.set("currentQuery.page", 1);
      this.set("currentQuery.search", { query: query, fields: fields });

      return this.update();
    },


    currentQuery: computed(function () {
      return {};
    }),

    isPending: false,

    isSearched: computed("currentQuery.search.query", function () {
      return !isEmpty(this.get("currentQuery.search.query"));
    }),

    total: 0,

    page: computed.reads("currentQuery.page"),

    perPage: computed.reads("currentQuery.perPage"),

    from: computed("currentQuery.page", "currentQuery.perPage", function () {
      var page = this.get("currentQuery.page");
      return 1 + (page - 1) * this.get("currentQuery.perPage");
    }),

    to: computed("from", "currentQuery.perPage", "total", function () {
      return Math.min(this.get("total"), this.get("from") + this.get("currentQuery.perPage") - 1);
    }),

    pages: computed("total", "currentQuery.perPage", function () {
      return Math.ceil(this.get("total") / this.get("currentQuery.perPage"));
    }),

    pagination: computed.gt("pages", 1)
  });

  exports.makePaginationQuery = makePaginationQuery;
  exports.default = PaginationArray;
});
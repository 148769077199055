define("bizbase3/mirage/fixtures/permissions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 1,
    permission: "knowledgebase.read",
    name: "Knowledgebase - Lesen",
    info: null,
    created_at: "2016-12-01 16:13:49",
    updated_at: "2016-11-12 21:53:51"
  }, {
    id: 2,
    permission: "knowledgebase.write",
    name: "Knowledgebase - schreiben",
    info: null,
    created_at: "2016-10-16 01:15:25",
    updated_at: "2017-06-19 02:53:30"
  }, {
    id: 3,
    permission: "press.read",
    name: "Presse - lesen",
    info: null,
    created_at: "2016-09-05 17:09:00",
    updated_at: "2017-03-13 16:16:27"
  }, {
    id: 4,
    permission: "press.write",
    name: "Presse - schreiben",
    info: null,
    created_at: "2017-01-31 16:50:48",
    updated_at: "2017-04-27 03:36:08"
  }, {
    id: 5,
    permission: "contacts.read",
    name: "Kontakte - lesen",
    info: null,
    created_at: "2016-10-31 13:13:36",
    updated_at: "2016-10-18 17:42:43"
  }, {
    id: 6,
    name: "Kontakte - schreiben",
    permission: "contacts.write",
    info: null,
    created_at: "2016-09-12 03:15:37",
    updated_at: "2017-03-21 07:04:11"
  }, {
    id: 7,
    name: "Einstellungen - lesen",
    permission: "settings.read",
    info: null,
    created_at: "2016-09-29 13:29:58",
    updated_at: "2017-08-04 11:47:57"
  }, {
    id: 8,
    name: "Einstellungen - schreiben",
    permission: "settings.write",
    info: null,
    created_at: "2016-12-18 08:36:25",
    updated_at: "2017-02-25 23:41:45"
  }, {
    id: 9,
    name: "Stores - lesen",
    permission: "stores.read",
    info: null,
    created_at: "2017-02-16 21:16:44",
    updated_at: "2017-08-04 12:56:00"
  }, {
    id: 10,
    name: "Stores - schreiben",
    permission: "stores.write",
    info: null,
    created_at: "2017-03-04 01:14:25",
    updated_at: "2016-09-14 12:05:19"
  }, {
    id: 11,
    name: "Filemanager - lesen",
    permission: "filemanager.read",
    info: null,
    created_at: "2016-10-26 01:30:56",
    updated_at: "2017-07-08 04:00:02"
  }, {
    id: 12,
    name: "Filemanager - schreiben",
    permission: "filemanager.write",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }, {
    id: 13,
    name: "Filemanager - download",
    permission: "filemanager.download",
    info: null,
    created_at: "2016-09-28 00:56:21",
    updated_at: "2016-10-20 10:37:27"
  }, {
    id: 14,
    name: "Benutzer - lesen",
    permission: "users.read",
    info: null,
    created_at: "2017-06-15 18:59:39",
    updated_at: "2017-03-26 05:10:15"
  }, {
    id: 15,
    name: "Benutzer - schreiben",
    permission: "users.write",
    info: null,
    created_at: "2017-03-25 20:43:36",
    updated_at: "2017-05-04 20:45:39"
  }, {
    id: 16,
    name: "Gruppen - lesen",
    permission: "groups.read",
    info: null,
    created_at: "2017-04-15 00:42:20",
    updated_at: "2017-04-05 16:59:49"
  }, {
    id: 17,
    name: "Gruppen - schreiben",
    permission: "groups.write",
    info: null,
    created_at: "2017-04-14 18:08:21",
    updated_at: "2017-06-02 18:44:41"
  }, {
    id: 18,
    name: "Rechte - lesen",
    permission: "permissions.read",
    info: null,
    created_at: "2017-01-18 06:29:52",
    updated_at: "2016-08-27 01:58:41"
  }, {
    id: 19,
    name: "Rechte - schreiben",
    permission: "permissions.write",
    info: null,
    created_at: "2016-10-13 11:31:49",
    updated_at: "2016-11-09 11:27:39"
  }, {
    id: 20,
    name: "Filemanager - Album - lesen",
    permission: "filemanager.album.write",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }, {
    id: 21,
    name: "Filemanager - Album - schreiben",
    permission: "filemanager.album.write",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }, {
    id: 22,
    name: "Filemanager - Clippings",
    permission: "filemanager.clippings",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }, {
    id: 23,
    name: "Header - Sprache",
    permission: "header.changelanguage",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }, {
    id: 24,
    name: "Header - Splitview",
    permission: "header.splitview",
    info: null,
    created_at: "2016-09-21 20:20:40",
    updated_at: "2017-04-09 17:07:33"
  }];
});
define('bizbase3/components/g-map-route', ['exports', 'ember-g-map/components/g-map-route'], function (exports, _gMapRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gMapRoute.default;
    }
  });
});
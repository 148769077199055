define("bizbase3/services/detail-tab", ["exports", "bizbase3/utils/services/settings-handler"], function (exports, _settingsHandler) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      Evented = Ember.Evented,
      RSVP = Ember.RSVP;
  exports.default = _settingsHandler.default.extend(Evented, {
    storageKey: "BIZBASE3.currentDetailTab",

    _defaultConfig: computed(function () {
      return {};
    }),

    persist: function persist(childId) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this.set("config", { childId: childId });
        _this.save().then(resolve, reject);
      });
    },
    restore: function restore(information, route) {
      var childId = this.get("config.childId"),
          info = information.findBy("typeId", childId);

      if (info) {
        route.transitionTo("knb-entry.information", get(info, "id"));
      }
    },
    routeChange: function routeChange(route) {
      this.set("route", route);
      this.trigger("routeChange", route);
    },
    unpersist: function unpersist() {
      this.set("config", { childId: false });
      return this.save();
    }
  });
});
define("bizbase3/pods/press-request/controller", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_notable.default, {
    queryParams: ["category"],
    category: null,

    splitview: service(),
    init: function init() {
      this._super();
    },
    actions: {
      back: function back() {
        window.history.go(-1);
      },
      deleteRequest: function deleteRequest() {
        this.delete(this.get("model"));
      }
    },

    delete: function _delete(item) {
      var _this = this;

      item.destroyRecord().then(function () {
        _this.transitionToRoute("press-requests");
        _this.success("modules.press.notify.errorDelete");
      }, function () {
        _this.error("modules.filemanager.notify.errorDelete");
      });
    }
  });
});
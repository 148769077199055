define("bizbase3/components/link-li", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LinkLiComponent = Ember.Component.extend({
    tagName: "li",
    classNameBindings: ["active"],
    active: function () {
      return this.get("childViews").isAny("active");
    }.property("childViews.@each.active")
  });

  exports.default = LinkLiComponent;
});
define("bizbase3/router", ["exports", "bizbase3/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("login");
    this.route("lostpassword");

    this.route("protected", { path: "/" }, function () {
      this.route("dashboard", { path: "/dashboard", resetNamespace: true });
      this.route("classic", { path: "/classic", resetNamespace: true });
      this.route("glm", { path: "/glm", resetNamespace: true });

      this.route("asset-categories", { path: "/filemanager", resetNamespace: true }, function () {
        this.route("assets", { path: "/:category_id", resetNamespace: true }, function () {
          this.route("new");

          this.route("asset", { path: "/assets/:asset_id", resetNamespace: true }, function () {
            this.route("exif");
            this.route("links");
            this.route("shares");
          });
        });
      });

      this.route("knb-categories", { path: "/knowledgebase", resetNamespace: true }, function () {
        this.route("knb-entries", { path: "/:category_id", resetNamespace: true }, function () {
          this.route("new");

          this.route("knb-entry", { path: "/entries/:knb-entry_id", resetNamespace: true }, function () {
            this.route("information", { path: "/information/:custom-information_id" }, function () {
              this.route("versions");
            });

            this.route("assets", function () {
              this.route("new");
            });

            this.route("links");
          });
        });
      });

      this.route("contact-categories", { path: "/kontakte", resetNamespace: true }, function () {
        this.route("contacts", { path: "/:category_id", resetNamespace: true }, function () {
          this.route("new");

          this.route("contact", { path: "/contacts/:contact_id", resetNamespace: true }, function () {
            this.route("links");
          });
        });
      });

      this.route("press-request-categories", { path: "/presseanfragen", resetNamespace: true }, function () {
        this.route("press-requests", { path: "/:category_id", resetNamespace: true }, function () {
          this.route("new");

          this.route("press-request", { path: "/anfragen/:press-request_id", resetNamespace: true }, function () {
            this.route("external-contact");
            this.route("responsible-contact");
            this.route("contact");
            this.route("links");
            this.route("assets", function () {
              this.route("new");
            });
          });
        });
      });

      this.route("store-categories", { path: "/districts", resetNamespace: true }, function () {
        this.route("stores", { path: "/:category_id", resetNamespace: true }, function () {
          this.route("store", { path: "/stores/:store_id", resetNamespace: true }, function () {
            this.route("links");
            this.route("contact");
            this.route("assets", function () {
              this.route("new");
            });
          });
        });
      });

      this.route("search", { path: "/search", resetNamespace: true }, function () {});

      this.route("calendar-projects", { path: "/calendar", resetNamespace: true }, function () {});
      this.route("settings", { path: "/settings", resetNamespace: true }, function () {
        this.route("permissions");
        this.route("app");

        this.route("groups", { path: "/groups", resetNamespace: true }, function () {
          this.route("new");
          this.route("group", { path: "/:group_id", resetNamespace: true }, function () {
            this.route("index", { path: "" });
            this.route("permissions", { path: "/permissions" }); //temporary, ember needs a child route for `.index` to be created
          });
        });

        this.route("permissions", { path: "/permissions", resetNamespace: true }, function () {
          this.route("new");
          this.route("permission", { path: "/:permission_id", resetNamespace: true }, function () {
            this.route("index", { path: "" }); //temporary, ember needs a child route for `.index` to be created
          });
        });

        this.route("overview");
        this.route("search");

        this.route("users", { path: "/users", resetNamespace: true }, function () {
          this.route("new");
          this.route("user", { path: "/:user_id", resetNamespace: true }, function () {
            this.route("index", { path: "" }); //temporary, ember needs a child route for `.index` to be created
          });
        });
      });
    });
  });

  exports.default = Router;
});
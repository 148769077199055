define("bizbase3/helpers/add-or-remove-from", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addOrRemoveFrom = addOrRemoveFrom;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var assert = Ember.assert,
      isArray = Ember.isArray;
  function addOrRemoveFrom(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        selection = _ref2[0];

    assert("Param for add-or-remove-from helper must be an array", isArray(selection));

    return function (id, multiple) {
      if (!multiple) {
        selection.clear();
        selection.pushObject(id);
      } else {
        if (selection.includes(id)) {
          selection.removeObject(id);
        } else {
          selection.pushObject(id);
        }
      }
    };
  }

  exports.default = Ember.Helper.helper(addOrRemoveFrom);
});
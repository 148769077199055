define("bizbase3/pods/contact/index/controller", ["exports", "bizbase3/mixins/contact-controller"], function (exports, _contactController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_contactController.default, {
    actions: {
      deleteContact: function deleteContact() {
        var _this = this;

        var contact = this.get("model"),
            fullName = contact.get("fullName"),
            categoryId = contact.get("category.id");

        contact.destroyRecord().then(function () {
          _this.transitionToRoute("contacts", categoryId);
          _this.success("generic.notify.deleteSuccess", { name: fullName });
        }).catch(function () {
          contact.rollbackAttributes();
          _this.error("generic.notify.deleteFailed", { name: fullName });
        });
      }
    },

    afterSave: function afterSave() {
      this.get("contacts").updateEntries();
    }
  });
});
define("bizbase3/pods/components/category-crumbs/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["breadcrumb", "category-crumbs"],

    tagName: "ol"
  });
});
define("bizbase3/pods/components/select-box/component", ["exports", "bizbase3/mixins/dropdown-toggle"], function (exports, _dropdownToggle) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      debounce = Ember.run.debounce,
      htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend(_dropdownToggle.default, {
    actions: {
      select: function select(id) {
        this.set("_value", id);
        this.dropdownClose();
        this.set("selectFilter", "");
      },
      filter: function filter(query) {
        this.set("selectFilter", query);
        debounce(this, this.querySuggestions, query, 500);
      }
    },

    btnClassNames: ["btn-default"],

    classNames: ["select-box"],

    classNameBindings: ["withFilter:with-filter"],

    querySuggestions: function querySuggestions(query) {
      if (query.length > 0 && this.canQuery()) {
        this.set("options", this.get("dataProvider").query(query));
      }
    },
    canQuery: function canQuery() {
      return this.get("dataProvider") && !this.get("filteredOptions.length") && !this.get("isPending");
    },


    options: computed("dataProvider.selectBoxOptions", {
      get: function get() {
        return this.get("dataProvider.selectBoxOptions");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isPending: computed.reads("options.isPending"),

    filteredOptions: computed("options.@each.{label,value}", "selectFilter", function () {
      var _getProperties = this.getProperties("options", "selectFilter"),
          options = _getProperties.options,
          filter = _getProperties.selectFilter;

      if (isEmpty(filter)) {
        return options;
      }

      var regex = new RegExp("(" + filter + ")", "i");

      return options.filter(function (option) {
        return regex.test(get(option, "label"));
      }).map(function (option) {
        return {
          label: htmlSafe(get(option, "label").replace(regex, "<strong>$1</strong>")),
          value: get(option, "value")
        };
      });
    }),

    _prompt: computed("prompt", "selected.label", "options.firstObject.label", function () {
      var label = this.get("selected.label"),
          prompt = this.get("prompt");

      if (label) {
        return label;
      }

      if (prompt) {
        return prompt;
      }

      return this.get("options.firstObject.label");
    }),

    _value: computed("value", {
      get: function get() {
        return this.get("value");
      },
      set: function set(key, value) {
        var onSelect = this.get("onSelect");

        if (typeof onSelect === "function") {
          onSelect(value);
        } else {
          this.set("value", value);
        }

        return value;
      }
    }),

    selected: computed("_value", "options.@each.value", function () {
      return this.getWithDefault("options", []).findBy("value", this.get("_value"));
    }),

    keyUp: function keyUp(e) {
      if (this.get("open")) {
        e.stopPropagation();
      }
    },


    withFilter: true
  });
});
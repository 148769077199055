define("bizbase3/utils/computed/promise-array", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (modelName) {
    return computed(function () {
      return PromiseArray.create({
        promise: this.get("store").findAll(modelName)
      });
    });
  };

  var computed = Ember.computed;
  var PromiseArray = _emberData.default.PromiseArray;
});
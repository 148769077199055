define("bizbase3/helpers/size", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function size(value) {
    value = +value;

    if (isNaN(value)) {
      return "0 B";
    }

    var factor = 1024,
        units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        unit = 0;

    if (value > factor) {
      do {
        value /= factor;
      } while (++unit < units.length - 1 && Math.abs(value) >= factor + factor / 10);
    }

    value = value.toFixed(2).replace(/[,.]00$/, "");

    return value + " " + units[unit];
  }

  function helperSize(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];

    return size(value);
  }

  exports.size = size;
  exports.default = Ember.Helper.helper(helperSize);
});
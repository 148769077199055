define("bizbase3/models/calendar-appointment", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    title: attr("string"),
    start: attr("date"),
    end: attr("date"),
    status: attr("number"),
    description: attr("string"),
    project: (0, _relationships.belongsTo)("calendar-project"),
    user: (0, _relationships.belongsTo)(),
    tags: (0, _relationships.hasMany)()
  });
});
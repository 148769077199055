define("bizbase3/helpers/asset-names", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assetNames = assetNames;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get;
  function assetNames(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 2),
        assets = _ref2[0],
        _ref2$ = _ref2[1],
        num = _ref2$ === undefined ? 3 : _ref2$;

    assets = assets.map(function (asset) {
      return get(asset, "alias");
    });

    var first = assets.slice(0, num);

    var names = first[0];

    first.slice(1).forEach(function (name, index) {
      if (assets.length <= num && index === first.length - 2) {
        names += " und ";
      } else {
        names += ", ";
      }

      names += name;
    });

    if (assets.length > num) {
      names += " und " + (assets.length - num) + " weitere";
    }

    return names;
  }

  exports.default = Ember.Helper.helper(assetNames);
});
define("bizbase3/pods/components/permission-form/component", ["exports", "bizbase3/mixins/notable", "bizbase3/utils/dirty-form-observer"], function (exports, _notable, _dirtyFormObserver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    classNames: ["permission-form"],

    data: computed(function () {
      return {};
    }),

    checkDirtyness: (0, _dirtyFormObserver.default)("name"),

    submit: function submit(e) {
      var _this = this;

      e.preventDefault();

      var data = this.get("data"),
          onSave = this.get("onSave");

      onSave(data).then(function () {
        return _this.success("generic.notify.saveSuccess", { name: _this.get("permission.name") });
      }).catch(function () {
        return _this.error("generic.notify.saveFailed", { name: _this.get("permission.name") });
      });
    },
    onDelete: function onDelete() {},
    onDirt: function onDirt() {}
  });
});
define("bizbase3/pods/components/contact-form/component", ["exports", "bizbase3/mixins/notable", "bizbase3/utils/dirty-form-observer"], function (exports, _notable, _dirtyFormObserver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      service = Ember.inject.service,
      on = Ember.on,
      set = Ember.set;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    classNames: ["contact-form"],

    contactGroupOptions: computed("contactGroups.groups.@each.{id,title}", function () {
      return this.get("contactGroups.groups").map(function (group) {
        return {
          label: get(group, "title"),
          value: get(group, "id")
        };
      });
    }),

    contactGroups: service(),

    _loadGroups: on("didInsertElement", function () {
      this.get("contactGroups").retrieve();
    }),

    data: computed(function () {
      return {};
    }),

    checkDirtyness: (0, _dirtyFormObserver.default)("contact", "firstName", "lastName", "company", "street1", "street2", "postcode", "city", "country", "email", "phone", "mobile", "fax", "mediaType", "mediaTitle", "department", "notes"),

    actions: {
      save: function save(event) {
        if (event) {
          event.preventDefault();
        }
        var newGroupId = this.get("newGroupId"),
            data = this.get("data"),
            onSave = this.get("onSave");

        if (newGroupId) {
          set(data, "group", this.get("contactGroups._groups").findBy("id", newGroupId));
        }

        onSave(data);
      }
    },

    onDelete: function onDelete() {},
    onDirt: function onDirt() {}
  });
});
define("bizbase3/services/splitview", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.get("session.data.splitview");

      if (this.get("session.data.splitview") === undefined) {
        this.get("session").set("data.splitview", true);
      }
    },


    active: computed("session.data.splitview", function () {
      return this.get("session.data.splitview");
    }),
    session: service(),

    toggleActive: function toggleActive() {
      this.get("session").set("data.splitview", !this.get("active"));
    }
  });
});
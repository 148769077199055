define("bizbase3/components/pagination-footer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ["row"],

    tagName: "footer",

    totalArchived: 0,

    totalOnPage: computed("total", "perPage", function () {
      var _getProperties = this.getProperties("total", "perPage"),
          total = _getProperties.total,
          perPage = _getProperties.perPage;

      return total < perPage ? total : perPage;
    }),

    unarchived: computed("totalOnPage", "totalArchived", function () {
      return this.get("totalOnPage") - this.get("totalArchived");
    })
  });
});
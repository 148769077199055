define("bizbase3/pods/components/date-input/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      focusInput: function focusInput() {
        this.$("input").focus();
      }
    },

    classNames: ["input-group"],

    autoclose: true,

    clearBtn: true,

    format: "dd. MM yyyy",

    language: "de",

    weekStart: 1
  });
});
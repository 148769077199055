define("bizbase3/components/data-table-row", ["exports", "bizbase3/mixins/draggable-data", "bizbase3/mixins/has-context-menu", "bizbase3/mixins/selectable"], function (exports, _draggableData, _hasContextMenu, _selectable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      _get = Ember.get;
  exports.default = Ember.LinkComponent.extend(_selectable.default, _draggableData.default, _hasContextMenu.default, {
    tagName: "tr",

    router: Ember.inject.service(),

    classNames: ["data-table-row", "no-loader"],

    splitview: inject.service(),

    eventName: computed("detailEventName", {
      get: function get() {
        return _get(this, "detailEventName") || "click";
      }
    }),

    classNameBindings: ["selected", "model.validity", "model.archive:archived", "model.isSaving:pending"],

    makeDragIconTitle: function makeDragIconTitle() {
      return "generic.entries";
    },


    modelId: computed.reads("model.id")
  });
});
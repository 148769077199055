define("bizbase3/utils/dirty-form-observer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = dirtyFormObserver;
  var observer = Ember.observer;
  function dirtyFormObserver(modelKey) {
    for (var _len = arguments.length, keys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      keys[_key - 1] = arguments[_key];
    }

    return observer("data." + keys.join(","), function () {
      var _this = this;

      var keys = Object.keys(this.get("data")),
          wasDirty = this.get("isDirty");

      this.set("isDirty", keys.some(function (key) {
        return _this.get("data." + key) !== _this.get(modelKey + "." + key);
      }));

      if (this.get("isDirty") !== wasDirty) {
        this.get("onDirt")(this.get("isDirty"));
      }
    });
  }
});
define("bizbase3/initializers/frola-save-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var FroalaEditor = Ember.$.FroalaEditor;
  function initialize() {
    FroalaEditor.DefineIcon("save", { NAME: "floppy-o" });
    // custom buttons
    FroalaEditor.RegisterCommand("bbSaveButton", {
      title: "Speichern",
      icon: "save",
      undo: false,
      focus: false,
      showOnMobile: true,
      callback: function callback() {
        if (this.opts.bbSave) {
          this.opts.bbSave();
        }
      }
    });
  }

  exports.default = {
    name: "frola-save-button",
    initialize: initialize
  };
});
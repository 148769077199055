define("bizbase3/mixins/draggable-data", ["exports", "bizbase3/mixins/draggable"], function (exports, _draggable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty;
  exports.default = Ember.Mixin.create(_draggable.default, {
    i18n: service(),
    dragData: computed("selection", "model.id", function () {
      var selection = this.get("selection"),
          modelId = this.get("model.id");

      if (!selection.includes(modelId)) {
        selection = [modelId];
      }

      return {
        action: "moveToCategory",
        params: [selection]
      };
    }),

    makeDragIcon: function makeDragIcon() {
      var count = this.get("selection.length") || 1,
          title = this.get("model.title"),
          icon = "file-o";

      if (count !== 1 || isEmpty(title)) {
        title = this.get("i18n", this.makeDragIconTitle(), { count: count });
        if (count > 1) {
          icon = "files-o";
        }
      }

      return $("<span class=\"label label-default\"><i class=\"fa fa-" + icon + "\"></i> " + title + "</span>");
    }
  });
});
define("bizbase3/mixins/on-document-click", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      on = Ember.on,
      run = Ember.run;
  exports.default = Ember.Mixin.create({
    onDocumentClick: function onDocumentClick() {},
    handleDocumentClick: function handleDocumentClick(event) {
      var $element = this.$();
      var $target = $(event.target);

      if (!$target.closest($element).length) {
        this.onDocumentClick();
      }
    },


    enableDocumentClickListener: on("didInsertElement", function () {
      var listener = run.bind(this, this.handleDocumentClick);

      return $(document).on("click.document-click document-click", listener);
    }),

    disableDocumentClickListener: on("willDestroyElement", function () {
      return $(document).off("click.document-click document-click");
    })
  });
});
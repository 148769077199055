define("bizbase3/mixins/draggable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    attributeBindings: ["draggable"],

    draggable: true,

    dragStart: function dragStart(e) {
      e.dataTransfer.effectAllowed = "move";

      e.dataTransfer.setData("application/x-bizbase3+json", JSON.stringify(this.get("dragData")));

      var dragIcon = this.makeDragIcon();

      if (dragIcon && dragIcon.jquery && dragIcon[0]) {
        dragIcon.css({ position: "absolute", left: "-1000px" });
        $("body > .ember-view").append(dragIcon);

        e.dataTransfer.setDragImage(dragIcon[0], +dragIcon.outerWidth() / 2, dragIcon.outerHeight());

        this._dragIcon = dragIcon;
      }
    },
    dragEnd: function dragEnd() {
      if (this._dragIcon && typeof this._dragIcon.remove === "function") {
        this._dragIcon.remove();
      }
    },
    makeDragIcon: function makeDragIcon() {}
  });
});
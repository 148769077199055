define("bizbase3/pods/asset-categories/route", ["exports", "bizbase3/mixins/categories-route"], function (exports, _categoriesRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_categoriesRoute.default, {
    actions: {
      // TODO: uploadToCategory
    },

    categoryType: "filemanager",

    categorizedRouteName: "assets",

    ican: inject.service(),

    model: function model(params) {
      var model = this._super(params);

      return RSVP.hash({
        model: model,
        albums: this.get("store").findAll("album")
      });
    },
    setupController: function setupController(controller, _ref) {
      var model = _ref.model,
          albums = _ref.albums;

      model.albums = albums;
      this._super(controller, model);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);

      this.render("asset-crumbs", {
        into: "categories-page",
        outlet: "bread-crumbs"
      });

      if (this.get("ican").authorized("filemanager.album.read")) {
        this.render("assets/albums-panel", {
          into: "categories-page",
          outlet: "left-column"
        });
      }
    },
    getParent: function getParent() {
      return this.currentModel.model.findBy("isRoot", true);
    },
    update: function update() {
      this.currentModel.model.update();
    }
  });
});
define("bizbase3/mirage/factories/custom-information", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    content: function content() {
      var sentence = _emberCliMirage.faker.lorem.paragraphs(_emberCliMirage.faker.random.number({ min: 2, max: 5 }));
      return "<strong>" + sentence + "</strong>";
    },
    plaintext: function plaintext() {
      return _emberCliMirage.faker.lorem.paragraphs(_emberCliMirage.faker.random.number({ min: 2, max: 5 }));
    },


    versions: [],

    type: null
  });
});
define("bizbase3/pods/knb-entries/controller", ["exports", "bizbase3/mixins/data-table", "bizbase3/mixins/notable", "bizbase3/mixins/searchable", "bizbase3/mixins/sortable", "bizbase3/mixins/remote-pagination", "bizbase3/mixins/master-controller", "bizbase3/mixins/item-selection"], function (exports, _dataTable, _notable, _searchable, _sortable, _remotePagination, _masterController, _itemSelection) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      getProperties = Ember.getProperties,
      inject = Ember.inject,
      merge = Ember.merge,
      observer = Ember.observer,
      $ = Ember.$;
  exports.default = Ember.Controller.extend(_dataTable.default, _notable.default, _remotePagination.default, _searchable.default, _sortable.default, _masterController.default, _itemSelection.default, {
    actions: {
      saveNewEntry: function saveNewEntry() {
        var _this = this;

        var data = {
          title: this.get("newEntryTitle"),
          category: this.get("category")
        };

        this.store.createRecord("knb-entry", data).save().then(function (entry) {
          _this.set("newEntryTitle", "");

          _this.transitionToRoute("knb-entry", entry);
        });
      },


      i18n: inject.service(),

      archiveEntry: function archiveEntry(_ref) {
        var id = _ref.id;

        this.send("archiveSelected", [id]);
      },
      unarchiveEntry: function unarchiveEntry(_ref2) {
        var id = _ref2.id;

        this.send("unarchiveSelected", [id]);
      },
      deleteEntries: function deleteEntries() {
        var _this2 = this;

        var selection = this.get("selectedItems"),
            count = get(selection, "length");

        var question = this.get("i18n").t("modules.knb.questions.deleteSome", {
          count: count,
          name: get(selection, "firstObject.title")
        });
        this.get("modalDialog").confirm(question).then(function () {
          _this2.set("paginationArray.isPending", true);
          _this2.deleteSelected(selection).finally(function () {
            return _this2.set("paginationArray.isPending", false);
          });
        });
      },
      focusTitleInput: function focusTitleInput() {
        $("#createEntryModal input").focus();
      }
    },

    deleteErrorMessage: computed(function () {
      return this.get("i18n").t("modules.knb.notify.errorDelete");
    }),

    getPartlyDeleteMessage: function getPartlyDeleteMessage(count, countDeleted) {
      return this.get("i18n").t("modules.knb.notify.partlyFailedDeleted", {
        count: count - countDeleted
      });
    },
    getDeleteSuccessMessage: function getDeleteSuccessMessage(count) {
      return this.get("i18n").t("modules.knb.notify.deleted", { count: count });
    },


    category: computed.reads("model.category"),

    queryParams: [{ page: "entriesPage" }, "perPage", "showArchive"],

    columnsConfig: inject.service("knb-columns"),

    columns: computed.reads("columnsConfig.columns"),

    dataModelName: "knb-entry",

    paginationArray: computed.reads("model.entries"),

    collection: computed.reads("entries"),

    entries: computed("model.entries.@each.{archive,categoryId}", "hideArchived", "model.category.descendants.@each.id", "model.category.id", function () {
      var entries = this.get("model.entries");

      // this is needed to make sure the entry disappears when moved to a different category
      var categoryIds = [this.get("model.category")].concat(this.get("model.category.descendants")).mapBy("id");

      if (this.get("hideArchived")) {
        entries = entries.filterBy("archive", false);
      }

      return entries.filter(function (entry) {
        return categoryIds.includes(get(entry, "categoryId"));
      });
    }),

    modalDialog: inject.service(),

    currentRoute: "knb-entries",

    detailRoute: "knb-entry",

    hideArchived: computed.not("showArchive"),

    showArchive: false,

    customFields: computed(function () {
      return this.get("columns").map(function (column) {
        return merge(
        // remove nested key if present
        { key: get(column, "key").replace(/^([^.]+)\..*/, "$1"), checked: true }, getProperties(column, "label", "notSearchable", "searchPath"));
      }).concat([{ key: "tags", label: "Tags", checked: true }]);
    }),

    setCurrentEntry: observer("detailModel.id", function () {
      var _this3 = this;

      var currentId = this.get("detailModel.id"),
          selection = this.get("selection");

      if (selection.length !== 1 || !selection.includes(currentId)) {
        this.set("selection", [currentId]);
      }

      var queryPagePromise = this.get("model.entries").queryPageOfRecordId(currentId);

      if (queryPagePromise && typeof queryPagePromise.then === "function") {
        queryPagePromise.then(function (result) {
          _this3.set("page", get(result, "meta.page"));
        });
      }
    }),

    totalArchived: computed("model.entries.@each.archive", function () {
      return get(this.get("model.entries").filterBy("archive"), "length");
    })
  });
});
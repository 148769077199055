define("bizbase3/mirage/fixtures/files", ["exports", "bizbase3/mirage/factories/file"], function (exports, _file) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var factory = new _file.default();

  var files = [];

  for (var i = 1; i <= 50; i++) {
    var file = factory.build(i);

    file.id = i;

    files.push(file);
  }

  exports.default = files;
});
define("bizbase3/mixins/has-tooltip", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = Ember.Mixin.create({
    enableTooltips: on("didInsertElement", function () {
      this._tooltipElement = this.$("[title]").tooltip();
    }),

    disableTooltips: on("willDestroyElement", function () {
      if (this._tooltipElement) {
        this._tooltipElement.tooltip("destroy");
      }
    })
  });
});
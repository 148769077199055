define("bizbase3/services/stores-columns", ["exports", "moment", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service"], function (exports, _moment, _settingsHandler, _columnsService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed;


  var STORAGE_KEY = "BIZBASE3.stores.columnConfig";
  var MODULE_NAME = "stores";

  function formatDate(date) {
    return date ? (0, _moment.default)(date).format("LL") : "-";
  }

  var COLUMNS = [{ key: "id", linkable: true }, { key: "category.path", format: function format(path) {
      return path.join(" / ");
    } }, { key: "name", linkable: true }, { key: "tel", linkable: true }, { key: "street", linkable: true }, { key: "zip", linkable: true }, { key: "city", linkable: true }, { key: "franchise_company" }, { key: "opening", format: formatDate }];

  exports.COLUMNS = COLUMNS;
  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    storageKey: STORAGE_KEY,
    moduleName: MODULE_NAME,
    _columns: computed(function () {
      return COLUMNS;
    })
  });
});
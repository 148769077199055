define("bizbase3/services/calendar-projects", ["exports", "bizbase3/mixins/data-retrieval-service"], function (exports, _dataRetrievalService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Service.extend(_dataRetrievalService.default, {
    _dataKey: "_projects",

    _modelKey: "calendar-project",

    _projects: computed(function () {
      return [];
    }),

    projects: computed("_projects.@each.{id,title,color,appointments,calendarProjectType}", function () {
      return this.get("_projects").map(function (project) {
        return project;
      });
    }),

    projectOptions: computed("_projects.@each.{id,title,color,appointments}", function () {
      return this.getWithDefault("data", []).sortBy("title").map(function (project) {
        return { value: get(project, "id"), label: get(project, "title") };
      });
    }),

    selectBoxOptions: computed.reads("projectOptions")
  });
});
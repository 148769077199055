define("bizbase3/pods/protected/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: service(),

    model: function model() {
      return this.get("currentUser").load();
    },

    currentRouteName: computed("controllers.application.currentPath", function () {
      return this.get("controllers.application.currentPath");
    }),

    ican: inject.service(),

    checkPermission: function checkPermission(permission) {
      return this.get("ican").authorized(permission);
    },


    afterModel: function afterModel() {
      if (this.get("router.url") === "/") {
        if (this.checkPermission("dashboard.read")) {
          this.transitionTo("dashboard");
        } else if (this.checkPermission("knowledgebase.read")) {
          this.transitionTo("knb-categories");
        } else if (this.checkPermission("contacts.read")) {
          this.transitionTo("contact-categories");
        } else if (this.checkPermission("press.read")) {
          this.transitionTo("press-request-categories");
        } else if (this.checkPermission("stores.read")) {
          this.transitionTo("store-categories");
        } else if (this.checkPermission("filemanager.read")) {
          this.transitionTo("asset-categories");
        }
      }
    }
  });
});
define("bizbase3/pods/stores/route", ["exports", "bizbase3/mixins/categorized-route", "bizbase3/utils/pagination-array", "bizbase3/mixins/notable"], function (exports, _categorizedRoute, _paginationArray, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      service = Ember.inject.service,
      isArray = Ember.isArray,
      get = Ember.get;


  function archive(selection, model) {
    var isArchive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    var count = get(selection, "length");

    if (isArray(selection) && count > 0) {
      return Promise.all(selection.map(function (id) {
        var store = get(model, "stores").findBy("id", id);
        store.set("archive", isArchive);
        return store.save();
      }));
    } else {
      return Promise.resolve([]);
    }
  }

  exports.default = Ember.Route.extend(_categorizedRoute.default, _notable.default, {
    missingCategoryRoute: "stores",

    categoriesControllerName: "stores",

    storesColumns: service(),
    contacts: service(),

    actions: {
      informationSaved: function informationSaved() /* information */{
        this.refresh();
      },
      archiveSelected: function archiveSelected(selection) {
        var _this = this;

        archive(selection, this.currentModel).then(function (results) {
          return _this.success(results.length + " Eintr\xE4ge archiviert");
        }).catch(function () {
          return _this.error("Fehler beim Archivieren der Einträge");
        });
      },
      unarchiveSelected: function unarchiveSelected(selection) {
        var _this2 = this;

        archive(selection, this.currentModel, false).then(function (results) {
          return _this2.success(results.length + " Eintr\xE4ge de-archiviert");
        }).catch(function () {
          return _this2.error("Fehler beim De-Archivieren der Einträge");
        });
      },
      resetModel: function resetModel() {
        this.refresh();
      }
    },

    beforeModel: function beforeModel() {
      return hash({
        storeColumns: this.get("storesColumns").load()
      });
    },
    model: function model(params) {
      var storesQuery = {
        category_id: params.category_id,
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      var category = this.modelFor("store-categories").findBy("id", params.category_id);
      var contact = false;
      if (category) {
        contact = category.get("contact");
      }

      return hash({
        category: category,
        contact: contact,
        stores: (0, _paginationArray.makePaginationQuery)(this.get("store"), "store", storesQuery, params.page, params.perPage)
      });
    }
  });
});
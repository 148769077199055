define("bizbase3/components/modal-open-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ["dataToggle:data-toggle", "for:data-target", "title"],

    dataToggle: "modal",

    tagName: "a"
  });
});
define("bizbase3/pods/components/module-links/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      alias = Ember.computed.alias,
      guidFor = Ember.guidFor;
  exports.default = Ember.Component.extend({
    actions: {
      changeTab: function changeTab(change, tabId) {
        change(tabId);
        this.set("activeTabId", tabId);
      }
    },

    activeTabId: computed("model.links.firstObject.key", {
      get: function get() {
        return guidFor(this) + "-tab-" + this.get("model.links.firstObject.moduleName");
      },
      set: function set(_, value) {
        return value;
      }
    }),

    _guid: computed(function () {
      return guidFor(this);
    }),

    tagName: "",

    model: alias("for")
  });
});
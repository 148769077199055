define("bizbase3/pods/components/asset-add-to-album-form/component", ["exports", "bizbase3/mixins/modal-dialog", "bizbase3/utils/computed/promise-array", "bizbase3/utils/computed/select-options"], function (exports, _modalDialog, _promiseArray, _selectOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Component.extend(_modalDialog.default, {
    actions: {
      cancel: function cancel() {
        this.close();
      }
    },

    albumsProxy: (0, _promiseArray.default)("album"),

    albums: (0, _selectOptions.default)("albumsProxy", "id", "title"),

    store: inject.service(),

    submit: function submit(e) {
      e.preventDefault();

      var onSubmit = this.get("onSubmit");

      if (typeof onSubmit === "function") {
        this._submit(onSubmit);
      }

      this.close();
    },
    _submit: function _submit(onSubmit) {
      var assets = this.get("assets");

      if (this.get("isCreatingNewAlbum")) {
        onSubmit(assets, null, this.get("newAlbumName"));
      } else {
        onSubmit(assets, this.get("selectedAlbum"));
      }
    },
    close: function close() {
      this.set("isCreatingNewAlbum", false);
      this.hide();
    },


    tagName: "form"
  });
});
define("bizbase3/initializers/link-to-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.LinkComponent.reopen({
      attributeBindings: ["draggable"]
    });
  }

  exports.default = {
    name: "link-to-component",
    initialize: initialize
  };
});
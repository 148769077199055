define("bizbase3/utils/computed/select-options", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (collection, valueKey, labelKey) {
    return computed(collection + ".@each.{" + valueKey + "," + labelKey + "}", function () {
      return this.get(collection).map(function (item) {
        var _ref = [get(item, valueKey), get(item, labelKey)],
            value = _ref[0],
            label = _ref[1];

        return { value: value, label: label };
      }).sortBy("label");
    });
  };

  var get = Ember.get,
      computed = Ember.computed;
});
define("bizbase3/serializers/album", ["exports", "bizbase3/serializers/application", "ember-data"], function (exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      assets: { serialize: "ids" }
    }
  });
});
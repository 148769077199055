define("bizbase3/pods/store/assets/index/controller", ["exports", "bizbase3/mixins/assets-controller", "bizbase3/mixins/notable"], function (exports, _assetsController, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend(_assetsController.default, _notable.default, {
    collection: computed.reads("model.assets")
  });
});
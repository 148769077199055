define("bizbase3/adapters/knb-entry", ["exports", "bizbase3/adapters/knowledgebase"], function (exports, _knowledgebase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _knowledgebase.default.extend({
    pathForType: function pathForType() {
      return "entries";
    }
  });
});
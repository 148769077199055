define("bizbase3/models/calendar-project", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    title: attr("string"),
    color: attr("string"),
    appointments: (0, _relationships.hasMany)("calendar-appointment"),
    calendarProjectType: (0, _relationships.belongsTo)("calendar-project-type", { async: false })
  });
});
define("bizbase3/components/check-box", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isArray = Ember.isArray;
  exports.default = Ember.Component.extend({
    selection: [],

    checked: computed("selection", {
      get: function get() {
        var selection = this.get("selection");

        return isArray(selection) && selection.includes(this.get("model"));
      },
      set: function set(key, checked) {
        var model = this.get("model");

        var selection = this.get("selection");

        if (!isArray(selection)) {
          selection = [];
        }

        if (checked) {
          selection.pushObject(model);
        } else {
          selection.removeObject(model);
        }

        this.set("selection", selection);

        return checked;
      }
    })
  });
});
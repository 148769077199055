define("bizbase3/models/message", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = _emberData.default.Model.extend({
    text: attr("string"),
    date: attr("date"),

    pressRequest: belongsTo("press-request"),

    author: belongsTo("contact"),

    currentUser: service(),

    isMine: computed("author.id", "currentUser.user.id", function () {
      return this.get("author.id") === this.get("currentUser.user.contact.id");
    })
  });
});
define("bizbase3/mirage/factories/asset", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    alias: function alias() {
      var alias = _emberCliMirage.faker.lorem.words(2).join(" ");
      var extenstion = _emberCliMirage.faker.random.arrayElement([".pdf", ".jpg"]);
      return alias + extenstion;
    },
    title: function title() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    usage: function usage() {
      return _emberCliMirage.faker.random.number(2);
    },
    info: function info() {
      return _emberCliMirage.faker.lorem.sentence();
    },
    previewImage: function previewImage(i) {
      return "http://lorempixel.com/300/300/?i=" + i;
    },
    version: function version() {
      return 1;
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.past();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    expires_at: function expires_at() {
      var soon = new Date();
      soon.setTime(soon.getTime() + 5 * 24 * 3600000);

      return _emberCliMirage.faker.random.arrayElement([_emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.past(), _emberCliMirage.faker.date.past(), _emberCliMirage.faker.date.between(new Date(), soon), _emberCliMirage.faker.date.between(new Date(), soon)]);
    },

    category: null,
    album: null,
    creator: null,
    updater: null,
    files: [],
    tags: [],
    shares: []
  });
});
define("bizbase3/pods/components/thumb-nail/component", ["exports", "bizbase3/mixins/selectable", "bizbase3/mixins/draggable-data", "bizbase3/mixins/has-context-menu"], function (exports, _selectable, _draggableData, _hasContextMenu) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_selectable.default, _draggableData.default, _hasContextMenu.default, {
    classNames: ["thumbnail"],

    classNameBindings: ["validity", "selected:selected"],

    makeDragIconTitle: function makeDragIconTitle() {
      return "generic.files";
    },


    validity: computed("model.validity", function () {
      var validity = this.get("model.validity");

      if (!validity) {
        return "";
      }

      return "text-" + validity + " bg-" + validity;
    }),

    modelId: computed.reads("model.id")
  });
});
define("bizbase3/models/reminder", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    title: attr("string"),
    notice: attr("string"),
    color: attr("string"),
    done: attr("boolean", { defaultValue: false }),
    isNew: attr("boolean", { defaultValue: false }),
    doneAt: attr("date"),
    notification: attr("boolean", { defaultValue: false }),
    notificationAt: attr("date"),
    notificationBeforeMinutes: attr("number"),
    updatedAt: attr("date"),
    createdAt: attr("date"),
    owner: (0, _relationships.belongsTo)("contact", { inverse: null }),

    contact: (0, _relationships.belongsTo)("contact", { inverse: null }),
    pressRequest: (0, _relationships.belongsTo)("press-request", { inverse: null }),
    knbEntry: (0, _relationships.belongsTo)("knb-entry", { inverse: null }),
    asset: (0, _relationships.belongsTo)("asset", { inverse: null }),
    market: (0, _relationships.belongsTo)("store", { inverse: null }),
    details: computed("contact", "pressRequest", "asset", "knbEntry", function () {
      if (this.get("contact.id")) {
        return {
          icon: "address-book",
          route: "contact",
          first: this.get("contact.category.id"),
          second: this.get("contact.id")
        };
      } else if (this.get("pressRequest.id")) {
        return {
          icon: "comment",
          route: "press-request",
          first: this.get("pressRequest.category.id"),
          second: this.get("pressRequest.id")
        };
      } else if (this.get("knbEntry.id")) {
        return {
          icon: "book",
          route: "knb-entry",
          first: this.get("knbEntry.category.id"),
          second: this.get("knbEntry.id")
        };
      } else if (this.get("market.id")) {
        return {
          icon: "shopping-cart",
          route: "store",
          first: this.get("market.category.id"),
          second: this.get("market.id")
        };
      } else if (this.get("asset.id")) {
        return {
          icon: "folder-open",
          route: "asset",
          first: this.get("asset.category"),
          second: this.get("asset")
        };
      } else {
        return {
          icon: "bell",
          route: "contacts.contact",
          model: this.get("contact")
        };
      }
    })
  });
});
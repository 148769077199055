define("bizbase3/pods/components/option-box-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      toggleOption: function toggleOption() {
        if (typeof this.get("onToggle") === "function") {
          this.get("onToggle")();
        } else {
          this.toggleProperty("checked");
        }
      }
    },

    checked: false,

    classNames: ["option-box-item"],

    tagName: "li"
  });
});
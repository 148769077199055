define("bizbase3/models/store", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "bizbase3/utils/computed/fields", "bizbase3/services/stores-columns"], function (exports, _attr, _model, _relationships, _fields, _storesColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      alias = Ember.computed.alias,
      get = Ember.get,
      inject = Ember.inject;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    type_id: (0, _attr.default)("number"),
    district_id: (0, _attr.default)("number"),
    tel: (0, _attr.default)("string"),
    fax: (0, _attr.default)("string"),
    mail: (0, _attr.default)("string"),
    zip: (0, _attr.default)("string"),
    street: (0, _attr.default)("string"),
    city: (0, _attr.default)("string"),
    country: (0, _attr.default)("string"),
    manager: (0, _attr.default)("string"),
    franchise_company: (0, _attr.default)("string"),
    state: (0, _attr.default)("string"),
    opening: (0, _attr.default)("date"),
    notice: (0, _attr.default)("string"),
    archive: (0, _attr.default)("boolean", { defaultValue: false }),

    knbEntries: (0, _relationships.hasMany)(),
    assets: (0, _relationships.hasMany)("asset"),
    pressRequests: (0, _relationships.hasMany)(),

    category: (0, _relationships.belongsTo)(),

    columnsConfig: inject.service("stores-columns"),

    columns: alias("columnsConfig.columns"),

    fields: (0, _fields.default)(_storesColumns.COLUMNS.mapBy("key")),

    title: alias("name"),

    path: computed("category", "category.path", function () {
      if (this.get("category")) {
        return this.get("category.path").join(" / ");
      } else {
        return "";
      }
    }),

    pressRequries: Ember.computed("pressRequests.@each.type", function () {
      var entries = this.get("pressRequests");
      return entries.filterBy("type", "press");
    }),

    shootings: Ember.computed("pressRequests.@each.type", function () {
      var entries = this.get("pressRequests");
      return entries.filterBy("type", "shooting");
    }),

    links: computed("knbEntries", "pressRequries", "shootings", function () {
      var links = [];
      if (this.get("pressRequries").length) {
        links.push({
          model: this.get("pressRequries"),
          moduleName: "pressrequests",
          route: "press-request"
        });
      }

      if (this.get("shootings").length) {
        links.push({
          model: this.get("shootings"),
          moduleName: "pressshootings",
          route: "press-request"
        });
      }

      if (this.get("knbEntries").length) {
        links.push({ model: this.get("knbEntries"), moduleName: "knb", route: "knb-entry" });
      }
      return links;
    }),

    hasLinks: computed("links.@each.model.length", function () {
      return !!this.get("links").some(function (link) {
        return get(link, "model.length");
      });
    })
  });
});
define("bizbase3/mixins/readonly-settings-handler", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = Ember.Mixin.create({
    save: function save() {
      Ember.Logger.error("Attempting to save read only setting");
    },


    loadConfig: on("init", function () {
      this.load();
    })
  });
});
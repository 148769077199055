define("bizbase3/mixins/sortable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: exporting of consts or lets is broken in jshint-2.8.0
  // see: https://github.com/jshint/jshint/issues/2430
  var ASC = "asc";

  var DESC = "desc";

  function toggleDir(dir) {
    if (dir === ASC) {
      return DESC;
    } else {
      return ASC;
    }
  }

  exports.ASC = ASC;
  exports.DESC = DESC;
  exports.default = Ember.Mixin.create({
    actions: {
      sortBy: function sortBy(sortKey) {
        var currentDir = this.get("sortDir"),
            sortDir = ASC;

        if (currentDir) {
          sortDir = toggleDir(currentDir);
        }

        this.setProperties({ sortKey: sortKey, sortDir: sortDir });
      }
    },

    queryParams: [{ sortKey: "sortBy" }, "sortDir"],

    sortKey: null,

    sortDir: null
  });
});
define("bizbase3/services/filemanager-columns", ["exports", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service", "moment"], function (exports, _settingsHandler, _columnsService, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed,
      inject = Ember.inject;


  var STORAGE_KEY = "BIZBASE3.filemanager.columnConfig";
  var MODULE_NAME = "filemanager";

  var COLUMNS = [{ key: "id" }, { key: "alias" }, { key: "title" }, { key: "info" }, { key: "publication" }, { key: "appearanceType" }, { key: "appearance" }, { key: "rating" }, { key: "reach" }, { key: "presscount" }, { key: "costs" }, { key: "type" }, { key: "category.path", format: function format(path) {
      return path.join(" / ");
    } }, { key: "size", type: "size" }, {
    key: "expires_at",
    format: function format(date) {
      return date ? (0, _moment.default)(date).format("LL") : "-";
    }
  }, {
    key: "created_at",
    format: function format(date) {
      return date ? (0, _moment.default)(date).format("LL") : "-";
    }
  }];

  exports.COLUMNS = COLUMNS;
  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    assetUsageLabels: inject.service(),
    storageKey: STORAGE_KEY,
    moduleName: MODULE_NAME,
    _columns: computed("assetUsageLabels.config", function () {
      var config = this.get("assetUsageLabels.config");
      return COLUMNS.concat({
        key: "usage",
        format: function format(usage) {
          return config[usage];
        }
      });
    })
  });
});
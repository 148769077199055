define("bizbase3/mixins/detail-tab-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    actions: {
      didTransition: function didTransition() {
        this.get("detailTab").persist(this.get("currentModel.type.id"));
      }
    },

    detailTab: inject.service()
  });
});
define("bizbase3/locales/de/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "language-select": {
      de: "Deutsch - Standard",
      de_real: "Deutsch - real"
    },
    appname: "bizbase cloud",
    task: {
      create: "Create new task",
      fail: "Delete task",
      open: {
        zero: "Nothing to do",
        one: "One open task",
        other: "You have %@ open tasks"
      }
    },
    generic: {
      done: "Erledigt!",
      error: "Fehler!",
      fillIn: "Bitte ausfüllen",
      choose: "Bitte auswählen",
      login: "Anmelden",
      logout: "Abmelden",
      delete: "Löschen",
      cancel: "Abbrechen",
      createdBy: "Erstellt von",
      expireDate: "Ablaufdatum",
      info: "Info",
      url: "Adresse",
      save: "Speichern",
      share: "Freigeben",
      download: "Download",
      downloadIntern: "Download interner Datein gesperrt!",
      user: "Benutzer",
      edit: "Bearbeiten",
      editClose: "Bearbeitung schließen",
      editCancel: "Bearbeitung verwerfen",
      history: "Alte Versionen",
      rename: "Umbenennen",
      name: "Name",
      notes: "Notizen",
      state: "Status",
      loading: "Laden...",
      files: {
        one: "Eine Datei",
        other: "{{count}} Dateien"
      },
      entries: {
        one: "Ein Eintrag",
        other: "{{count}} Einträge"
      },
      resetFilter: "Filter zurücksetzen",
      categories: {
        create: "Kategorie erstellen",
        rename: "Kategorie umbenennen",
        name: "Name der Kategorie",
        questions: {
          delete: "Soll die Kategorie <strong>{{name}}</strong> wirklich gelöscht werden?"
        }
      },
      search: {
        lable: "Suchen:"
      },
      selectToggle: {
        all: "Alle auswählen",
        nothing: "Nichts auswählen",
        swap: "Auswahl umkehren"
      },
      tags: {
        title: "Schlagworte",
        placeholder: "Suche Schlagworte",
        search: "Suche nach Schlagworte mit <strong>„{{tag}}“</strong>",
        new: "<strong>„{{tag}}“</strong> neu erstellen",
        replace: "Vorhandene Schlagworte überschreiben",
        notify: {
          saveSuccess: "Tag „{{tag}}“ hinzugefügt",
          saveFailed: "Fehler beim Hinzufügen von „{{tag}}“",
          deleteSuccess: "Tag „{{tag}}“ entfernt",
          deleteFailed: "Fehler beim Entfernen von „{{tag}}“"
        }
      },
      copyToClipboard: "In die Zwischenablage kopieren",
      notify: {
        clipboard: "kopiert",
        saveSuccess: "{{name}} erfolgreich gespeichert",
        saveFailed: "Fehler beim Speichern von {{name}}",
        deleteSuccess: "{{name}} erfolgreich gespeichert",
        deleteFailed: "Fehler beim Speichern von {{name}}",
        movedItemToSuccess: "{{item}} nach {{to}} verschoben",
        movedItemToFailed: "Konnte {{item}} nicht nach {{to}} schieben",
        movedFailed: "Fehler beim verschieben"
      },
      questions: {
        delete: "Wollen Sie {{name}} wirklich löschen?"
      },
      dataTables: {
        showPre: "Zeige",
        showAfter: "Einträge pro Seite",
        showColumns: "Spalten einblenden"
      }
    },
    login: {
      greeting: "Guten Tag. Melden Sie sich bitte an.",
      failed: "Login fehlgeschlagen",
      ldap: "WICHTIG: LDAP-Login ist zwingend erfoderlich.",
      progress: "Anmeldung...",
      login: "Anmelden",
      username: "Benutzername",
      password: "Passwort",
      changePassword: {
        link: "Passwort ändern"
      },
      lostpassword: {
        link: "Passwort vergessen?",
        provideMail: "Bitte geben Sie ihre E-Mail-Adresse an:",
        done: "Ihr Passwort wurde zurückgesetzt und versendet. Sie werden nun zum Login weitergeleitet.",
        error: "Es ist ein Fehler aufgetreten oder der Benutzer ist uns nicht bekannt.",
        button: "Passwort zurücksetzen",
        backToLogin: "Zurück zum Login"
      }
    },
    modules: {
      knb: {
        name: "Wissensdatenbank",
        toolbar: {
          archive: "Archivieren",
          unarchive: "De-Archivieren",
          showArchive: "Archivierte Einträge anzeigen",
          hideArchive: "Archivierte Einträge ausblenden",
          archiveSelected: "Markierte Einträge archivieren",
          deleteSelected: "Markierte Einträge löschen"
        },
        columns: {
          name: "Titel",
          created: "Erstellt",
          id: "ID",
          title: "Titel",
          "category.path": "Kategorie",
          views: "Ansichten",
          validFrom: "Gültig von",
          validTill: "Gültig bis",
          "user.fullName": "Autor",
          info: "Info",
          created_at: "Erstellt"
        },
        context: {
          addAlbum: "Zu einem Album hinzufügen",
          delete: {
            one: "Eintrag löschen",
            other: "{{count}} Einträge löschen"
          }
        },
        notify: {
          saveSuccess: "Eintrag gespeichert",
          saveFailed: "Fehler beim Speichern des Eintrags",
          deleteFailed: "Fehler beim Löschen der Einträge",
          noEntries: "Noch keine Einträge vorhanden.",
          archive: "Dieser Artikel wurde archiviert",
          noEntriesFirst: "Machen Sie den Ersten...",
          searchToLarge: "Es sind zu viele Einträge vorhanden. Bitte spezifizieren Sie Ihre Suche oder wählen Sie eine Unterkategorie aus.",
          errorDelete: "Fehler beim Löschen der Einträge",
          deleted: {
            one: "Der Artikel wurde gelöscht",
            other: "Es wurden {{count}} Artikel gelöscht"
          },
          partlyFailedDeleted: {
            one: "Der Artikel konnte nicht gelöscht werden",
            other: "Es konnten {{count}} Artikel nicht gelöscht werden"
          }
        },
        questions: {
          delete: 'Soll der Artikel "{{title}}" wirklich gelöscht werden?',
          deleteSome: {
            one: 'Soll der Artikel "{{name}}" wirklich gelöscht werden?',
            other: "Sollen {{count}} Einträge wirklich gelöscht werden?"
          }
        },
        modals: {
          new: {
            title: "Neuen Eintrag anlegen",
            placeholder: "Title des Eintrags"
          }
        }
      },
      stores: {
        name: "Märkte",
        form: {
          address: "Name und Adresse",
          contact: "Kontakt",
          saveContact: "Kontakt speichern"
        },
        toolbar: {
          editContact: "zu Kontakt wechseln",
          chooseContact: "Kontakt zuweisen"
        },
        district: {
          title: "Bezirks - / Regionalleiter",
          none: "Für diesen Bezirk gibt es noch keinen Ansprechpartner. Fügen Sie einen hinzu."
        },
        notify: {
          archive: "Dieser Markt wurde archiviert"
        },
        columns: {
          id: "ID",
          district_id: "Bezirk",
          "category.path": "Kategorie",
          category: {
            path: "Kategorie"
          },
          name: "Name",
          tel: "Telefon",
          email: "E-Mail",
          fax: "Fax",
          street: "Straße",
          country: "Land",
          zip: "Postleitzahl",
          city: "Stadt",
          manager: "Manager",
          franchise_company: "Company",
          opening: "Eröffnungstag"
        }
      },
      filemanager: {
        name: "Dateimanager",
        tabname: "Dateien",
        tabs: {
          info: {
            title: "Info",
            name: "Name",
            type: "Dateityp",
            rating: "Bewertung",
            info: "Info",
            createdBy: "Erstellt von",
            expireDate: "Ablaufdatum",
            usage: "Verwendungszweck",
            appearance: "Erscheinungsweise",
            presscount: "Auflage",
            reach: "Reichweite",
            appearanceType: "Medium",
            publication: "Publikation",
            costs: "kumulierter Anzeigenpreis",
            content: "Inhalt",
            unlimitedUsage: "Unbegrenzte Nutzungsrechte",
            tags: "Schlagworte",
            notify: {
              saveExpireDate: "Beim Speichern wird das Ablaufdatum von allen Dateien entfernt.",
              saveError: "Fehler beim Speichern der Datei",
              saveSuccess: "Datei gespeichert",
              deleteSuccess: "Datei wurde erfolgreich gelöscht",
              deleteFailure: "Löschen fehlgeschlagen"
            }
          },
          exif: {
            title: "Exif-Daten"
          },
          share: {
            title: "Freigaben",
            notify: {
              saved: "Freigabe gespeichert"
            }
          }
        },
        categories: {
          notify: {
            expireSaved: 'Ablaufdatum für "{{title}}" auf {{date}} festgelegt.',
            expireSavedFail: 'Fehler beim Speichern des Ablaufdatums für "{{title}}"',
            deleteFailure: 'Fehler beim Löschen des Albums "{{title}}".',
            deleteSuccess: 'Das Album "{{title}}" wurde erfolgreich gelöscht.',
            moveSuccess: {
              one: '"{{name}}" nach {{category}} verschoben',
              other: "{{count}} Dateien nach {{category}} verschoben"
            },
            moveFailure: 'Das Album "{{title}}" wurde erfolgreich gelöscht.'
          }
        },
        questions: {
          delete: "Soll die Datei <strong>{{alias}}</strong> wirklich gelöscht werden?",
          deleteFromKnb: {
            one: 'Soll die Datei "{{alias}}" wirklich vom Artikel entfernt werden?',
            other: "Sollen {{count}} Dateien wirklich vom Artikel entfernt werden?"
          },
          deleteFromPressrequest: {
            one: 'Soll die Datei "{{alias}}" wirklich von der Anfrage entfernt werden?',
            other: "Sollen {{count}} Dateien wirklich von der Anfrage entfernt werden?"
          },
          deleteFromStore: {
            one: 'Soll die Datei "{{alias}}" wirklich vom Store entfernt werden?',
            other: "Sollen {{count}} Dateien wirklich von Store entfernt werden?"
          }
        },
        toolbar: {
          new: "Neue Datei hochladen",
          deleteFromAlbum: "Aus dem Album entfernen",
          add: "Datei(en) hinzufügen",
          table: "Tabelle anzeigen",
          thumbs: "Bilder anzeigen",
          imagesize: "Bildgröße",
          quality: "Qualität"
        },
        alerts: {
          expired: "Dieses Album ist {{expireText}} am {{expireDate}} abgelaufen.",
          expires: "Dieses Album läuft in {{expireText}} am {{expireDate}} ab.",
          share: "Die Freigabe wird unter folgender URL erstellt:",
          shared: "Die Freigabe wurde unter folgender URL erstellt:",
          nofiles: "Noch keine Dateien vorhanden.",
          uploadSome: "Laden sie welche hoch..."
        },
        notify: {
          linkAssets: {
            success: "Dateien erfolgreich verknüpft",
            error: "Dateien erfolgreich verknüpft"
          },

          batchError: "Fehler beim Bearbeiten der Dateien",
          batchSuccess: {
            one: "Die Datei wurde bearbeitet",
            other: "Es wurden {{count}} Dateien bearbeit"
          },
          errorDelete: "Fehler beim Löschen der Dateien",
          addedToAlbum: {
            one: "Die Datei wurdem zum Album hinzugefügt",
            other: "{{count}} wurden zum Album hinzugefügt"
          },
          deleted: {
            one: "Die Datei wurde gelöscht",
            other: "Es wurden {{count}} Dateien gelöscht"
          },
          partlyFailedDeleted: {
            one: "Die Datei konnte nicht gelöscht werden",
            other: "Es konnten {{count}} Dateien nicht gelöscht werden"
          }
        },
        context: {
          addAlbum: "Zu einem Album hinzufügen",
          importClippings: "Clippings importieren",
          delete: {
            one: "Datei löschen",
            other: "{{count}} Dateien löschen"
          },
          deleteFromAlbum: {
            one: "Datei aus Album löschen",
            other: "{{count}} Dateien löschen löschen aus Album löschen"
          }
        },
        modals: {
          linkAssets: {
            name: "Dateien verknüpfen",
            placeholder: "IDs mit Komma (32, 234, 4)"
          },
          pdf: {
            title: "PDF: ",
            close: "PDF schliessen",
            download: "PDF herunterladen"
          },
          quality: {
            title: "Qualität",
            high: {
              title: "Kleinste Qualität",
              info: "Optimal für den Versand in Emails"
            },
            mid: {
              title: "Mittlerer Qualität",
              info: "Optimal für Einbettung in Präsenationen (720 Pixel)"
            },
            hight: {
              title: "Hohe Qualität (Full-HD)",
              info: "Optimal für Präsentationen im Vollbild"
            },
            original: {
              title: "Orginal Bild",
              info: "Maximale Qualität"
            }
          },
          album: {
            addHeader: "Zu einem Album hinzufügen",
            createNew: "Neues Album erstellen",
            name: "Albumname",
            choose: "Album wählen"
          }
        },
        columns: {
          id: "ID",
          alias: "Name",
          title: "Titel",
          info: "Info",
          "category.path": "Kategorie",
          category: {
            path: "Kategorie"
          },
          appearance: "Erscheinungsweise",
          appearanceType: "Medium",
          presscount: "Auflage",
          rating: "Bewertung",
          type: "Typ",
          reach: "Reichweite",
          publication: "Publikation",
          costs: "kumulierter Anzeigenpreis",
          size: "Größe",
          expires_at: "Ablaufdatum",
          usage: "Verwendungszweck",
          created_at: "Erstellt"
        }
      },
      pressrequests: {
        modulename: "Anfragen",
        name: "Presseanfragen",
        shooting: "Drehanfragen",
        conversation: {
          changeSender: "Absender ändern"
        },
        tabs: {
          assets: {
            name: "Dateien"
          },
          details: {
            name: "Details"
          }
        },
        form: {
          contact: "Medienkontakt",
          requestMessage: "Anfragetext",
          chooseContact: "Kontakt",
          addContact: "Kontakt hinzufügen",
          chooseSender: "Absender wählen",
          anwerAs: "Als {{name}} antworten",
          tags: "Tags",
          personCount: "Anzahl Personen",
          duration: "Dauer",
          team: "Team",
          type: "Typ",
          copyText: "Kopieren Sie hier ihre Anfrage herein",
          messageFrom: "Fügen Sie eine Nachricht von {{name}} hinzu..."
        },
        columns: {
          contact: "Medienkontakt",
          "contact.fullName": "Presseverantwortlicher",
          "externalContact.fullName": "Medienkontakt",
          "responsibleContact.fullName": "Recherche Verantwortlicher intern",
          "category.path": "Kategorie",
          receiptDate: "Eingegangen",
          incomingDate: "Eingegangen",
          doneAt: "Erledigt am",
          state: "Status",
          tags: "Tags",
          personCount: "Anzahl Personen",
          duration: "Dauer",
          team: "Team",
          publicationDate: "Veröffentlicht",
          respondUntil: "Zu beantworten bis"
        },
        toolbar: {
          editContact: "Kontakt bearbeiten",
          delete: "Anfrage löschen",
          backToList: "Zur Übersicht",
          sender: "Absender",
          deleteSome: {
            one: "Anfrage löschen",
            other: "{{count}} Anfragen löschen"
          }
        },
        notify: {
          saveSuccess: "Anfrage erfolgreich gespeichert",
          saveFailed: "Fehler beim Speichern der Anfrage",
          deleted: {
            one: "Die Datei wurde gelöscht",
            other: "Es wurden {{count}} Dateien gelöscht"
          },
          deletedFailed: {
            one: "Die Datei wurde gelöscht",
            other: "Es wurden {{count}} Dateien gelöscht"
          }
        },
        questions: {
          delete: 'Soll der Artikel "{{title}}" wirklich gelöscht werden?',
          deleteSome: {
            one: 'Soll der Artikel "{{name}}" wirklich gelöscht werden?',
            other: "Sollen {{count}} Einträge wirklich gelöscht werden?"
          }
        }
      },
      pressshootings: {
        name: "Drehanfragen"
      },
      dashboard: {
        name: "Dashboard",
        portlets: {
          knb: {
            title: "Letzten Wissensdatenbank Einträge",
            show: "Zeige alle Einträge an"
          },
          contacts: {
            title: "Letzten neuen Kontakte"
          },
          pressrequests: {
            title: "Letzte Presseanfragen",
            shooting: "Letzte Drehanfragen",
            show: "Zeige alle Einträge an",
            create: "Presseanfrage erstellen"
          }
        },
        goto: "Gehe zu"
      },
      calendar: {
        name: "Kalender",
        buttons: {
          new: "Neuer Eintrag",
          monthview: "Monatskalender"
        },
        notify: {
          noEvents: "Es gibt keinen Eintrag"
        },
        sidebar: {
          users: {
            title: "Benutzer"
          }
        },
        forms: {
          entry: {
            title: "Titel",
            details: "Details",
            project: "Project",
            start: "Start",
            end: "Ende"
          }
        },
        modals: {
          entry: {
            headline: "Kalendereintrag"
          }
        },
        questions: {
          delete: "Wollen Sie den Termin wirklich löschen?"
        }
      },
      contacts: {
        name: "Kontakte",
        tabs: {
          details: {
            name: "Details"
          }
        },
        questions: {
          deleteSome: {
            one: "Wollen Sie den Kontakt {{name}} wirklich löschen?",
            other: "Wollen Sie wirklich {{count}} Kontakte löschen?"
          }
        },
        toolbar: {
          new: "Kontakt erstellen",
          delete: {
            one: "Kontakt löschen",
            other: "{{count}} Kontakte löschen"
          }
        },
        context: {
          delete: {
            one: "Kontakt löschen",
            other: "{{count}} Kontakte löschen"
          }
        },
        notify: {
          movedSuccess: {
            one: "Kontakt {{name}} nach {{to}} verschoben",
            other: "{{item}} nach {{to}} verschoben"
          }
        },
        columns: {
          ID: "Id",
          fullName: "Name",
          email: "E-Mail",
          firstName: "Vorname",
          lastName: "Name",
          company: "Firma",
          mediaTitle: "Medientitel",
          mediaType: "Medienform",
          department: "Redaktion",
          phone: "Tel",
          fax: "Fax",
          mobile: "Mobil",
          street1: "Straße",
          postcode: "Postleitzahl",
          country: "Land",
          createdAt: "Erstellt"
        }
      },
      settings: {
        name: "Einstellungen",
        sub: {
          overview: {
            name: "Übersicht"
          },
          permissions: {
            name: "Rechte",
            detailsTab: "Details",
            new: "Neues Recht hinzufügen",
            reset: "Rechte zurücksetzen",
            columns: {
              id: "ID",
              name: "Name",
              permission: "Recht",
              info: "Info"
            }
          },
          groups: {
            name: "Gruppen",
            detailsTab: "Details",
            new: "Neue Gruppe hinzufügens",
            reset: "Rechte zurücksetzen",
            technical: "technisches Recht / intern",
            previewPermissions: "Vorschau der Rechte",
            notify: {
              resetPermissionsSuccess: "Rechte wurden erfolgreich zurückgesetzt",
              resetPermissionsFailed: "Rechte konnten nicht zurückgesetzt werden",
              saveSuccess: "Speichern der Rechte erfolgreich",
              saveFailed: "Speichern der Rechte fehlgeschlagen"
            },
            columns: {
              id: "ID",
              name: "Name",
              info: "Info"
            }
          },
          users: {
            columns: {
              firstName: "Vorname",
              lastName: "Nachname",
              email: "E-Mail",
              "group.name": "Gruppe",
              lastLogin: "Letztes Login"
            },
            name: "Benutzer",
            detailsTab: "Details",
            new: "Neuen Benutzer hinzufügen",
            resetPassword: "Passwort zurücksetzen",
            userdata: "Benutzerdaten",
            sendWelcome: "Willkommensmail versenden",
            ldapuser: "LDAP-User - Rücksetzen des Passwortes nicht möglich."
          },
          search: {
            name: "Suche",
            deleteIndex: "Index löschen",
            indexHeadline: "Indizieren",
            index: "indizieren"
          }
        }
      },
      search: {
        name: "Suche",
        itemsearch: {
          empty: "Bitte geben Sie einen Suchbegriff ein",
          noresult: "Es wurde kein Eintrag mit '{{query}}' gefunden"
        }
      },
      reminder: {
        modal: {
          title: "Erinnerung bearbeiten",
          labels: {
            until: "Bis",
            reminder: "Erinnern",
            alarm: "<strong>Wecker:</strong> {{minutes}} Minuten vorher"
          },
          minute: {
            zero: "Erinnerung am Datum",
            one: "{{count}} Minute vorher",
            other: "{{count}} Minuten vorher"
          }
        },
        questions: {
          delete: "Soll die Erinnerung <strong>{{title}}</strong> wirklich gelöscht werden?"
        },
        notify: {
          errorDelete: "Fehler beim Löschen der Erinnerung",
          deleted: "Die Erinnerung wurde gelöscht",
          saved: "Die Erinnerung wurde gespeichert",
          errorSaved: "Die Erinnerung konnte nichts gespeichert werden",
          empty: "Aktuell gibt es keine Erinnerungen"
        }
      },
      relations: {
        name: "Verknüpfungen"
      }
    }
  };
});
define("bizbase3/services/password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      Promise = Ember.RSVP.Promise;
  exports.default = Ember.Service.extend({
    reset: function reset(email) {
      return new Promise(function (resolve, reject) {
        $.ajax({
          type: "POST",
          url: "/api/v3/sessions/password",
          data: JSON.stringify({ email: email }),
          contentType: "application/json"
        }).then(function (ret) {
          resolve(ret);
        }).fail(reject);
      });
    }
  });
});
define("bizbase3/services/tags", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      inject = Ember.inject;
  exports.default = Ember.Service.extend(_notable.default, {
    createTag: function createTag(tag) {
      return this.get("store").createRecord("tag", { tag: tag }).save();
    },
    search: function search(searchQuery) {
      return this.get("store").query("tag", { searchQuery: searchQuery });
    },
    assignTag: function assignTag(model, tag) {
      var _this = this;

      get(model, "tags").addObject(tag);

      var promise = model.save();

      promise.then(function () {
        return _this.success("generic.tags.notify.saveSuccess", tag);
      }).catch(function () {
        return _this.error("generic.tags.notify.saveFailed", tag);
      });

      return promise;
    },
    removeTag: function removeTag(model, tag) {
      var _this2 = this;

      model.get("tags").removeObject(tag);

      var promise = model.save();

      promise.then(function () {
        return _this2.success("generic.tags.notify.deleteSuccess", tag);
      }).catch(function () {
        return _this2.error("generic.tags.notify.deleteFailed", tag);
      });

      return promise;
    },


    store: inject.service()
  });
});
define("bizbase3/mirage/fixtures/assets", ["exports", "bizbase3/mirage/factories/asset", "bizbase3/mirage/fixtures/files", "bizbase3/mirage/fixtures/categories"], function (exports, _asset, _files, _categories) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var factory = new _asset.default({ category: _categories.default[0].id });

  var i = 0;

  exports.default = _files.default.map(function (file) {
    var asset = factory.build(++i);

    asset.id = i;
    asset.category = _categories.default[0].id;
    asset.files = [file.id];

    return asset;
  });
});
define("bizbase3/services/contacts", ["exports", "bizbase3/mixins/data-retrieval-service"], function (exports, _dataRetrievalService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Service.extend(_dataRetrievalService.default, {
    _modelKey: "contact",

    contacts: computed("data.@each.{id,fullName}", function () {
      return this.getWithDefault("data", []).sortBy("lastName", "firstName").map(function (contact) {
        return contact;
      });
    }),

    contactsOptions: computed("data.@each.{id,fullName}", function () {
      return this.getWithDefault("data", []).sortBy("lastName", "firstName").map(function (contact) {
        return { value: get(contact, "id"), label: get(contact, "fullName") };
      });
    }),

    selectBoxOptions: computed.reads("contactsOptions")
  });
});
define("bizbase3/mixins/master-controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on,
      service = Ember.inject.service;
  exports.default = Ember.Mixin.create({
    splitview: service(),

    hideDetails: function hideDetails() {
      this.transitionToRoute(this.get("currentRoute"));
    },


    _leaveDetailRoute: on("recordsDeleted", function () {
      var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (ids.includes(this.get("detailModel.id"))) {
        this.hideDetails();
      }
    })
  });
});
define("bizbase3/pods/knb-entries/route", ["exports", "bizbase3/mixins/categorized-route", "bizbase3/mixins/notable", "bizbase3/utils/pagination-array"], function (exports, _categorizedRoute, _notable, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var get = Ember.get;

  var hash = Ember.RSVP.hash;

  var inject = Ember.inject;

  var isArray = Ember.isArray;

  var Promise = Ember.RSVP.Promise;

  function archive(selection, model) {
    var isArchive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    var count = get(selection, "length");

    if (isArray(selection) && count > 0) {
      return Promise.all(selection.map(function (id) {
        var entry = get(model, "entries").findBy("id", id);
        entry.set("archive", isArchive);
        return entry.save();
      }));
    } else {
      return Promise.resolve([]);
    }
  }

  exports.default = Ember.Route.extend(_categorizedRoute.default, _notable.default, {
    actions: {
      informationSaved: function informationSaved() /* information */{
        this.refresh();
      },
      archiveSelected: function archiveSelected(selection) {
        var _this = this;

        archive(selection, this.currentModel).then(function (results) {
          return _this.success(results.length + " Eintr\xE4ge archiviert");
        }).catch(function () {
          return _this.error("Fehler beim Archivieren der Einträge");
        });
      },
      unarchiveSelected: function unarchiveSelected(selection) {
        var _this2 = this;

        archive(selection, this.currentModel, false).then(function (results) {
          return _this2.success(results.length + " Eintr\xE4ge de-archiviert");
        }).catch(function () {
          return _this2.error("Fehler beim De-Archivieren der Einträge");
        });
      },
      resetModel: function resetModel() {
        this.refresh();
      }
    },

    missingCategoryRoute: "knb-categories",

    categoriesControllerName: "knb-categories",

    knbColumns: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("knbColumns").load();
    },
    model: function model(params) {
      var entryQuery = {
        category_id: params.category_id,
        include: "custom-information,custom-information-type,contacts",
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      return hash({
        category: this.modelFor("knb-categories").findBy("id", params.category_id),
        entries: (0, _paginationArray.makePaginationQuery)(this.get("store"), "knb-entry", entryQuery, params.page, params.perPage)
      });
    }
  });
});
define("bizbase3/pods/components/tag-input/component", ["exports", "bizbase3/utils/debounced-observer", "bizbase3/mixins/on-document-click"], function (exports, _debouncedObserver, _onDocumentClick) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed,
      inject = Ember.inject,
      isEmpty = Ember.isEmpty;

  exports.default = Ember.Component.extend(_onDocumentClick.default, {
    actions: {
      addTag: function addTag() {
        var _this = this;

        this.get("tagsService").createTag(this.get("newTag")).then(function (tag) {
          return _this.sendAssigned(tag);
        });
        this.resetTag();
      },
      assignTag: function assignTag(tag) {
        this.sendAssigned(tag);
      },
      deleteTag: function deleteTag(tag) {
        this.sendAction("tagRemoved", tag);
      },
      resetTag: function resetTag() {
        this.resetTag();
      }
    },

    classNames: ["tag-input"],

    classNameBindings: ["hasNewTag", "open:open"],

    hasNewTag: computed.notEmpty("newTag"),

    onDocumentClick: function onDocumentClick() {
      this.resetTag();
    },
    keyPress: function keyPress(event) {
      var inputValue = this.$("input").val();
      if (event.keyCode === 13 && !isEmpty(inputValue)) {
        this.sendAction("tagAdded", inputValue);
      }
    },


    querySuggestions: (0, _debouncedObserver.default)(function () {
      var _this2 = this;

      var newTag = this.get("newTag");
      if (!isEmpty(newTag)) {
        this.set("suggestionsPending", true);
        this.get("tagsService").search(newTag).then(function (tags) {
          tags = tags.sortBy("tag");

          var exactTag = tags.findBy("tag", newTag),
              tagSuggestions = [];

          if (!exactTag) {
            tagSuggestions.pushObject({
              doesntExist: true,
              tag: newTag
            });
          }

          tagSuggestions.pushObjects(tags.toArray());

          _this2.setProperties({ suggestionsPending: false, tagSuggestions: tagSuggestions });
        });
      } else {
        if (!this.get("isDestroyed")) {
          this.set("tagSuggestions", []);
        }
      }
    }, 500, "newTag"),

    resetTag: function resetTag() {
      this.set("newTag", "");
      this.set("tagSuggestions", []);
    },
    sendAssigned: function sendAssigned(tag) {
      this.sendAction("tagAssigned", tag);
    },


    open: computed.or("suggestionsPending", "tagSuggestions.length"),

    suggestionsPending: false,

    tags: computed(function () {
      return [];
    }),

    tagsService: inject.service("tags"),

    tagSuggestions: computed(function () {
      return [];
    })
  });
});
define("bizbase3/mixins/dropdown-toggle", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var run = Ember.run;

  exports.default = Ember.Mixin.create({
    actions: {
      toggleDropdown: function toggleDropdown() {
        var _this = this;

        this.toggleProperty("open");

        if (this.get("open")) {
          Ember.$(document).one("click keyup", function () {
            run(function () {
              if (!_this.get("isDestroyed")) {
                _this.set("open", false);
              }
            });
          });
        }
      }
    },

    classNameBindings: ["open"],

    open: false,

    click: function click(e) {
      if (this.get("open")) {
        e.stopPropagation();
      }
    },
    dropdownClose: function dropdownClose() {
      this.set("open", false);
    }
  });
});
define("bizbase3/components/main-navigation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    tagName: "nav",
    classNames: ["main-navigation", "push-left"],

    actions: {
      resetPermissions: function resetPermissions() {
        this.get("session").set("data.testpermissions", false);
        window.location.reload(true);
      }
    },

    navigation: service(),

    session: service(),

    store: service(),

    items: computed("navigation.config.[]", function () {
      var config = this.get("navigation.config");
      return config;
    })
  });
});
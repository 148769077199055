define("bizbase3/pods/assets/controller", ["exports", "bizbase3/mixins/data-table", "bizbase3/mixins/remote-pagination", "bizbase3/mixins/download-action", "bizbase3/mixins/searchable", "bizbase3/mixins/master-controller", "bizbase3/mixins/assets-controller", "bizbase3/utils/computed/validity", "bizbase3/mixins/sortable"], function (exports, _dataTable, _remotePagination, _downloadAction, _searchable, _masterController, _assetsController, _validity, _sortable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      inject = Ember.inject;


  var VIEWMODE_GALLERY = "gallery";
  var VIEWMODE_TABLE = "table";

  function qualityTexts() {
    return [{
      title: "modules.filemanager.modals.quality.small.title",
      info: "modules.filemanager.modals.quality.small.info"
    }, {
      title: "modules.filemanager.modals.quality.mid.title",
      info: "modules.filemanager.modals.quality.mid.info"
    }, {
      title: "modules.filemanager.modals.quality.high.title",
      info: "modules.filemanager.modals.quality.high.info"
    }, {
      title: "modules.filemanager.modals.quality.original.title",
      info: "modules.filemanager.modals.quality.original.info"
    }];
  }

  exports.default = Ember.Controller.extend(_dataTable.default, _remotePagination.default, _downloadAction.default, _searchable.default, _masterController.default, _sortable.default, _assetsController.default, {
    ajax: inject.service(),
    i18n: inject.service(),
    splitview: inject.service(),
    ican: inject.service(),
    actions: {
      deleteAssets: function deleteAssets() {
        var _this = this;

        var selection = this.get("selectedItems"),
            count = get(selection, "length");

        var message = "Sollen " + count + " Dateien wirklich gel\xF6scht werden?";

        if (count === 1) {
          message = "Soll die Datei \"" + get(selection, "firstObject.alias") + "\" wirklich gel\xF6scht werden?";
        }

        this.get("modalDialog").confirm(message).then(function () {
          _this.set("paginationArray.isPending", true);
          _this.deleteSelected(selection).finally(function () {
            return _this.set("paginationArray.isPending", false);
          });
        });
      },
      removeFromAlbum: function removeFromAlbum() {
        var _this2 = this;

        var selection = this.get("selectedItems"),
            count = get(selection, "length");

        var message = "Sollen " + count + " Dateien aus dem Album entfernt werden?";

        if (count === 1) {
          message = "Soll die Datei \"" + get(selection, "firstObject.alias") + "\" aus dem Album entfernt werden?";
        }

        this.get("modalDialog").confirm(message).then(function () {
          var album = _this2.get("model.album");

          get(album, "assets").removeObjects(selection);

          _this2.set("paginationArray.isPending", true);

          return album.save();
        }).then(function () {
          _this2.set("selection", []);
          _this2.success(count + " Datei" + (count !== 1 ? "en" : "") + " entfernt");
          return _this2.updateEntries();
        }).catch(function () {
          return _this2.error("Fehler beim Entfernen der Dateien");
        }).finally(function () {
          return _this2.set("paginationArray.isPending", false);
        });
      },
      resetNewShare: function resetNewShare() {
        this.set("newShare", false);
      },
      toggleViewMode: function toggleViewMode() {
        if (this.get("viewMode") === VIEWMODE_GALLERY) {
          this.set("viewMode", VIEWMODE_TABLE);
        } else {
          this.set("viewMode", VIEWMODE_GALLERY);
        }
      }
    },

    deleteErrorMessage: computed(function () {
      return this.get("i18n").t("modules.filemanager.notify.errorDelete");
    }),

    getPartlyDeleteMessage: function getPartlyDeleteMessage(count, countDeleted) {
      return this.get("i18n").t("modules.filemanager.notify.partlyFailedDeleted", {
        count: count - countDeleted
      });
    },
    getDeleteSuccessMessage: function getDeleteSuccessMessage(count) {
      return this.get("i18n").t("modules.filemanager.notify.deleted", { count: count });
    },


    imagesizeFormatter: function imagesizeFormatter(value) {
      return value + "Pixel";
    },

    imagesize: 100,

    quality: 4,

    qualityText: computed("quality", function () {
      return qualityTexts()[this.get("quality") - 1];
    }),

    qualityFormatter: function qualityFormatter(value) {
      var texts = qualityTexts();
      return texts[value - 1].title;
      //return this.get('i18n').t(texts[value - 1].title);
    },

    modalDialog: inject.service(),

    store: inject.service(),

    columnsConfig: inject.service("filemanager-columns"),

    paginationArray: computed.reads("model.assets"),

    collection: computed.reads("paginationArray"),

    category: computed.reads("model.category"),

    columns: computed.reads("columnsConfig.columns"),

    queryParams: [{ page: "assetPage" }, "perPage"],

    perPage: 18,

    perPageOptions: computed(function () {
      return [6, 12, 18, 24, 30, 36, 42].map(function (n) {
        return { label: n, value: n };
      });
    }),

    expirationAlertVisible: true,

    currentRoute: "assets",

    detailRoute: "asset",

    dataModelName: "asset",

    isGalleryViewMode: computed.equal("viewMode", VIEWMODE_GALLERY),

    isExpiredAlbum: computed("model.album.expirationDate", function () {
      var date = this.get("model.album.expirationDate"),
          now = new Date();

      if (!date) {
        return false;
      }

      return date < now;
    }),

    customFields: computed(function () {
      return [{
        key: "alias",
        label: this.get("i18n").t("modules.filemanager.tabs.info.name"),
        checked: true
      }, { key: "tags", label: this.get("i18n").t("generic.tags.title"), checked: true }, {
        key: "info",
        label: this.get("i18n").t("modules.filemanager.tabs.info.info"),
        checked: true
      }, {
        key: "content",
        label: this.get("i18n").t("modules.filemanager.tabs.info.content"),
        checked: false
      }, {
        key: "exif",
        label: this.get("i18n").t("modules.filemanager.tabs.exif.title"),
        checked: false
      }];
    }),

    canDownloadInternal: computed("selectedItems.@each.usageInternal", function () {
      var items = this.get("selectedItems");
      var hasInternalAssets = items.any(function (item) {
        return get(item, "usageInternal") === true;
      });
      if (!isEmpty(items) && hasInternalAssets) {
        return this.get("ican").authorized("filemanager.download.internal") && items.any(function (item) {
          return get(item, "usageInternal") === true;
        });
      } else {
        return true;
      }
    }),

    canDownload: computed("selectedItems.@each.validity", function () {
      var items = this.get("selectedItems");

      return !isEmpty(items) && (this.get("ican").authorized("filemanager.write") || items.any(function (item) {
        return get(item, "validity") !== _validity.VALID_BAD;
      }));
    }),

    showDownloadButton: computed("selectedItems.@each.filetype", function () {
      return !this.get("selectedItems").any(function (item) {
        return getWithDefault(item, "filetype", "").match(/^image/);
      });
    }),

    showPdfButton: computed("selectedItems.@each.alias", function () {
      if (this.get("selectedItems").length === 1) {
        return this.get("selectedItems").any(function (item) {
          return getWithDefault(item, "alias", "").match(/pdf/);
        });
      } else {
        return false;
      }
    }),

    currentPdf: computed("selectedItems", function () {
      if (this.get("selectedItems").length === 1) {
        var id = this.get("selectedItems")[0].get("id");
        return "//" + document.location.host + "/api/v3/assets/" + id + "/download/inline";
      } else {
        return false;
      }
    }),

    currentAlias: computed("selectedItems", function () {
      if (this.get("selectedItems").length === 1) {
        return this.get("selectedItems")[0].get("alias");
      } else {
        return false;
      }
    }),

    deleteAction: computed("model.album", {
      get: function get() {
        if (this.get("model.album")) {
          return "removeFromAlbum";
        }

        return "deleteAssets";
      }
    })
  });
});
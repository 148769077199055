define("bizbase3/pods/components/header-flyout-reminder/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "li",

    actions: {
      onDelete: function onDelete(reminder) {
        var _this = this;

        reminder.destroyRecord().then(function () {
          reminder.set("deleted", true);
          _this.success("modules.reminder.notify.deleted");
        }).catch(function (e) {
          _this.error("modules.reminder.notify.errorDelete");
          throw e;
        });
      },
      edit: function edit(reminder) {
        this.set("editReminder", reminder);
        this.set("showModal", true);
      }
    },

    classNames: ["notifications", "dropdown"],

    store: service(),

    reminder: service(),

    fetchdata: true,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get("fetchdata")) {
        this.get("reminder").fetch();
      }
    }
  });
});
define("bizbase3/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "bizbase3/config/environment"], function (exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var dasherize = Ember.String.dasherize;

  var pluralize = Ember.String.pluralize;

  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    authorizer: "authorizer:bizbase",

    coalesceFindRequests: true,

    host: _environment.default.BIZBASE_HOST,

    namespace: "/api/v3",

    pathForType: function pathForType(type) {
      return dasherize(pluralize(type));
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});
define("bizbase3/pods/components/calendar-form/component", ["exports", "bizbase3/mixins/notable", "moment", "bizbase3/utils/dirty-form-observer"], function (exports, _notable, _moment, _dirtyFormObserver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      on = Ember.on,
      service = Ember.inject.service;


  var colors = ["bg-navy", "bg-blue", "bg-aqua", "bg-teal", "bg-olive", "bg-green", "bg-lime", "bg-yellow", "bg-orange", "bg-red", "bg-fuchsia", "bg-purple", "bg-maroon", "bg-white", "bg-gray", "bg-silver", "bg-black"];

  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    calendarProjects: service(),

    users: service(),

    currentUser: service(),

    actions: {
      removeTag: function removeTag(tag) {
        this.get("appointment.tags").removeObject(tag);
      },
      assignTag: function assignTag(tag) {
        this.get("appointment.tags").addObject(tag);
      },
      onDelete: function onDelete(data) {
        var onDelete = this.get("onDelete");
        onDelete(data);
      }
    },

    _loadProjects: on("didInsertElement", function () {
      this.get("users").retrieve();
      this.get("calendarProjects").retrieve();
      this.get("currentUser").load();
    }),

    classNames: ["calendar-form"],

    colors: colors,

    isAppointment: computed.bool("appointment"),

    data: computed("appointment", {
      get: function get() {
        if (this.get("appointment")) {
          return {
            project: this.get("appointment.project"),
            start: this.get("appointment.start"),
            end: this.get("appointment.end"),
            user: this.get("appointment.user"),
            title: this.get("appointment.title"),
            description: this.get("appointment.description"),
            tags: this.get("appointment.tags")
          };
        }

        var date = new _moment.default();
        return {
          start: this.get("selectedDate") || date,
          end: new _moment.default(this.get("selectedDate")).add(60, "m"),
          user: this.get("currentUser.user"),
          project: this.get("calendarProjects.projects").get("firstObject")
        };
      },
      set: function set(key, value) {
        return value;
      }
    }),

    checkDirtyness: (0, _dirtyFormObserver.default)("contact", "firstName", "lastName", "company", "street1", "street2", "postcode", "city", "country", "email", "phone", "mobile", "fax", "mediaType", "mediaTitle", "department", "notes"),

    submit: function submit(e) {
      e.preventDefault();

      var data = this.get("data"),
          onSave = this.get("onSave");
      onSave(data);
    },
    onDelete: function onDelete() {},
    onDirt: function onDirt() {}
  });
});
define("bizbase3/pods/components/calendar-projects-list/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      on = Ember.on;
  exports.default = Ember.Component.extend(_notable.default, {
    tagName: "form",

    listClasses: ["list-group"],

    actions: {
      updateSelection: function updateSelection() {},
      toggleSelect: function toggleSelect(project) {
        project = !project;
        return project;
      }
    },

    classNames: ["calendar-projects-list"],

    calendarProjects: inject.service(),

    projects: computed.reads("calendarProjects.projects"),

    projectsByType: computed("calendarProjects.projects", function () {
      var groups = {};
      this.get("calendarProjects.projects").map(function (project) {
        if (!groups[project.get("calendarProjectType.title")]) {
          groups[project.get("calendarProjectType.title")] = {
            type: project.get("calendarProjectType"),
            items: []
          };
        }
        groups[project.get("calendarProjectType.title")].items.push(project);
      });
      return groups;
    }),

    _loadProjects: on("didInsertElement", function () {
      this.get("calendarProjects").retrieve();
    })
  });
});
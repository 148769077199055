define("bizbase3/mixins/selectable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      on = Ember.on;
  exports.default = Ember.Mixin.create({
    selected: computed("selection.[]", "modelId", function () {
      return this.getWithDefault("selection", []).includes(this.get("modelId"));
    }),

    selectThis: on("click", "contextMenu", function (e) {
      var onSelect = this.get("onSelect"),
          multiple = e.metaKey || e.ctrlKey,
          modelId = this.get("modelId");

      if (e.type !== "contextmenu" || !this.get("selected") || multiple) {
        if (typeof onSelect === "function") {
          e.preventDefault();
          e.stopPropagation();
          return onSelect(modelId, multiple);
        }
      }
    })
  });
});
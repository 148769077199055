define("bizbase3/mixins/user-proxy", ["exports", "bizbase3/utils/computed/promise-array", "bizbase3/utils/computed/select-options"], function (exports, _promiseArray, _selectOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Mixin.create({
    usersProxy: (0, _promiseArray.default)("user"),

    users: (0, _selectOptions.default)("usersProxy", "id", "fullName"),

    userId: computed("model.user.id", {
      get: function get() {
        return this.get("model.user.id");
      },
      set: function set(key, value) {
        return value;
      }
    })
  });
});
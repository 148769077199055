define("bizbase3/models/permission", ["exports", "ember-data/attr", "ember-data/model", "bizbase3/utils/computed/fields", "bizbase3/services/permissions-columns"], function (exports, _attr, _model, _fields, _permissionsColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    info: (0, _attr.default)("string"),
    permission: (0, _attr.default)("string"),

    columnsConfig: inject.service("permissions-columns"),
    fields: (0, _fields.default)(_permissionsColumns.COLUMNS.mapBy("key"))
  });
});
define("bizbase3/pods/components/split-view/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  exports.default = Ember.Component.extend({
    actions: {
      onDragstart: function onDragstart() {
        this.startDrag();
      }
    },

    classNames: ["split-view"],

    classNameBindings: ["isVertical:vertical:horizontal"],

    padding: 10,

    minSize: 50,

    splitPosition: 250,

    initialSplit: on("didInsertElement", function () {
      var initialPosition = this.get("splitDefaultAt");

      if (this.get("isVertical")) {
        if (!initialPosition) {
          initialPosition = this.$().width() / 2;
        }

        this.resizeVerticalSplits(initialPosition);
      } else {
        if (!initialPosition) {
          initialPosition = this.$().height() / 2;
        }

        this.resizeHorizontalSplits(initialPosition);
      }
    }),

    mouseUp: function mouseUp() {
      this.endDrag();
    },
    mouseLeave: function mouseLeave() {
      this.endDrag();
    },
    startDrag: function startDrag() {
      this.set("dragging", true);
      this.$().css({ cursor: "ew-resize", "user-select": "none" });
    },
    endDrag: function endDrag() {
      this.set("dragging", false);
      this.$().css({ cursor: "", "user-select": "" });
    },
    mouseMove: function mouseMove(e) {
      if (!this.get("dragging")) {
        return;
      }

      var offset = this.$().offset();
      var minSize = this.get("minSize");

      if (this.get("isVertical")) {
        this.set("splitPosition", Math.max(minSize, e.pageX - offset.left));
        this.resizeVerticalSplits();
      } else {
        this.set("splitPosition", Math.max(minSize, e.pageY - offset.top));
        this.resizeHorizontalSplits();
      }
    },
    resizeVerticalSplits: function resizeVerticalSplits() {
      var splitPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get("splitPosition");

      var padding = this.get("padding");
      var splitLeftPosition = splitPosition - padding;
      var splitRightPosition = splitPosition + padding + 5;

      this.$("> .split-handle").css("left", splitPosition);
      this.$("> .split-left").css("width", splitLeftPosition);
      this.$("> .split-right").css("left", splitRightPosition);
    },
    resizeHorizontalSplits: function resizeHorizontalSplits() {
      var splitPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get("splitPosition");

      var padding = this.get("padding");
      var splitTopPosition = splitPosition - padding;
      var splitBottomPosition = splitPosition + padding + 5;

      this.$("> .split-handle").css("top", splitPosition);
      this.$("> .split-top").css("height", splitTopPosition);
      this.$("> .split-bottom").css("top", splitBottomPosition);
    }
  });
});
define("bizbase3/storages/columns", ["exports", "ember-local-storage/local/object"], function (exports, _object) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ColumnStorage = _object.default.extend();

  exports.default = ColumnStorage;
});
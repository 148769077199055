define("bizbase3/mixins/item-selection", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      isArray = Ember.isArray,
      _Ember$RSVP = Ember.RSVP,
      allSettled = _Ember$RSVP.allSettled,
      reject = _Ember$RSVP.reject;
  exports.default = Ember.Mixin.create({
    deleteSelected: function deleteSelected() {
      var _this = this;

      var selectedItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get("selectedItems");

      if (!isArray(selectedItems)) {
        return reject("Selected items are undefined");
      }

      var promise = allSettled(selectedItems.map(function (item) {
        return item.destroyRecord();
      }));

      promise.then(function (results) {
        var count = get(selectedItems, "length"),
            countDeleted = get(results.filter(function (result) {
          return result.state === "fulfilled";
        }), "length");

        if (countDeleted === 0) {
          return _this.error(_this.get("deleteErrorMessage"));
        }

        if (countDeleted < count) {
          _this.warning(_this.getPartlyDeleteMessage(count, countDeleted));
        } else {
          _this.success(_this.getDeleteSuccessMessage(count));
        }

        _this.trigger("recordsDeleted", _this.get("selection"));
        _this.set("selection", []);
      });

      return promise;
    },


    selection: computed(function () {
      return [];
    }),

    selectedItems: computed("collection.@each.id", "selection.[]", function () {
      var selection = this.getWithDefault("selection", []),
          collection = this.getWithDefault("collection", []);

      return collection.filter(function (item) {
        return selection.includes(get(item, "id"));
      });
    })
  });
});
define("bizbase3/pods/components/reminder-edit-modal/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend(_notable.default, {
    error: false,

    reminderService: service("reminder"),

    modalId: computed("id", "params.[]", function (id, params) {
      return id = id || params[0];
    }),

    colors: ["bg-navy", "bg-blue", "bg-aqua", "bg-teal", "bg-olive", "bg-green", "bg-lime", "bg-yellow", "bg-orange", "bg-red", "bg-fuchsia", "bg-purple", "bg-maroon", "bg-white", "bg-gray", "bg-silver", "bg-black"],

    minutes: [0, 1, 5, 10, 15, 30, 45, 60],

    actions: {
      hide: function hide() {
        this.set("showModal", false);
      },
      close: function close() {
        this.set("showModal", false);
      },
      submit: function submit() {
        var _this = this;

        var reminder = this.get("reminder");
        return reminder.save().then(function () {
          _this.success("modules.reminder.notify.saved");
          _this.set("error", false);
          _this.set("showModal", false);
          _this.get("reminderService").pushReminder(reminder);
        }).catch(function (e) {
          _this.set("errorException", e);
          _this.set("error", "modules.reminder.notify.errorSaved");
        });
      }
    }
  });
});
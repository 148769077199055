define("bizbase3/initializers/responsive", ["exports", "ember-responsive/initializers/responsive"], function (exports, _responsive) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: "responsive",
    initialize: _responsive.initialize
  };
});
define("bizbase3/utils/services/settings-handler", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed;

  var inject = Ember.inject;

  exports.default = Ember.Service.extend({
    config: computed("setting.content", {
      get: function get() {
        return this.getWithDefault("setting.content.config", this.get("_defaultConfig"));
      },
      set: function set(key, value) {
        this.set("setting.content", { config: value });

        return value;
      }
    }),

    _defaultConfig: computed(function () {
      return [];
    }),

    load: function load() {
      var _this = this;

      return this.get("settings").query(this.get("storageKey")).then(function (setting) {
        _this.set("setting", setting);
        return setting;
      });
    },
    save: function save() {
      return this.get("setting").save();
    },


    settings: inject.service()
  });
});
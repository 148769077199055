define("bizbase3/pods/components/option-box/component", ["exports", "bizbase3/mixins/dropdown-toggle"], function (exports, _dropdownToggle) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dropdownToggle.default, {
    actions: {
      primaryAction: function primaryAction() {
        this.sendAction("primaryAction");
      }
    },

    btnClassNames: "btn-default",

    classNames: ["option-box btn-group"]
  });
});
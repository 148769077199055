define("bizbase3/mirage/factories/store", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _emberCliMirage.faker.name.firstName,
    type_id: 1,

    tel: function tel() {
      return _emberCliMirage.faker.phone.phoneNumber("+49 ##########");
    },
    fax: function fax() {
      return _emberCliMirage.faker.phone.phoneNumber("+49 17# #######");
    },

    mail: _emberCliMirage.faker.internet.email,

    street: _emberCliMirage.faker.address.streetName,
    zip: function zip() {
      return _emberCliMirage.faker.address.zipCode("#####");
    },
    city: function city() {
      return _emberCliMirage.faker.address.city();
    },
    country: function country() {
      return _emberCliMirage.faker.address.country();
    },
    manager: function manager() {
      return _emberCliMirage.faker.name.findName();
    },
    franchise_company: function franchise_company() {
      return _emberCliMirage.faker.company.companyName();
    },

    state: "",
    opening: _emberCliMirage.faker.date.past,

    category: 1,

    notice: _emberCliMirage.faker.lorem.paragraph
  });
});
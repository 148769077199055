define("bizbase3/mixins/modal-dialog", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    show: function show() {
      this.$("#" + this.get("dialogId")).modal("show");
    },
    hide: function hide() {
      this.$("#" + this.get("dialogId")).modal("hide");
    }
  });
});
define("bizbase3/models/share", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    token: (0, _attr.default)("string"),
    url: (0, _attr.default)("string"),
    info: (0, _attr.default)("string"),
    expiresAt: (0, _attr.default)("date"),

    shared: (0, _attr.default)("string"),

    sharedId: (0, _attr.default)("string"),

    createdBy: (0, _relationships.belongsTo)("user")
  });
});
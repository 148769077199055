define("bizbase3/app", ["exports", "bizbase3/resolver", "ember-load-initializers", "bizbase3/config/environment"], function (exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var locale = window.location.hostname.indexOf("real") > -1 ? "de_real" : "de";

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    locale: locale,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});
define("bizbase3/models/group", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "bizbase3/utils/computed/fields", "bizbase3/services/groups-columns"], function (exports, _attr, _model, _relationships, _fields, _groupsColumns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = _model.default.extend({
    name: (0, _attr.default)("string"),
    permissions: (0, _relationships.hasMany)(),

    columnsConfig: inject.service("groups-columns"),
    fields: (0, _fields.default)(_groupsColumns.COLUMNS.mapBy("key"))
  });
});
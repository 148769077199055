define("bizbase3/mirage/factories/file", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: function name(i) {
      return "Datei " + i;
    },
    size: function size() {
      return 1 + Math.random() * 9 * Math.pow(10, _emberCliMirage.faker.random.number({ min: 1, max: 12 }));
    },


    exif: "\n    {\n      \"thumbnail\" : {\n        \"Compression\" : 6,\n        \"Orientation\" : 1\n      },\n      \"exif\" : {\n        \"FNumber\" : 2.8,\n        \"ExposureProgram\" : 2\n      },\n      \"interoperability\" : {\n        \"InteropVersion\" : \"<Buffer 30 31 30 30>\",\n        \"InteropIndex\" : \"R98\"\n      },\n      \"image\" : {\n        \"Model\" : \"FinePix40i\",\n        \"Make\" : \"FUJIFILM\",\n        \"Model1\" : \"FinePix40i\",\n        \"Make1\" : \"FUJIFILM\",\n        \"Model1\" : \"FinePix40i\",\n        \"Make1\" : \"FUJIFILM\",\n        \"Model11\" : \"FinePix40i\",\n        \"Make11\" : \"FUJIFILM\",\n        \"Model1\" : \"FinePix40i\",\n        \"Make1\" : \"FUJIFILM\",\n        \"Model11\" : \"FinePix40i\",\n        \"Make11\" : \"FUJIFILM\",\n        \"Model11\" : \"FinePix40i\",\n        \"Make11\" : \"FUJIFILM\",\n        \"Model111\" : \"FinePix40i\",\n        \"Make111\" : \"FUJIFILM\"\n      },\n      \"makernote\" : {\n        \"Version\" : \"<Buffer 30 31 33 30>\",\n        \"Quality\" : \"NORMAL \"\n      }\n    }\n  ",

    filetype: function filetype() {
      return _emberCliMirage.faker.random.arrayElement(["image/png", "other"]);
    }
  });
});
define("bizbase3/utils/computed/validity", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (key, warnLevel) {
    return computed(key, function () {
      return validity(this.get(key), warnLevel);
    });
  };

  var computed = Ember.computed;

  var VALID_WARN = "warning";
  var VALID_BAD = "danger";

  function validity(value) {
    var warnLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;

    if (!value) {
      return null;
    }

    if (value >= 0 && value <= warnLevel) {
      return VALID_WARN;
    } else if (value < 0) {
      return VALID_BAD;
    }
  }

  exports.VALID_WARN = VALID_WARN;
  exports.VALID_BAD = VALID_BAD;
  exports.validity = validity;
});
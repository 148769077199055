define("bizbase3/components/category-node", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      on = Ember.on,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    actions: {
      expand: function expand() {
        this.toggleProperty("expanded");
      },
      drop: function drop(e, id) {
        this.sendAction("onDrop", e, id);
      },
      childExpansion: function childExpansion() {
        var _this = this;

        // run in next runloop because it is deprecated to rerender an element
        // twice in a single loop
        run.next(function () {
          return _this.expand();
        });
      }
    },

    dragOver: function dragOver(e) {
      var _this2 = this;

      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = "move";
      run(function () {
        // TODO: improve this: don't create multiple countdowns but just one
        _this2.set("countdown", setTimeout(function () {
          if (_this2.get("dragged")) {
            _this2.expand();
          }
        }, 500));
        _this2.set("dragged", true);
      });
    },
    drop: function drop(e) {
      var _this3 = this;

      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = "move";
      run(function () {
        _this3.fireDropAction(e);
        _this3.set("dragged", false);
        clearTimeout(_this3.get("countdown"));
      });
    },
    dragStart: function dragStart(e) {
      this.set("dragging", true);
      e.stopPropagation();
    },
    dragEnd: function dragEnd(e) {
      this.set("dragging", false);
      e.stopPropagation();
    },
    fireDropAction: function fireDropAction(e) {
      var onDrop = this.get("onDrop");

      var _JSON$parse = JSON.parse(e.dataTransfer.getData("application/x-bizbase3+json")),
          action = _JSON$parse.action,
          params = _JSON$parse.params;

      if (typeof onDrop === "function") {
        onDrop(action, params, this.get("node.id"));
      }
    },
    dragLeave: function dragLeave() {
      var _this4 = this;

      run(function () {
        _this4.set("dragged", false);
        clearTimeout(_this4.get("countdown"));
      });
    },
    expand: function expand() {
      this.set("expanded", true);
    },
    collapse: function collapse() {
      this.set("expanded", false);
    },


    expandCurrentCategory: on("init", observer("currentCategory.id", function () {
      var currentId = this.get("currentCategory.id"),
          id = this.get("id");

      if (id === currentId) {
        this.expand();
      }
    })),

    fireExpansion: observer("expanded", function () {
      if (this.get("expanded") && typeof this.attrs.onExpansion === "function") {
        this.attrs.onExpansion();
      }
    }),

    tagName: "li",

    classNames: ["node"],

    classNameBindings: ["dragged", "expanded", "dragging"],

    dragged: false,

    expanded: false,

    folderOpen: computed.and("expanded", "showChildTree"),

    hasChildren: computed.or("children.length", "newNode"),

    id: computed.reads("node.id"),

    name: computed.reads("node.name"),

    children: computed.sort("node.children", "childrenSorting"),

    childrenSorting: ["name:asc"],

    showChildTree: computed.or("hasChildren", "newNode")
  });
});
define("bizbase3/pods/components/copy-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "button",
    classNames: ["btn btn-primary btm-sm"],
    click: function click() {
      var _this = this;

      var copyListener = function copyListener(event) {
        event.clipboardData.setData("text/plain", _this.clipboardText);
        event.clipboardData.setData("text/html", _this.clipboardText);
        event.preventDefault();
        if (_this.success) {
          _this.success();
        }
      };
      document.addEventListener("copy", copyListener);
      document.execCommand("copy");
      document.removeEventListener("copy", copyListener);
    }
  });
});
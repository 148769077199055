define("bizbase3/models/category", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  var hasMany = _emberData.default.hasMany;
  var get = Ember.get;

  exports.default = _emberData.default.Model.extend({
    name: attr("string"),

    type: attr("string"),

    parent: belongsTo("category", {
      inverse: "children",
      async: true
    }),

    children: hasMany("category", {
      inverse: "parent",
      async: true
    }),

    parents: computed("parent", "isRoot", function () {
      if (this.get("isRoot")) {
        return [];
      } else {
        var parent = this.get("parent"),
            parents = get(parent, "parents");

        return parents.concat(parent);
      }
    }),

    descendants: computed("children", function () {
      return this.get("children").reduce(function (descendants, child) {
        return descendants.concat([child].concat(get(child, "descendants")));
      }, []);
    }),

    isRoot: computed("parent.name", function () {
      return !this.get("parent.name");
    }),

    path: computed("parents.@each.{name}", "name", function () {
      return this.get("parents").mapBy("name").concat(this.get("name"));
    })
  });
});
define("bizbase3/mirage/factories/album", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title() {
      return _emberCliMirage.faker.lorem.words(2).join(" ");
    },

    assets: [],
    shares: []
  });
});
define("bizbase3/mixins/notable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inject = Ember.inject;

  function aliasToNotify(type) {
    return function (message, i18nData) {
      var i18n = this.get("i18n");
      if (message.indexOf && message.indexOf(".notify.") > 0) {
        message = i18n.t(message, i18nData);
      }
      this.get("notify")[type](message);
    };
  }

  exports.default = Ember.Mixin.create({
    notify: inject.service(),
    i18n: inject.service(),
    info: aliasToNotify("info"),
    success: aliasToNotify("success"),
    warning: aliasToNotify("warning"),
    alert: aliasToNotify("alert"),
    error: aliasToNotify("error")
  });
});
define("bizbase3/pods/components/press-request-form/component", ["exports", "bizbase3/utils/dirty-form-observer", "bizbase3/utils/computed/indexed-options"], function (exports, _dirtyFormObserver, _indexedOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      set = Ember.set;


  function contact(idKey) {
    return computed(idKey, function () {
      return this.get("contacts.data").findBy("id", this.get(idKey));
    });
  }

  exports.default = Ember.Component.extend({
    tagName: "form",

    classNames: ["press-request-form"],

    data: computed(function () {
      return { tags: [] };
    }),

    tags: computed(function () {
      return [];
    }),

    actions: {
      assignTag: function assignTag(tag) {
        var data = this.get("data");
        set(data, "tags", this.get("tags"));
        this.get("tags").addObject(tag);
      },
      removeTag: function removeTag(tag) {
        this.get("tags").removeObject(tag);
      }
    },

    checkDirtyness: (0, _dirtyFormObserver.default)("receiptDate", "publicationDate", "respondUntil", "doneAt", "state", "note", "category", "contact"),

    onDelete: function onDelete() {},
    onDirt: function onDirt() {},


    states: (0, _indexedOptions.default)("pressRequestStateLabels.config"),

    contacts: service(),

    types: ["press", "shooting"],

    pressRequestStateLabels: service(),

    newContact: contact("newContactId"),

    newExternalContact: contact("newExternalContactId"),

    newResponsibleContact: contact("newResponsibleContactId"),

    widgetPositioning: { horizontal: "left", vertical: "bottom" },

    submit: function submit(e) {
      e.preventDefault();

      var newContact = this.get("newContact");
      var newExternalContact = this.get("newExternalContact");
      var newResponsibleContact = this.get("newResponsibleContact");
      var data = this.get("data");
      var onSave = this.get("onSave");

      if (newContact) {
        set(data, "contact", newContact);
      }

      if (newExternalContact) {
        set(data, "externalContact", newExternalContact);
      }

      if (newResponsibleContact) {
        set(data, "responsibleContact", newResponsibleContact);
      }

      onSave(data);
    },


    isDisabled: computed("messageText", "request.isNew", function () {
      return isEmpty(this.get("messageText")) && this.get("request.isNew");
    })
  });
});
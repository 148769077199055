define("bizbase3/models/calendar-project-type", ["exports", "ember-data", "ember-data/relationships"], function (exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    title: attr("string"),
    calendarProjects: (0, _relationships.hasMany)()
  });
});
define("bizbase3/pods/knb-entry/route", ["exports", "bizbase3/mixins/notable", "bizbase3/mixins/detail-route", "bizbase3/mixins/tabbed-route", "bizbase3/mixins/taggable-route"], function (exports, _notable, _detailRoute, _tabbedRoute, _taggableRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend(_notable.default, _detailRoute.default, _tabbedRoute.default, _taggableRoute.default, {
    actions: {
      sortInformation: function sortInformation(newOrder) {
        var _this = this;

        this.get("informationOrder").saveOrder(newOrder).then(function () {
          _this.success("Reihenfolge gespeichert");
        }, function () {
          _this.error("Fehler beim Speichern der Reihenfolge");
        });
      }
    },

    beforeModel: function beforeModel() {
      return RSVP.all([this._super(), this.get("informationOrder").load()]);
    },
    afterModel: function afterModel(entry) {
      entry.logView();
    },


    masterControllerName: "knb-entries",

    informationOrder: inject.service()
  });
});
define("bizbase3/pods/components/context-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      inject = Ember.inject,
      run = Ember.run,
      on = Ember.on;
  exports.default = Ember.Component.extend({
    classNames: ["context-menu"],

    classNameBindings: ["open"],

    contextMenuManager: inject.service(),

    setupHandler: on("didInsertElement", function () {
      var handler = this.get("for");

      if (handler[0] === "#") {
        // bind jquery handler
        $(handler).on("contextmenu", run.bind(this, this.cmClick));
      } else {
        this.get("contextMenuManager").on("showContextMenu", this, this.onShowContextMenu);
      }
    }),

    teardownHandler: on("willDestroyElement", function () {
      var handler = this.get("for");

      if (handler[0] === "#") {
        // unbind jquery handler
        $(handler).off("contextmenu.context-menu");
      } else {
        this.get("contextMenuManager").off("showContextMenu", this, this.onShowContextMenu);
      }

      $(document).off("click.context-menu contextmenu.context-menu context-menu");
    }),

    onShowContextMenu: function onShowContextMenu(originalEvent, contextMenuId, contextMenuData) {
      if (contextMenuId === this.get("for")) {
        this.set("data", contextMenuData);
        this.cmClick(originalEvent);
      }
    },
    cmClick: function cmClick(e) {
      e.preventDefault();

      this.toggleProperty("open");

      if (this.get("open")) {
        e.stopPropagation();

        this.position(e);

        this.get("contextMenuManager").trigger("onShow", this.get("for"));
        this.setupHider();
      }
    },
    position: function position(e) {
      // jQuery ui position only works on visible elements
      // `.css("display", "")` actually removes the property from the
      // style attribute of the element which is necessary as visibility is set
      // via css classes
      this.$(".dropdown-menu").css("display", "block").position({ my: "left-4 top-10", of: e, collision: "fit" }).css("display", "");
    },
    setupHider: function setupHider() {
      var _this = this;

      var $document = $(document),
          contextMenuManager = this.get("contextMenuManager");

      var hider = function hider(fromHandler) {
        if (!_this.get("isDestroyed") && (!fromHandler || fromHandler !== _this.get("for"))) {
          run(function () {
            return _this.set("open", false);
          });
        }

        contextMenuManager.off("onShow", hider);
        $document.off("click.context-menu contextmenu.context-menu context-menu");
      };

      contextMenuManager.one("onShow", hider);

      $document.one("click.context-menu contextmenu.context-menu context-menu", hider);
    }
  });
});
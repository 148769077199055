define("bizbase3/pods/components/asset-batch-edit-form/component", ["exports", "bizbase3/mixins/modal-dialog", "bizbase3/utils/computed/indexed-options"], function (exports, _modalDialog, _indexedOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;


  function proxy(property) {
    var def = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

    return computed("assets.length", "assets.firstObject." + property, {
      get: function get() {
        if (this.get("assets.length") > 1) {
          return typeof def === "function" ? def() : def;
        }

        return this.get("assets.firstObject." + property);
      },
      set: function set(key, value) {
        return value;
      }
    });
  }

  exports.default = Ember.Component.extend(_modalDialog.default, {
    actions: {
      cancel: function cancel() {
        this.resetForm();
        this.hide();
      },
      removeTag: function removeTag(tag) {
        this.get("tags").removeObject(tag);
      },
      assignTag: function assignTag(tag) {
        this.get("tags").addObject(tag);
      },
      toggleUnlimitedAccess: function toggleUnlimitedAccess() {
        this.toggleProperty("hasUnlimitedAccess");
      },
      toggleReplaceTags: function toggleReplaceTags() {
        this.toggleProperty("replaceTags");
      }
    },

    resetForm: function resetForm() {
      if (this.get("assets.length") !== 1) {
        this.setProperties({
          expires_at: null,
          info: null,
          tags: [],
          hasUnlimitedAccess: false,
          usage: 0
        });
      }
    },
    submit: function submit(e) {
      e.preventDefault();

      var onSubmit = this.get("onSubmit");

      if (typeof onSubmit === "function") {
        var properties = this.getProperties("expires_at", "info", "tags", "hasUnlimitedAccess", "replaceTags", "usage");
        onSubmit(properties);
      }

      this.resetForm();
      this.hide();
    },


    expires_at: proxy("expires_at"),

    info: proxy("info"),

    usage: proxy("usage"),

    tags: proxy("tags", function () {
      return [];
    }),

    dialogId: "batchEditDialog",

    tagName: "form",

    assetUsageLabels: service(),

    usageOptions: (0, _indexedOptions.default)("assetUsageLabels.config")
  });
});
define("bizbase3/pods/search/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      if (params.q) {
        return this.get("search").globalSearch(params.q, params.page, params.perPage);
      }

      return {};
    },


    search: inject.service()
  });
});
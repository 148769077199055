define("bizbase3/services/contacts-columns", ["exports", "bizbase3/utils/services/settings-handler", "bizbase3/mixins/columns-service", "moment"], function (exports, _settingsHandler, _columnsService, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.COLUMNS = undefined;
  var computed = Ember.computed;


  var STORAGE_KEY = "BIZBASE3.contacts.columnConfig";
  var MODULE_NAME = "contacts";

  var COLUMNS = [{ key: "firstName", searchview: true }, { key: "lastName", searchview: true }, { key: "email", searchview: true }, { key: "company", searchview: true }, { key: "mediaTitle", searchview: true }, { key: "mediaType" }, { key: "department" }, { key: "phone" }, { key: "fax" }, { key: "mobile" }, { key: "street1" }, { key: "postcode" }, { key: "country" }, {
    key: "createdAt",
    format: function format(date) {
      return date ? (0, _moment.default)(date).format("LL") : "-";
    }
  }];

  exports.COLUMNS = COLUMNS;
  exports.default = _settingsHandler.default.extend(_columnsService.default, {
    storageKey: STORAGE_KEY,
    moduleName: computed(function () {
      return MODULE_NAME;
    }),
    _columns: computed(function () {
      return COLUMNS;
    })
  });
});
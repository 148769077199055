define("bizbase3/mirage/factories/user", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    firstName: _emberCliMirage.faker.name.firstName,
    lastName: _emberCliMirage.faker.name.lastName,
    email: _emberCliMirage.faker.internet.email,
    lastLogin: _emberCliMirage.faker.date.past,
    contact: null
  });
});
define("bizbase3/instance-initializers/i18n", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var locale = window.location.hostname.indexOf("real") > -1 ? "de_real" : "de";

  exports.default = {
    name: "i18n",
    initialize: function initialize(container) {
      var i18n = container.lookup("service:i18n");

      i18n.set("locale", locale);
    }
  };
});
define("bizbase3/mirage/factories/group", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: _emberCliMirage.faker.commerce.department,
    info: _emberCliMirage.faker.commerce.department
  });
});
define("bizbase3/pods/asset-categories/index/route", ["exports", "bizbase3/mixins/categories-index-route"], function (exports, _categoriesIndexRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_categoriesIndexRoute.default, {
    categorizedRouteName: "assets",

    redirect: function redirect(_ref) {
      var model = _ref.model;

      this._super(model);
    }
  });
});
define("bizbase3/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: service(),

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get("currentUser").load().catch(function () {
        return _this.get("session").invalidate();
      });
    }
  });
});
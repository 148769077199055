define("bizbase3/pods/components/user-search/component", ["exports", "ember-concurrency", "bizbase3/mixins/notable", "ember-light-table"], function (exports, _emberConcurrency, _notable, _emberLightTable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_notable.default, {
    results: [],
    store: "contacts",
    contactsColumns: service(),
    search: service(),
    query: "",
    isLoading: Ember.computed.or("searchUsers.isRunning").readOnly(),
    hasSelection: Ember.computed.notEmpty("table.selectedRows"),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("contactsColumns").load().then(function () {
        var moduleCols = _this.get("contactsColumns.columns");
        var i18n = _this.get("i18n");

        var columns = moduleCols.filter(function (col) {
          return col.searchview;
        }).map(function (col) {
          if (col.searchview) {
            return {
              label: i18n.t("modules.contacts.columns." + col.key),
              valuePath: col.key,
              sortable: false
            };
          }
        });

        columns.push({
          label: "Actions",
          width: "100px",
          sortable: false,
          cellComponent: "user-search/action-column"
        });

        _this.set("table", new _emberLightTable.default(columns, _this.get("results"), {
          enableSync: _this.get("enableSync")
        }));
      });
    },


    actions: {
      onSearchChange: function onSearchChange() {
        this.get("searchUsers").perform();
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? "asc" : "desc",
            sort: column.get("valuePath"),
            canLoadMore: true,
            page: 0
          });
        }
      },
      selectRow: function selectRow(row) {
        this.get("onselect")(row);
      }
    },

    searchUsers: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var query, search, results;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(250);

            case 2:
              query = this.get("query");
              search = this.get("search");
              _context.prev = 4;
              _context.next = 7;
              return search.search("contact", "exp:" + query);

            case 7:
              results = _context.sent;

              this.set("results", results);
              this.get("table").setRows(results.toArray());
              _context.next = 15;
              break;

            case 12:
              _context.prev = 12;
              _context.t0 = _context["catch"](4);

              this.error(_context.t0.message);

            case 15:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, this, [[4, 12]]);
    })).restartable()
  });
});
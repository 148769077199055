define("bizbase3/mirage/factories/knb-entry", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title() {
      var words = _emberCliMirage.faker.lorem.words(3).join(" ");
      return words.charAt(0).toUpperCase() + words.slice(1);
    },

    category: null,
    user: null,
    updatedBy: null,
    information: [],
    assets: [],
    tags: [],
    archive: false,
    createdAt: function createdAt() {
      return _emberCliMirage.faker.date.past();
    },
    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.past();
    },
    views: function views() {
      return _emberCliMirage.faker.random.number({ min: 0, max: 1000 });
    },
    validFrom: function validFrom() {
      return _emberCliMirage.faker.date.past();
    },
    validTill: function validTill() {
      var soon = new Date();
      soon.setTime(soon.getTime() + 5 * 24 * 3600000);

      return _emberCliMirage.faker.random.arrayElement([_emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.future(), _emberCliMirage.faker.date.past(), _emberCliMirage.faker.date.past(), _emberCliMirage.faker.date.between(new Date(), soon), _emberCliMirage.faker.date.between(new Date(), soon)]);
    }
  });
});
define("bizbase3/mirage/config", ["exports", "ember-cli-mirage", "bizbase3/mirage/factories/asset", "bizbase3/mirage/factories/share", "npm:jsonwebtoken", "bizbase3/mixins/remote-pagination", "bizbase3/utils/global-search-result", "bizbase3/utils/global-search-inquries-result"], function (exports, _emberCliMirage, _asset, _share, _npmJsonwebtoken, _remotePagination, _globalSearchResult, _globalSearchInquriesResult) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.namespace = "/api/v3";

    this.get("/categories", function (db) {
      return {
        categories: db.categories,
        contacts: db.contacts
      };
    });

    this.put("/categories/:id", function (db, request) {
      var id = request.params.id,
          attrs = JSON.parse(request.requestBody).category,
          parent = db.categories.find(attrs.parent);

      if (parent) {
        parent.children.addObject(id);
        db.categories.update(parent.id, parent);
      }

      return {
        category: db.categories.update(id, attrs)
      };
    });

    this.get("/categories/:id", function (db, request) {
      var id = request.params.id;
      return {
        category: { id: id, name: "foo" }
      };
    });

    this.delete("/categories/:id");

    this.get("/knb/entries", function (db, request) {
      var _request$queryParams = request.queryParams,
          category_id = _request$queryParams.category_id,
          page = _request$queryParams.page,
          perPage = _request$queryParams.perPage,
          sortDir = _request$queryParams.sortDir,
          sortKey = _request$queryParams.sortKey,
          recordId = _request$queryParams.recordId;


      if (!category_id) {
        category_id = 1;
      }

      var query = request.queryParams["search[query]"];

      if (query === "error500") {
        return new _emberCliMirage.default.Response(500, {}, {
          errors: [{
            id: "500",
            links: {
              about: "https://help.bizbase3.de"
            },
            status: "500",
            code: "100",
            title: "Fehler in der Suche",
            detail: "Die Suche konnte nicht durchgeführt werden.",
            source: {
              pointer: "{a JSON Pointer to the associated entity in the request document}",
              parameter: "{a string indicating which URI query parameter caused the error}"
            },
            meta: {}
          }]
        });
      }
      var entries = db["knb-entries"].filter(function (_ref) {
        var category = _ref.category,
            title = _ref.title;

        var found = true;

        if (query) {
          found = title.match(query);
        }

        return +category === +category_id && found;
      });

      if (sortKey) {
        entries = entries.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          entries = entries.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = entries.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage;
      var total = entries.length;

      return {
        "knb-entries": entries.slice(start, start + parseInt(perPage, 10)),
        "custom-information": db["custom-information"],
        "custom-information-types": db["custom-information-types"],
        contacts: db.contacts,
        users: db.users,
        assets: db.assets,
        stores: db.stores,
        "press-requests": db["press-requests"],
        meta: { page: page, total: total }
      };
    });

    this.get("/knb/entries/:id", function (db, request) {
      return {
        "knb-entry": db["knb-entries"].find(request.params.id),
        "custom-information": db["custom-information"],
        "custom-information-types": db["custom-information-types"],
        contacts: db.contacts,
        categories: db.categories,
        assets: db.assets
      };
    });

    this.post("/knb/entries", function (db, request) {
      var payload = JSON.parse(request.requestBody).knbEntry;
      var entry = db["knb-entries"].insert(payload);

      return {
        "knb-entry": entry
      };
    });

    this.put("/knb/entries/:id", function (db, request) {
      var id = request.params.id,
          attrs = JSON.parse(request.requestBody)["knbEntry"];

      return {
        "knb-entry": db["knb-entries"].update(id, attrs)
      };
    });

    this.delete("/knb/entries/2", { error: true }, 500);
    this.delete("/knb/entries/:id", function (db, request) {
      var id = request.params.id,
          record = db["knb-entries"].find(id);

      db["knb-entries"].remove(id);

      return {
        "knb-entry": record
      };
    });

    this.get("/knb/custom-information-types");

    this.get("/knb/custom-information");

    this.get("/knb/custom-information/:id", function (db, request) {
      var id = request.params.id;
      var record = db["custom-information"].find(id);

      return {
        "custom-information": record
      };
    });

    this.put("/knb/custom-information/:id", function (db, request) {
      var id = request.params.id;
      var attrs = JSON.parse(request.requestBody)["customInformation"];
      var record = db["custom-information"].update(id, attrs);

      return {
        "custom-information": record
      };
    });

    this.get("/knb/custom-information-versions", function (db, request) {
      if (!request.queryParams.ids) {
        request.queryParams.ids = [];
      }

      return {
        "custom-information-versions": db["custom-information-versions"].filter(function (version) {
          return request.queryParams.ids.includes("" + version.id);
        })
      };
    });

    this.get("/knb/custom-information-versions/:id", function (db, request) {
      var id = request.params.id,
          record = db["custom-information-versions"].find(id);

      return { "custom-information-version": record };
    });

    this.put("/knb/custom-information-types/:id", function (db, request) {
      var id = request.params.id;
      var attrs = JSON.parse(request.requestBody)["customInformationType"];
      var record = db["custom-information-types"].update(id, attrs);

      return {
        "custom-information-type": record
      };
    });

    this.get("/assets", function (db, request) {
      var _request$queryParams2 = request.queryParams,
          category_id = _request$queryParams2.category_id,
          album_id = _request$queryParams2.album_id,
          page = _request$queryParams2.page,
          perPage = _request$queryParams2.perPage,
          sortDir = _request$queryParams2.sortDir,
          sortKey = _request$queryParams2.sortKey,
          recordId = _request$queryParams2.recordId,
          ids = _request$queryParams2.ids;

      var query = request.queryParams["search[query]"];

      if (ids) {
        ids = ids.map(function (id) {
          return +id;
        });
      }

      var assets = db.assets.filter(function (asset) {
        var found = true;

        if (query) {
          found = asset.alias.match(query);
        }

        if (album_id) {
          return query && found || !query && +asset.album === +album_id;
        }

        if (ids) {
          return ids.includes(asset.id);
        }

        return query && found || !query && +asset.category === +category_id;
      });

      if (sortKey) {
        assets = assets.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          assets = assets.reverse();
        }
      }

      if (!perPage) {
        perPage = 18;
      }

      if (!page) {
        var position = assets.mapBy("id").indexOf(+recordId) + 1;

        page = max(1, ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = assets.length;

      if (!ids) {
        assets = assets.slice(start, start + parseInt(perPage, 10));
      }

      return {
        assets: assets,
        files: db.files,
        contacts: db.contacts,
        tags: db.tags,
        meta: { page: page, total: total }
      };
    });

    this.put("/assets/:id", function (db, request) {
      var id = request.params.id,
          attrs = JSON.parse(request.requestBody)["asset"];

      var album = db.albums.find(attrs.album);
      if (album) {
        if (!album.assets) {
          album.assets = [];
        }
        db.albums.update(album.id, { assets: album.assets.concat([id]) });
      }

      return {
        asset: db.assets.update(id, attrs)
      };
    });

    this.post("/assets", function (db /*, request*/) {
      // TODO: return random asset for now as FormData is empty, because of a bug in mirage
      // See: https://github.com/samselikoff/ember-cli-mirage/issues/74

      var randomAsset = assetFactory.build(assetSequence++);

      randomAsset.category = 1;

      return {
        asset: db.assets.insert(randomAsset)
      };
    });

    this.delete("/assets/2", { error: true }, 500);
    this.delete("/assets/:id");

    this.get("/assets/:id", function (db, request) {
      return {
        asset: db["assets"].find(request.params.id)
      };
    });

    this.get("/albums", function (_ref2) {
      var albums = _ref2.albums,
          assets = _ref2.assets,
          files = _ref2.files,
          contacts = _ref2.contacts;

      return { albums: albums, assets: assets, files: files, contacts: contacts };
    });

    this.post("/albums");

    this.put("/albums/:id", function (db, request) {
      var attr = JSON.parse(request.requestBody)["album"],
          assetIds = db.albums.find(request.params.id).assets,
          album = db.albums.update(request.params.id, attr);

      assetIds.forEach(function (id) {
        if (!attr.assets.includes("" + id)) {
          db.assets.update(id, { album: null });
        }
      });

      return {
        album: album,
        assets: db.assets
      };
    });

    this.delete("/albums/:id");

    this.get("/files");

    this.get("/files/:id");

    this.get("/tags", function (db, request) {
      var tags = db.tags,
          query = request.queryParams.searchQuery;

      if (query) {
        tags = tags.filter(function (tag) {
          return tag.tag.match(query);
        });
      }

      return { tags: tags };
    });

    this.post("/tags");

    this.post("/categories", function (db, request) {
      var attrs = JSON.parse(request.requestBody)["category"],
          record = db.categories.insert(attrs);

      return {
        category: record
      };
    });

    this.get("/settings", function (db, request) {
      var key = request.queryParams.key;

      return {
        settings: db.settings.where({ key: key })
      };
    });

    this.post("/settings");

    this.put("/settings/:id", "setting");

    this.post("/sessions", function (db, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          username = _JSON$parse.username,
          password = _JSON$parse.password;

      if (username === "Hans" && password === "Admin") {
        return {
          session: {
            token: _npmJsonwebtoken.default.sign({
              permissions: ["dashboard.read", "classic.read", "filemanager.read", "contacts.read", "contacts.write", "filemanager.download", "filemanager.download.internal", "filemanager.read", "filemanager.write", "filemanager.album.write", "filemanager.album.read", "filemanager.clippings", "groups.read", "groups.write", "knowledgebase.read", "knowledgebase.write", "permissions.read", "permissions.write", "press.read", "press.write", "settings.read", "settings.write", "calendar.read", "calendar.write", "stores.read", "stores.write", "globalsearch", "users.read", "users.write", "reminders.read", "reminders.write", "glm.write", "header.splitview"],
              uid: 1
            }, "abc")
          }
        };
      } else {
        return new _emberCliMirage.default.Response(401, {}, { message: "Login fehlgeschlagen" });
      }
    });

    this.post("/sessions/password", function (db, request) {
      var _JSON$parse2 = JSON.parse(request.requestBody),
          email = _JSON$parse2.email;

      if (email === "wilms@tovista.net") {
        return {
          success: true
        };
      } else {
        return new _emberCliMirage.default.Response(401, {}, { message: "Login fehlgeschlagen" });
      }
    });

    this.get("/shares", function (db, request) {
      var shares = db.shares;

      var shareIds = request.queryParams.ids.map(function (id) {
        return parseInt(id, 10);
      });

      if (shareIds) {
        shares = shares.filter(function (share) {
          return shareIds.includes(share.id);
        });
      }

      return { shares: shares };
    });

    this.post("/shares", function (db, request) {
      var _JSON$parse3 = JSON.parse(request.requestBody),
          _JSON$parse3$share = _JSON$parse3.share,
          shared = _JSON$parse3$share.shared,
          sharedId = _JSON$parse3$share.sharedId;

      var share = shareFactory.build(shareSequence++);

      share = db.shares.insert(share);

      var response = { share: share };

      if (shared === "asset") {
        var asset = db.assets.find(sharedId);

        asset.shares.addObject(share.id);
        db.assets.update(asset.id, asset);

        response.assets = db.assets;
      } else if (shared === "album") {
        var album = db.albums.find(sharedId);

        album.shares.addObject(share.id);
        db.albums.update(album.id, album);
      }

      return response;
    });

    this.get("/shares/:id");

    this.put("/shares/:id");

    this.get("/contacts", function (db, request) {
      var _request$queryParams3 = request.queryParams,
          category_id = _request$queryParams3.category_id,
          groupIds = _request$queryParams3.groupIds,
          ids = _request$queryParams3.ids,
          page = _request$queryParams3.page,
          perPage = _request$queryParams3.perPage,
          recordId = _request$queryParams3.recordId,
          sortDir = _request$queryParams3.sortDir,
          sortKey = _request$queryParams3.sortKey;


      if (!category_id) {
        category_id = 1;
      }

      if (ids) {
        ids = ids.map(function (id) {
          return +id;
        });
      }

      var query = request.queryParams["search[query]"];
      var contacts = db.contacts.filter(function (_ref3) {
        var id = _ref3.id,
            firstName = _ref3.firstName,
            group = _ref3.group,
            category = _ref3.category;

        if (ids) {
          return ids.includes(id);
        }

        if (query) {
          if (query.indexOf("exp:") > -1) {
            query = query.substring(4);
          }
          return firstName.match(query);
        }

        if (groupIds && !groupIds.includes("" + group)) {
          return false;
        }

        return +category === +category_id;
      });

      if (!category_id) {
        return {
          contacts: db.contacts
        };
      }

      if (sortKey) {
        contacts = contacts.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          contacts = contacts.reverse();
        }
      }

      if (!perPage) {
        perPage = 10;
      }

      if (!page) {
        var position = contacts.mapBy("id").indexOf(+recordId) + 1;

        page = max(1, ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = contacts.length;

      if (!ids) {
        contacts = contacts.slice(start, start + parseInt(perPage, 10));
      }

      return {
        contacts: contacts,
        meta: { page: page, total: total }
      };
    });
    this.get("/contacts/:id");

    this.post("/contacts", {
      errors: [{
        detail: "Bitte überprüfen",
        source: {
          pointer: "data/attributes/firstName"
        }
      }, {
        detail: "Bitte überprüfen",
        source: {
          pointer: "data/attributes/lastName"
        }
      }]
    }, 422);
    this.put("/contacts/2", { error: true }, 500);
    this.put("/contacts/:id", function (db, request) {
      var _JSON$parse4 = JSON.parse(request.requestBody),
          contactAttr = _JSON$parse4.contact;

      var contact = db.contacts.update(request.params.id, contactAttr);
      return {
        contact: contact
      };
    });
    this.delete("/contacts/2", { error: true }, 500);
    this.delete("/contacts/:id");
    this.get("/contact-groups");
    this.get("/contact-groups/:id");

    this.get("/press-requests", function (db, request) {
      var _request$queryParams4 = request.queryParams,
          category_id = _request$queryParams4.category_id,
          page = _request$queryParams4.page,
          perPage = _request$queryParams4.perPage,
          sortDir = _request$queryParams4.sortDir,
          sortKey = _request$queryParams4.sortKey,
          recordId = _request$queryParams4.recordId,
          ids = _request$queryParams4.ids;

      if (!category_id) {
        category_id = 1;
      }

      if (ids) {
        ids = ids.map(function (id) {
          return +id;
        });
      }
      var query = request.queryParams["search[query]"],
          pressRequests = db["press-requests"].filter(function (_ref4) {
        var id = _ref4.id,
            contact = _ref4.contact,
            category = _ref4.category;

        var found = true;

        if (ids) {
          return ids.includes(id);
        }

        var _ref5 = db.contacts.find(contact) || { firstName: "" },
            firstName = _ref5.firstName;

        if (query) {
          found = firstName.match(query);
        }

        return query && found || !query && +category === +category_id;
      });

      if (sortKey) {
        pressRequests = pressRequests.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          pressRequests = pressRequests.reverse();
        }
      }

      if (!perPage) {
        perPage = 10;
      }

      if (!page) {
        var position = pressRequests.mapBy("id").indexOf(+recordId) + 1;

        page = max(1, ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = pressRequests.length;

      if (!ids) {
        pressRequests = pressRequests.slice(start, start + parseInt(perPage, 10));
      }

      return {
        "press-requests": pressRequests,
        stores: db.stores,
        meta: { page: page, total: total }
      };
    });
    this.get("/press-requests/:id");

    this.put("/press-requests/2", { error: true }, 500);
    this.put("/press-requests/:id", function (db, req) {
      //TODO: need to implement that endpoint presumably because of the dash in the route. we need to
      // upgrade to >= mirage-0.2.x anyways
      var attr = JSON.parse(req.requestBody).pressRequest;

      return {
        "press-request": db["press-requests"].update(req.params.id, attr)
      };
    });

    this.put("/press-requests/:id/finish", function (db, req) {
      //TODO: need to implement that endpoint presumably because of the dash in the route. we need to
      // upgrade to >= mirage-0.2.x anyways
      return {
        "press-request": db["press-requests"].update(req.params.id, { state: 3 })
      };
    });

    this.get("/messages", function (db) {
      return { messages: db.messages, contacts: db.contacts };
    });
    this.post("/messages", function (db, req) {
      var attr = JSON.parse(req.requestBody).message,
          request = db["press-requests"].find(+attr.pressRequest);

      if (!attr.date) {
        attr.date = new Date();
      }

      if (!attr.author) {
        attr.author = db.users.find(1).contact;
      }

      var message = db.messages.insert(attr);

      if (!request.messages) {
        request.messages = [];
      }

      db["press-requests"].update(request.id, { messages: request.messages.concat([message.id]) });

      return { message: message };
    });
    this.put("/messages/:id");

    this.delete("/press-requests/2", { error: true }, 500);
    this.delete("/press-requests/:id");

    // there is a bug in mirage's built-ins with dashed routes
    this.post("/press-requests", function (db, request) {
      var payload = JSON.parse(request.requestBody).pressRequest;

      return {
        "press-request": db["press-requests"].insert(payload)
      };
    });

    this.get("/dashboard/stats", function () {
      return {
        stats: {
          pressIquries: 112,
          stores: 422,
          knbEntries: 1874,
          contacts: 288
        }
      };
    });

    this.get("/stores", function (db, request) {
      var _request$queryParams5 = request.queryParams,
          page = _request$queryParams5.page,
          perPage = _request$queryParams5.perPage,
          sortDir = _request$queryParams5.sortDir,
          sortKey = _request$queryParams5.sortKey,
          recordId = _request$queryParams5.recordId,
          category_id = _request$queryParams5.category_id;

      var query = request.queryParams["search[query]"],
          entries = db["stores"].filter(function (entry) {
        var found = true;

        if (query) {
          found = entry.name.match(query);
        }

        return query && found || !query && +entry.category === +category_id;
      });

      if (sortKey) {
        entries = entries.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          entries = entries.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = entries.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = entries.length;

      return {
        stores: entries.slice(start, start + parseInt(perPage, 10)),
        meta: { page: page, total: total }
      };
    });

    this.get("/stores/:id", function (db, request) {
      return {
        store: db["stores"].find(request.params.id)
      };
    });

    this.put("/stores/:id", function (db, request) {
      var _JSON$parse5 = JSON.parse(request.requestBody),
          storeAttr = _JSON$parse5.store;

      var store = db.stores.update(request.params.id, storeAttr);
      return {
        store: store
      };
    });

    this.put("/stores/2", { error: true }, 500);

    this.get("/users", function (db, request) {
      var _request$queryParams6 = request.queryParams,
          ids = _request$queryParams6.ids,
          page = _request$queryParams6.page,
          perPage = _request$queryParams6.perPage,
          sortDir = _request$queryParams6.sortDir,
          sortKey = _request$queryParams6.sortKey,
          recordId = _request$queryParams6.recordId;


      if (ids) {
        ids = ids.map(function (id) {
          return +id;
        });
      }

      var query = request.queryParams["search[query]"];
      var users = db["users"].filter(function (_ref6) {
        var id = _ref6.id,
            contact = _ref6.contact;

        if (ids) {
          return ids.includes(id);
        }

        if (query) {
          var contactRecord = db.contacts.find(contact);
          return contactRecord.firstName.match(query) || contactRecord.lastName.match(query);
        }

        return true;
      });

      if (sortKey) {
        users = users.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          users = users.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = users.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage;
      var total = users.length;

      if (!ids) {
        users = users.slice(start, start + parseInt(perPage, 10));
      }

      return { users: users, groups: db.groups, meta: { page: page, total: total } };
    });

    this.get("/users/:id", function (db, request) {
      return {
        user: db["users"].find(request.params.id)
      };
    });

    this.post("/users", function (db, request) {
      var _JSON$parse6 = JSON.parse(request.requestBody),
          userAttr = _JSON$parse6.user,
          contactAttr = _JSON$parse6.contact;

      var contact = db.contacts.insert(contactAttr);

      userAttr.contact = contact.id;
      var user = db.users.insert(userAttr);

      return {
        user: user,
        contacts: [contact]
      };
    });
    // uncomment the following line to simulate error POSTs.
    // this.post("/users", { error: true }, 500); // DONT COMMIT THAT! TESTS WILL FAIL!

    this.delete("/users/2", { error: true }, 500);
    this.delete("/users/:id");
    this.put("/users/:id", function (db, request) {
      var _JSON$parse7 = JSON.parse(request.requestBody),
          userAttr = _JSON$parse7.user,
          contactAttr = _JSON$parse7.contact;

      var user = db.users.update(request.params.id, userAttr);
      if (contactAttr) {
        var contact = db.contacts.update(userAttr.contact, contactAttr);
        return {
          user: user,
          contacts: [contact]
        };
      } else {
        return {
          user: user
        };
      }
    });
    this.put("/users/2", { error: true }, 500);

    this.get("/groups", function (db, request) {
      var _request$queryParams7 = request.queryParams,
          page = _request$queryParams7.page,
          perPage = _request$queryParams7.perPage,
          sortDir = _request$queryParams7.sortDir,
          sortKey = _request$queryParams7.sortKey,
          recordId = _request$queryParams7.recordId;

      var query = request.queryParams["search[query]"],
          entries = db["groups"].filter(function (entry) {
        var found = true;

        if (query) {
          found = entry.title.match(query);
        }

        return found;
      });

      if (sortKey) {
        entries = entries.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          entries = entries.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = entries.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = entries.length;

      return {
        groups: entries.slice(start, start + parseInt(perPage, 10)),
        meta: { page: page, total: total }
      };
    });

    this.get("/groups/:id", function (db, request) {
      return {
        group: db["groups"].find(request.params.id)
      };
    });

    this.post("/groups");
    this.delete("/groups/2", { error: true }, 500);
    this.delete("/groups/:id");
    this.put("/groups/:id");

    this.get("/permissions", function (db, request) {
      var _request$queryParams8 = request.queryParams,
          page = _request$queryParams8.page,
          perPage = _request$queryParams8.perPage,
          sortDir = _request$queryParams8.sortDir,
          sortKey = _request$queryParams8.sortKey,
          recordId = _request$queryParams8.recordId;

      var query = request.queryParams["search[query]"],
          entries = db["permissions"].filter(function (entry) {
        var found = true;

        if (query) {
          found = entry.title.match(query);
        }

        return found;
      });

      if (sortKey) {
        entries = entries.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          entries = entries.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = entries.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = entries.length;

      return {
        permissions: entries.slice(start, start + parseInt(perPage, 10)),
        meta: { page: page, total: total }
      };
    });

    this.get("/permissions/:id", function (db, request) {
      return {
        permissions: db["permissions"].find(request.params.id)
      };
    });

    this.post("/permissions");
    this.delete("/permissions/2", { error: true }, 500);
    this.delete("/permissions/:id");
    this.put("/permissions/:id");

    this.get("/calendar-projects");
    this.get("/calendar-project-types");

    this.get("/calendar-projects/:id", function (db, request) {
      return {
        "calendar-projects": db["calendar-projects"].find(request.params.id)
      };
    });

    this.delete("/calendar-projects/20", { error: true }, 500);
    this.delete("/calendar-projects/:id");
    this.put("/calendar-projects/:id");

    this.get("/calendar-appointments", function (db, request) {
      var _request$queryParams9 = request.queryParams,
          page = _request$queryParams9.page,
          perPage = _request$queryParams9.perPage,
          sortDir = _request$queryParams9.sortDir,
          sortKey = _request$queryParams9.sortKey,
          recordId = _request$queryParams9.recordId,
          ids = _request$queryParams9.ids;

      var query = request.queryParams["search[query]"];

      if (ids) {
        ids = ids.map(function (id) {
          return +id;
        });
      }

      var appointments = db["calendar-appointments"].filter(function (asset) {
        var found = true;

        if (query) {
          found = asset.alias.match(query);
        }

        if (ids) {
          return ids.includes(asset.id);
        }

        return query && found || !query;
      });

      if (sortKey) {
        appointments = appointments.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          appointments = appointments.reverse();
        }
      }

      if (!perPage) {
        perPage = 18;
      }

      if (!page) {
        var position = appointments.mapBy("id").indexOf(+recordId) + 1;

        page = max(1, ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = appointments.length;

      if (!ids) {
        appointments = appointments.slice(start, start + parseInt(perPage, 10));
      }

      return {
        "calendar-appointments": appointments,
        meta: { page: page, total: total }
      };
    });
    this.get("/calendar-appointments/:id", function (db, request) {
      return {
        "calendar-appointments": db["calendar-appointments"].find(request.params.id)
      };
    });

    this.post("/calendar-appointments", function (db, request) {
      var payload = JSON.parse(request.requestBody).calendarAppointment;
      var appointment = db["calendar-appointments"].insert(payload);

      return {
        "calendar-appointment": appointment
      };
    });

    this.put("/calendar-appointments/:id", function (db, request) {
      var id = request.params.id,
          attrs = JSON.parse(request.requestBody)["calendarAppointment"];

      return {
        "calendar-appointment": db["calendar-appointments"].update(id, attrs)
      };
    });

    this.delete("/calendar-appointments/:id");

    this.post("/search/global", function (db, request) {
      var body = JSON.parse(request.requestBody);
      if (body.query === "Galileo") {
        return (0, _globalSearchInquriesResult.default)(body);
      } else {
        return (0, _globalSearchResult.default)(body);
      }
    });

    this.post("/search/index", function (db, request) {
      var body = JSON.parse(request.requestBody);
      if (body.name === "stores") {
        return { success: false };
      } else {
        return { success: true };
      }
    });

    this.post("/search/delete", function (db, request) {
      var body = JSON.parse(request.requestBody);
      if (body.name === "stores") {
        return { success: false };
      } else {
        return { success: true };
      }
    });

    this.get("/reminders", function (db, request) {
      var _request$queryParams10 = request.queryParams,
          page = _request$queryParams10.page,
          perPage = _request$queryParams10.perPage,
          sortDir = _request$queryParams10.sortDir,
          sortKey = _request$queryParams10.sortKey,
          recordId = _request$queryParams10.recordId;

      var query = request.queryParams["search[query]"],
          entries = db["reminders"].filter(function (entry) {
        var found = true;

        if (query) {
          found = entry.title.match(query);
        }

        return found;
      });

      if (sortKey) {
        entries = entries.sortBy(sortKey);

        if (sortDir.toLowerCase() === "desc") {
          entries = entries.reverse();
        }
      }

      if (!perPage) {
        perPage = _remotePagination.PER_PAGE;
      }

      if (!page) {
        var position = entries.mapBy("id").indexOf(+recordId) + 1;

        page = Math.max(1, Math.ceil(position / perPage));
      }

      var start = (page - 1) * perPage,
          total = entries.length;

      return {
        reminders: entries.slice(start, start + parseInt(perPage, 10)),
        "knb-entries": db["knb-entries"],
        contacts: db.contacts,
        assets: db.assets,
        stores: db.stores,
        "press-requests": db["press-requests"],
        meta: { page: page, total: total }
      };
    });

    this.get("/reminders/:id", function (db, request) {
      return {
        reminder: db["reminders"].find(request.params.id),
        "knb-entries": db["knb-entries"],
        contacts: db.contacts,
        assets: db.assets,
        stores: db.stores,
        "press-requests": db["press-requests"]
      };
    });

    this.post("/reminders", function (db, request) {
      var payload = JSON.parse(request.requestBody).reminder;
      var reminder = db["reminders"].insert(payload);

      return {
        reminder: reminder
      };
    });
    this.delete("/reminders/2", { error: true }, 500);
    this.delete("/reminders/:id");
    this.put("/reminders/:id");
  };

  var assetFactory = new _asset.default(),
      shareFactory = new _share.default(),
      assetSequence = 1,
      shareSequence = 1;

  var ceil = Math.ceil,
      max = Math.max;
});
define("bizbase3/pods/components/link-assets-button/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend(_notable.default, {
    classNames: "link-assets-button",

    actions: {
      link: function link() {
        var _this = this;

        var ids = this.get("ids");

        if (!ids.match(/[^0-9, ]/)) {
          var idArray = ids.replace(/\s+/g, "").split(",");
          var store = this.get("store");
          var onLink = this.get("onLink");

          store.findByIds("asset", idArray).then(onLink).then(function () {
            return _this.success("modules.filemanager.notify.linkAssets.success");
          }).catch(function () {
            return _this.error("modules.filemanager.notify.linkAssets.errors");
          });
        }
      }
    },

    showInput: false,

    store: service()
  });
});
define("bizbase3/instance-initializers/error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var on = Ember.RSVP.on;
  var keys = Object.keys;


  function notifyErrorhandler(notify) {
    return function (error) {
      if (error && error.errors) {
        error.errors.forEach(function (e) {
          var html = ["<strong>" + e.title + "</strong> (Code" + e.code + ")", "" + e.detail];

          if (e.links) {
            html.addObject(keys(e.links).map(function (link) {
              return "<a href=\"" + e.links[link] + "\" target=\"_blank\">" + link + "</a>";
            }).join(" | "));
          }
          if (e.code) {
            notify.error({ html: html.join("<br>"), closeAfter: 15000 });
          }
        });
      }
    };
  }

  function initialize(application) {
    on("error", notifyErrorhandler(application.lookup("service:notify")));
  }

  exports.default = { name: "error", initialize: initialize };
});
define("bizbase3/services/ajax", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      service = Ember.inject.service,
      Promise = Ember.RSVP.Promise;
  exports.default = Ember.Service.extend({
    session: service(),

    authorized: function authorized(url, options) {
      var _this = this;

      if (!options) {
        options = {};
      }

      return new Promise(function (resolve, reject) {
        _this.get("session").authorize("authorizer:bizbase", function (header, value) {
          options.headers = options.headers || {};
          options.headers[header] = value;
          options.url = url;

          $.ajax(options).then(resolve, reject);
        });
      });
    }
  });
});
define("bizbase3/components/pagination-pages", ["exports", "bizbase3/mixins/remote-pagination"], function (exports, _remotePagination) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed;

  exports.default = Ember.Component.extend({
    actions: {
      prevPage: function prevPage() {
        var prevPage = this.get("prevPage");
        if (prevPage) {
          this.changePage(prevPage);
        }
      },
      nextPage: function nextPage() {
        var nextPage = this.get("nextPage");
        if (nextPage) {
          this.changePage(nextPage);
        }
      },
      firstPage: function firstPage() {
        this.changePage(1);
        this.set("page", 1);
      },
      lastPage: function lastPage() {
        this.changePage(this.get("totalPages"));
      },
      openPage: function openPage(page) {
        this.changePage(page);
      }
    },

    classNames: ["pagination", "pagination-sm"],

    page: 1,

    perPage: _remotePagination.PER_PAGE,

    total: 0,

    padding: 2,

    seperator: "...",

    tagName: "ul",

    totalPages: computed("total", "perPage", function () {
      return Math.max(1, Math.ceil(parseFloat(this.get("total")) / this.get("perPage")));
    }),

    pages: computed("totalPages", "page", "seperator", "throttle", function () {
      var pages = [],
          totalPages = this.get("totalPages"),
          current = this.get("page"),
          padding = this.get("padding"),
          seperator = this.get("seperator"),
          start = current - (padding + Math.max(0, padding - totalPages + current)),
          end = current + (padding + Math.max(0, padding - current + 1)),
          leftEdge = start - 1,
          rightEdge = end + 1,
          currentFarLeft = current - padding <= 1,
          currentFarRight = current + padding >= totalPages;

      function goodPage(page) {
        return page === 1 || page === totalPages || page >= start && page <= end || currentFarLeft && page === rightEdge || currentFarRight && page === leftEdge;
      }

      function isSeperated(page) {
        return currentFarLeft && page === rightEdge && totalPages - padding > rightEdge || currentFarRight && page === leftEdge && padding < leftEdge || !currentFarRight && page === start && start > 2 || !currentFarLeft && page === end && totalPages - 1 > end;
      }

      for (var page = 1; page <= totalPages; page++) {
        if (goodPage(page)) {
          if (isSeperated(page)) {
            pages.push(seperator);
          } else {
            pages.push(page);
          }
        }
      }

      return pages.map(function (page) {
        if (page === seperator) {
          return { label: seperator };
        }

        return { page: page, active: page === current };
      });
    }),

    prevPage: computed("page", function () {
      var page = this.get("page");

      if (page > 1) {
        return page - 1;
      }
    }),

    nextPage: computed("page", "totalPages", function () {
      var page = this.get("page");

      if (page < this.get("totalPages")) {
        return page + 1;
      }
    }),

    isFirstPage: computed.equal("page", 1),

    isLastPage: computed("page", "totalPages", function () {
      return this.get("page") === this.get("totalPages");
    }),

    changePage: function changePage(page) {
      if (page !== this.get("page")) {
        if (typeof this.get("onPageChange") === "function") {
          this.get("onPageChange")(page);
        } else {
          this.set("page", page);
        }
      }
    }
  });
});
define("bizbase3/initializers/datepicker", ["exports", "ember-cli-bootstrap-datepicker/components/datepicker-support"], function (exports, _datepickerSupport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    _datepickerSupport.default.reopen({
      todayBtn: true,
      todayHighlight: true
    });
  }

  exports.default = {
    name: "datepicker",
    initialize: initialize
  };
});
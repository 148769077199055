define("bizbase3/instance-initializers/bugsnag", ["exports", "bizbase3/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  function getContext(router) {
    var infos = router.currentState.routerJsState.handlerInfos;

    var url = router.get("location").getURL();
    var routeName = infos.length > 0 ? infos[infos.length - 1].name : "unknown route";

    var firstSegments = routeName.replace(".index", "").replace(/\./g, " ");
    var prettyRouteName = Ember.String.capitalize(firstSegments);

    return prettyRouteName + " (" + routeName + ", " + url + ")";
  }

  function initialize(application) {
    if (!_environment.default.bugsnag) {
      return;
    }

    Bugsnag.apiKey = _environment.default.bugsnag.API_KEY;
    Bugsnag.appVersion = _environment.default.APP.VERSION;
    Bugsnag.releaseStage = _environment.default.environment;
    Bugsnag.notifyReleaseStages = _environment.default.bugsnag.releaseStages;

    function errorHandler(name) {
      return function (error) {
        Bugsnag.context = getContext(application.lookup("router:main"));
        Bugsnag.notifyException(error, name, {}, "error");
        // eslint-disable-next-line no-console
        console.error(error.stack); // jshint ignore: line
      };
    }

    if (_environment.default.environment !== "test") {
      Ember.onerror = errorHandler("Ember Error");
      Ember.RSVP.on("error", errorHandler("RSVP Error"));

      Ember.Logger.error = function (error) {
        Bugsnag.context = getContext(application.lookup("router:main"));

        if (!(error instanceof Error)) {
          error = new Error(error.stack);
        }

        Bugsnag.notifyException(error, error.message, {}, "error");
        // eslint-disable-next-line no-console
        console.error(error.stack); // jshint ignore: line
      };

      var router = application.lookup("router:main");
      var originalDidTransition = router.didTransition || Ember.K;
      router.didTransition = function () {
        Bugsnag.refresh();
        return originalDidTransition.apply(this, arguments);
      };
    }
  }

  exports.default = {
    name: "bugsnag",
    initialize: initialize
  };
});
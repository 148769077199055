define("bizbase3/pods/dashboard/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      hash = Ember.RSVP.hash;
  exports.default = Ember.Route.extend({
    ajax: service(),

    model: function model() {
      var store = this.store,
          query = { page: 1, perPage: 5, sortKey: "id", sortDir: "desc" };

      return hash({
        stats: this.get("ajax").authorized("/api/v3/dashboard/stats"),
        knbEntries: store.query("knb-entry", {
          page: 1,
          perPage: 10,
          sortKey: "id",
          sortDir: "desc"
        }),
        contacts: store.query("contact", query)
      });
    }
  });
});
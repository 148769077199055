define("bizbase3/pods/groups/new/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get("store"),
          group = store.createRecord("group");
      return group;
    }
  });
});
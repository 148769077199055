define("bizbase3/authenticators/bizbase", ["exports", "ember-simple-auth/authenticators/base", "bizbase3/session-stores/application"], function (exports, _base, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var Promise = Ember.RSVP.Promise,
      computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      isEmpty = Ember.isEmpty,
      $ = Ember.$;
  exports.default = _base.default.extend({
    restore: function restore(data) {
      return new Promise(function (resolve, reject) {
        if (!isEmpty(get(data, _application.KEY))) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(username, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        $.ajax({
          type: "POST",
          url: _this.get("loginURL"),
          data: JSON.stringify({ username: username, password: password }),
          contentType: "application/json"
        }).then(function (_ref) {
          var token = _ref.session.token;

          var data = jwt_decode(token);

          resolve(_defineProperty({
            permissions: data.permissions,
            uid: data.uid
          }, _application.KEY, token));
        }).fail(reject);
      });
    },


    loginURL: computed(function () {
      return this.get("store").adapterFor("login").buildURL("session");
    }),

    store: inject.service()
  });
});
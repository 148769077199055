define("bizbase3/mixins/download-action", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isArray = Ember.isArray;

  var $ = Ember.$;

  var inject = Ember.inject;

  exports.default = Ember.Mixin.create({
    actions: {
      download: function download(ids, quality) {
        var jwt = this.get("session.data.authenticated.jwt");

        if (!isArray(ids)) {
          ids = [ids];
        }

        if (isNaN(parseInt(quality)) || quality < 0 || quality > 4) {
          quality = 4;
        }

        // TODO: get url from adapter
        $.fileDownload("/api/v3/assets/download", {
          httpMethod: "POST",
          data: { ids: ids, quality: quality, jwt: jwt }
        });
      }
    },

    session: inject.service()
  });
});
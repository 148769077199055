define("bizbase3/helpers/confirm", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.confirm = confirm;
  var inject = Ember.inject;
  function confirm(params, _ref) {
    var _this = this;

    var message = _ref.message,
        _ref$onOk = _ref.onOk,
        onOk = _ref$onOk === undefined ? function () {} : _ref$onOk,
        _ref$onCancel = _ref.onCancel,
        onCancel = _ref$onCancel === undefined ? function () {} : _ref$onCancel;

    return function () {
      return _this.get("modalDialog").confirm(message).then(onOk, onCancel);
    };
  }

  exports.default = Ember.Helper.extend({
    compute: confirm,

    modalDialog: inject.service()
  });
});
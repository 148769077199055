define("bizbase3/pods/contacts/route", ["exports", "bizbase3/mixins/categorized-route", "bizbase3/utils/pagination-array"], function (exports, _categorizedRoute, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_categorizedRoute.default, {
    missingCategoryRoute: "contact-categories",

    categoriesControllerName: "contact-categories",

    contactsColumns: inject.service(),

    contactGroups: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("contactsColumns").load();
    },
    model: function model(params) {
      var contactsQuery = {
        category_id: params.category_id,
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      return hash({
        category: this.modelFor("contact-categories").findBy("id", params.category_id),
        contacts: (0, _paginationArray.makePaginationQuery)(this.get("store"), "contact", contactsQuery, params.page, params.perPage)
      });
    }
  });
});
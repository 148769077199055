define("bizbase3/mirage/fixtures/calendar-project-types", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 1,
    title: "Kalendar"
  }, {
    id: 2,
    title: "System"
  }];
});
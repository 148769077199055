define("bizbase3/mixins/confirm-exit-when-model-is-dirty", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var CONFIRM_TEXT = "Es gibt ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?";

  exports.default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        if (this.showConfirmDialog()) {
          transition.abort();

          // TODO: event is fired twice
          if (!this.get("isConfirming")) {
            this.get("modalDialog").confirm(CONFIRM_TEXT).then(function () {
              _this.set("hasConfirmedExit", true);
              transition.retry();
            });
          }
        } else {
          return true;
        }
      },
      didTransition: function didTransition() {
        this.set("hasConfirmedExit", false);
      }
    },

    modalDialog: inject.service(),

    hasConfirmedExit: false,

    isConfirming: computed.reads("modalDialog.isOpenDialog"),

    /**
     * This needs to be a function as we cannot bind to `currentModel`
     *
     * @function showConfirmDialog
     */
    showConfirmDialog: function showConfirmDialog() {
      return this.currentModel.get("hasDirtyAttributes") && !this.get("hasConfirmedExit");
    }
  });
});
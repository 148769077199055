define("bizbase3/pods/components/press-request-header-dropdown/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    tagName: "li",

    classNames: ["notifications", "dropdown"],

    store: service(),

    fetchdata: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get("fetchdata")) {
        this.get("store").query("press-request", {
          page: 1,
          perPage: 20,
          multiSort: [{
            sortKey: "state",
            sortDir: "asc"
          }, {
            sortKey: "receipt_date",
            sortDir: "desc"
          }]
        }).then(function (data) {
          _this.set("requests", data.sortBy("state"));
        }, function (error) {
          _this.set("error", error);
        });
      }
    },

    badgeCount: computed("requests", function () {
      if (this.get("requests")) {
        return this.get("requests").filterBy("state", 0).length;
      } else {
        return 0;
      }
    })
  });
});
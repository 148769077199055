define("bizbase3/pods/components/contact-create-overlay/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      get = Ember.get;
  exports.default = Ember.Component.extend(_notable.default, {
    actions: {
      open: function open() {
        this.set("contactModel", this.get("store").createRecord("contact"));
        this.set("showModal", true);
      },
      cancelNewContact: function cancelNewContact() {
        var newContact = this.get("contactModel");

        if (newContact && !newContact.get("id")) {
          newContact.deleteRecord();
        }

        this.set("contactModel", null);
        this.set("showModal", false);
      },
      saveContact: function saveContact(data) {
        var _this = this;

        var contact = this.get("contactModel");

        contact.setProperties(data);

        var fullName = get(contact, "fullName"),
            promise = contact.save();

        promise.then(function () {
          _this.set("showModal", false);
          _this.afterSave(contact);

          _this.success(fullName + " erfolgreich gespeichert.");
        }).catch(function (e) {
          //eslint-disable-next-line no-console
          console.error(e); // jshint ignore: line
          //contact.rollbackAttributes();
          _this.error("Fehler beim Speichern von " + fullName + ".");
        }).finally(function () {
          if (_this.finallySave) {
            _this.finallySave(contact);
          }
        });

        return promise;
      }
    },
    store: service()
  });
});
define("bizbase3/components/data-table", ["exports", "bizbase3/mixins/sortable", "ember-local-storage"], function (exports, _sortable, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      computed = Ember.computed,
      on = Ember.on,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    actions: {
      columnResized: function columnResized(index, newWidth, newNextWidth) {
        var rowColumn = this.$().closest("table").find("td:nth-child(" + (index + 1) + ")");
        var storageObjectName = "columnsSettings." + this.get("storageName");

        var settings = this.get(storageObjectName);

        if (!settings) {
          settings = this.set(storageObjectName, []);
        }

        if (settings) {
          if (!settings[index]) {
            settings[index] = {};
          }
          if (!settings[index + 1]) {
            settings[index + 1] = {};
          }

          settings[index].width = newWidth;
          settings[index + 1].width = newNextWidth;
          this.set(storageObjectName, settings);
        }

        if (rowColumn[0]) {
          rowColumn.width(newWidth);
          rowColumn.next().width(newNextWidth);
        }
      }
    },

    classNames: ["table", "table-bordered", "table-striped", "table-condensed", "table-hover"],

    tagName: "table",

    storageName: "table",

    collection: computed(function () {
      return [];
    }),

    columns: computed(function () {
      return [];
    }),

    columnsSettings: (0, _emberLocalStorage.storageFor)("columns"),

    sortKey: null,

    sortDir: null,

    sortAsc: computed.equal("sortDir", _sortable.ASC),

    sortDesc: computed.equal("sortDir", _sortable.DESC),

    onSelect: function onSelect() {},
    getColumns: function getColumns() {
      var columns = this.$("th");

      if (!columns) {
        return [];
      }

      // use .toArray() because jQuery
      return columns.toArray();
    },


    syncColumns: on("didRender", function () {
      var _this = this;

      var columns = this.getColumns();

      columns.forEach(function (th) {
        var $th = $(th);
        var index = $th.index();
        var width = $th.width();

        _this._syncRows(width, index);
      });
    }),

    setWidth: on("didInsertElement", function () {
      function setWidth() {
        var dataTable = this.$().closest(".data-table");
        dataTable.width(dataTable.width());

        this.restoreColumns();
      }

      run.schedule("afterRender", this, setWidth);
    }),

    restoreColumns: function restoreColumns() {
      var _this2 = this;

      var columns = this.getColumns();
      var key = "columnsSettings." + this.get("storageName");
      var storageSettings = this.get(key);

      if (!storageSettings || storageSettings.length !== columns.length) {
        storageSettings = this.set(key, columns.map(function (th) {
          return { width: $(th).width() };
        }));
      }

      columns.forEach(function (th) {
        var $th = $(th);
        var index = $th.index();

        var width = $th.width();

        if (storageSettings && storageSettings[index] && storageSettings[index].width) {
          width = storageSettings[index].width;
        }

        $th.width(width);
        _this2._syncRows(width, index);
      });
    },
    _syncRows: function _syncRows(width, index) {
      var rowColumns = this.$().closest("table").find("td:nth-child(" + (index + 1) + ")");

      if (rowColumns.length) {
        rowColumns.width(width);
      }
    }
  });
});
define("bizbase3/pods/components/conversation-message/component", ["exports", "bizbase3/mixins/notable"], function (exports, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Ember.Component.extend(_notable.default, {
    actions: {
      submit: function submit(e) {
        var _this = this;

        e.preventDefault();

        var _getProperties = this.getProperties("onUpdate", "message", "data", "newAuthorId"),
            onUpdate = _getProperties.onUpdate,
            message = _getProperties.message,
            data = _getProperties.data,
            newAuthorId = _getProperties.newAuthorId;

        if (newAuthorId) {
          var author = this.get("contacts.data").findBy("id", newAuthorId);
          set(data, "author", author);
        }

        onUpdate(message, data).then(function () {
          _this.success("Nachricht erfolgreich bearbeitet");
          _this.set("isEditing", false);
        }).catch(function () {
          return _this.success("Fehler beim Bearbeiten der Nachricht");
        });
      }
    },

    widgetPositioning: { vertical: "bottom" },

    classNames: ["conversation-message"],

    data: computed(function () {
      return {};
    }),

    contacts: service(),

    isEditing: false
  });
});
define("bizbase3/pods/press-request/route", ["exports", "bizbase3/mixins/detail-route"], function (exports, _detailRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_detailRoute.default, {
    beforeModel: function beforeModel() {
      return this.get("contacts").retrieve();
    },


    contacts: service(),
    masterControllerName: "press-requests",

    renderTemplate: function renderTemplate() {
      this.render({ into: "protected" });
    }
  });
});
define("bizbase3/pods/settings/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel() {
      //this.transitionTo('settings.overview');
    }
  });
});
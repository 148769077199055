define("bizbase3/pods/groups/new/controller", ["exports", "bizbase3/mixins/group-controller"], function (exports, _groupController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_groupController.default, {
    afterSave: function afterSave() {
      this.get("groups").updateEntries();
      this.transitionToRoute("group", this.get("model"));
    }
  });
});
define("bizbase3/mirage/factories/press-request", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    receiptDate: _emberCliMirage.faker.date.past,
    publicationDate: _emberCliMirage.faker.date.past,
    respondUntil: _emberCliMirage.faker.date.future,
    doneAt: function doneAt() {
      return _emberCliMirage.faker.random.arrayElement([null, _emberCliMirage.faker.date.past()]);
    },
    state: function state() {
      return _emberCliMirage.faker.random.number(3);
    },
    note: function note() {
      return _emberCliMirage.faker.lorem.paragraphs();
    },

    isFinished: _emberCliMirage.faker.random.boolean,

    category: null,
    contact: null,
    duration: null,
    team: null,
    personCount: null,
    type: "press",
    externalContact: null,
    responsibleContact: null,
    tags: [],
    messages: [],
    assets: []
  });
});
define("bizbase3/pods/components/thumb-resizer/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var observer = Ember.observer;

  var on = Ember.on;

  exports.default = Ember.TextField.extend({
    attributeBindings: ["max", "min", "step", "value", "handle"],

    getSliderConfig: function getSliderConfig() {
      return {
        min: this.get("min"),
        step: this.get("step"),
        max: this.get("max"),
        value: this.get("value"),
        handle: this.get("handle"),
        formatter: this.get("formatter"),
        tooltip: this.get("tooltip") ? this.get("tooltip") : "true"
      };
    },


    updateValue: observer("value", function () {
      this.get("_slider").slider(this.getSliderConfig());
    }),

    setupSlider: on("didInsertElement", function () {
      var _this = this;

      var slider = this.$().slider(this.getSliderConfig());

      this.set("_slider", slider);

      var setValue = function setValue() {
        _this.set("value", slider.slider("getValue"));
      };

      slider.on("slide", setValue);

      slider.on("slideStop", setValue);
    }),

    destroySlider: on("willDestroyElement", function () {
      this.get("_slider").off("slide");
      this.get("_slider").off("slideStop");
    })
  });
});
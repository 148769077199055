define("bizbase3/pods/calendar-projects/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend({
    calendarProjects: service(),

    beforeModel: function beforeModel() {
      return this.get("calendarProjects").retrieve();
    },


    model: function model() {
      return RSVP.hash({
        appointments: this.get("store").findAll("calendar-appointment"),
        projects: this.get("store").findAll("calendar-project"),
        projectTypes: this.get("store").findAll("calendar-project-type")
      });
    }
  });
});
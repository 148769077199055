define("bizbase3/mixins/pagination", ["exports", "bizbase3/mixins/remote-pagination"], function (exports, _remotePagination) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var computed = Ember.computed;

  exports.default = Ember.Mixin.create({
    page: 1,

    pages: computed("perPage", "total", function () {
      return Math.ceil(this.get("total") / this.get("perPage"));
    }),

    perPage: _remotePagination.PER_PAGE,

    paginatedCollection: computed("sortedCollection.[]", "page", "perPage", "total", function () {
      var collection = this.get("sortedCollection"),
          perPage = this.get("perPage"),
          offset = perPage * (this.get("page") - 1);

      if (isNaN(offset) || offset < 0 || offset + 1 > this.get("total")) {
        offset = 0;
      }

      return collection.slice(offset, offset + perPage);
    }),

    total: computed.reads("collection.length"),

    from: computed("page", "perPage", function () {
      var page = this.get("page");
      return 1 + (page - 1) * this.get("perPage");
    }),

    to: computed("from", "perPage", "total", function () {
      return Math.min(this.get("total"), this.get("from") + this.get("perPage") - 1);
    }),

    pagination: computed.gt("pages", 1)
  });
});
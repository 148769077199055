define("bizbase3/mixins/assets-controller", ["exports", "bizbase3/mixins/item-selection", "bizbase3/mixins/notable"], function (exports, _itemSelection, _notable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      RSVP = Ember.RSVP,
      inject = Ember.inject,
      set = Ember.set;
  var keys = Object.keys;


  function assignTags(item, properties) {
    var tags = get(properties, "tags");

    if (get(properties, "replaceTags")) {
      set(item, "tags", tags);
    } else {
      get(item, "tags").pushObjects(tags);
    }
  }

  exports.default = Ember.Mixin.create(_notable.default, _itemSelection.default, {
    i18n: inject.service(),

    actions: {
      batchSave: function batchSave(properties) {
        var _this = this;

        var items = this.get("selectedItems");

        this.set("isPending", true);

        RSVP.all(items.map(function (item) {
          keys(properties).forEach(function (key) {
            if (key === "tags") {
              return;
            }

            if (get(properties, key)) {
              set(item, key, get(properties, key));
            }
          });

          if (get(properties, "tags")) {
            assignTags(item, properties);
          }

          if (get(properties, "hasUnlimitedAccess")) {
            set(item, "expires_at", null);
          }

          return item.save();
        })).then(function () {
          _this.success("modules.filemanager.notify.batchSuccess", { count: items.length });
        }).catch(function () {
          _this.error("modules.filemanager.notify.batchError");
        }).finally(function () {
          _this.set("isPending", false);
        });
      },
      addToAlbum: function addToAlbum(assets) {
        var _this2 = this;

        var store = this.get("store");

        var albumPromise = void 0;

        this.set("isPending", true);

        if (arguments.length === 2) {
          albumPromise = store.findRecord("album", arguments[1]);
        } else {
          albumPromise = store.createRecord("album", { title: arguments[2] }).save();
        }

        var promise = albumPromise.then(function (album) {
          // TODO: won't work with RESTSerializer nor with JSONApiSerializer
          // see: http://discuss.emberjs.com/t/hasmany-relationship-not-being-saved/8747/3
          // return album.get("assets").then(albumAssets => {
          //   albumAssets.addObjects(assets);
          //   return album.save();
          // });
          return RSVP.all(assets.map(function (asset) {
            asset.set("album", album);
            return asset.save();
          }));
        });

        promise.then(function () {
          _this2.success("modules.filemanager.notify.addedToAlbum", assets.length);
        }).catch(function () {
          _this2.error("modules.filemanager.notify.batchError");
        }).finally(function () {
          _this2.set("isPending", false);
        });

        return promise;
      },
      linkAssets: function linkAssets(assets) {
        var model = this.get("model");

        get(model, "assets").addObjects(assets);

        return model.save();
      },
      removeAssets: function removeAssets(model) {
        var _this3 = this;

        var selection = this.get("selectedItems"),
            count = get(selection, "length");

        this.set("isPending", true);

        get(model, "assets").removeObjects(selection);
        return model.save().then(function () {
          _this3.set("selection", []);
          _this3.success("modules.filemanager.notify.deleted", { count: count });
        }).catch(function () {
          return _this3.error("modules.filemanager.notify.errorDelete");
        }).finally(function () {
          return _this3.set("isPending", false);
        });
      }
    }
  });
});
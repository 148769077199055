define("bizbase3/pods/assets/new/controller", ["exports", "bizbase3/mixins/drop-upload-controller"], function (exports, _dropUploadController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var controller = Ember.inject.controller;
  exports.default = Ember.Controller.extend(_dropUploadController.default, {
    parentRoute: "assets",

    didFinishUpload: function didFinishUpload() {
      this._super();
      this.get("assets").updateEntries();
    },


    assets: controller()
  });
});
define("bizbase3/pods/groups/route", ["exports", "bizbase3/utils/pagination-array"], function (exports, _paginationArray) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash,
      inject = Ember.inject;
  exports.default = Ember.Route.extend({
    groupsColumns: inject.service(),

    beforeModel: function beforeModel() {
      return this.get("groupsColumns").load();
    },
    model: function model(params) {
      var groupQuery = {
        sortKey: params.sortKey,
        sortDir: params.sortDir
      };

      return hash({
        groups: (0, _paginationArray.makePaginationQuery)(this.get("store"), "group", groupQuery, params.page, params.perPage)
      });
    }
  });
});
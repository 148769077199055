define("bizbase3/pods/knb-entry/index/controller", ["exports", "bizbase3/mixins/notable", "bizbase3/utils/computed/promise-array", "bizbase3/utils/computed/select-options"], function (exports, _notable, _promiseArray, _selectOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend(_notable.default, {
    actions: {
      saveEntry: function saveEntry() {
        var _this = this;

        if (this.get("userId") !== this.get("model.user.id")) {
          this.set("model.user", this.store.peekRecord("user", this.get("userId")));
        }

        this.get("model").save().then(function () {
          _this.success("modules.knb.notify.saveSuccess");
        }).catch(function () {
          _this.error("modules.knb.notify.saveFailed");
        });
      },
      deleteEntry: function deleteEntry() {
        var _this2 = this;

        var categoryId = this.get("model.category.id");

        this.get("model").destroyRecord().then(function () {
          _this2.transitionToRoute("knb-entries", categoryId);
          _this2.success("modules.knb.notify.deleted", { count: 1 });
        }).catch(function () {
          return _this2.error("modules.knb.notify.deleteFailed");
        });
      }
    },

    usersProxy: (0, _promiseArray.default)("user"),

    users: (0, _selectOptions.default)("usersProxy", "id", "fullName"),

    userId: computed("model.user.id", {
      get: function get() {
        return this.get("model.user.id");
      },
      set: function set(key, value) {
        return value;
      }
    })
  });
});
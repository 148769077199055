define("bizbase3/mirage/factories/calendar-project", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var colors = ["bg-navy", "bg-blue", "bg-aqua", "bg-teal", "bg-olive", "bg-green", "bg-lime", "bg-yellow", "bg-orange", "bg-red", "bg-fuchsia", "bg-purple", "bg-maroon", "bg-gray", "bg-silver", "bg-black"];

  exports.default = _emberCliMirage.default.Factory.extend({
    title: function title() {
      var words = _emberCliMirage.faker.lorem.words(3).join(" ");
      return words.charAt(0).toUpperCase() + words.slice(1);
    },
    color: function color() {
      return _emberCliMirage.faker.random.arrayElement(colors);
    },
    calendarType: function calendarType() {
      return 1;
    }
  });
});
define("bizbase3/helpers/increment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.increment = increment;
  function increment(value) {
    return parseInt(value) + 1;
  }

  exports.default = Ember.Helper.helper(increment);
});
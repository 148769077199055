define("bizbase3/mixins/categorized-route", ["exports", "bizbase3/mixins/detail-route"], function (exports, _detailRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      set = Ember.set;
  exports.default = Ember.Mixin.create(_detailRoute.default, {
    afterModel: function afterModel(resolvedModel) {
      if (!get(resolvedModel, this.get("detailModelKey"))) {
        this.transitionTo(this.get("missingCategoryRoute"));
      }
    },


    /**
     * The app will transition to this route in `afterModel`,
     * if the resolvedModel's `category`-property is not set.
     *
     * @property missingCategoryRoute
     * @type {String}
     * @default "index"
     */
    missingCategoryRoute: "index",

    detailModelKey: "category",

    masterControllerName: computed.reads("categoriesControllerName"),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (!isEmpty(get(controller, "selection"))) {
        set(controller, "selection", []);
      }
    }
  });
});
define("bizbase3/utils/data-table-helpers", ["exports", "bizbase3/helpers/date-time", "bizbase3/helpers/size"], function (exports, _dateTime, _size) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.size = exports.dateTime = undefined;


  var DataTableHelpers = {
    "date-time": _dateTime.dateTime,
    size: _size.size
  };

  exports.dateTime = _dateTime.dateTime;
  exports.size = _size.size;
  exports.default = DataTableHelpers;
});
define("bizbase3/mixins/user-controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      controller = Ember.inject.controller;
  exports.default = Ember.Mixin.create({
    actions: {
      createNewUser: function createNewUser() {
        this.set("userModel", this.get("store").createRecord("user"));
      },
      cancelNewUser: function cancelNewUser() {
        var newUser = this.get("userModel");

        if (newUser) {
          newUser.deleteRecord();
        }

        this.set("userModel", null);
      },
      saveUser: function saveUser(data) {
        var _this = this;

        var user = this.get("userModel");

        user.setProperties(data);

        var firstName = data.firstName,
            lastName = data.lastName;

        var promise = user.save({ adapterOptions: { contact: { firstName: firstName, lastName: lastName } } });

        promise.then(function () {
          return _this.afterSave(user);
        }).catch(function (e) {
          user.rollbackAttributes();
          throw e;
        }).finally(function () {
          return _this.finallySave(user);
        });

        return promise;
      }
    },

    users: controller(),

    userModel: computed.alias("model"),

    afterSave: function afterSave() {},
    finallySave: function finallySave() {}
  });
});
define("bizbase3/pods/components/dismissable-alert/component", ["exports", "bizbase3/mixins/has-tooltip"], function (exports, _hasTooltip) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer;


  var DEFAULT_TYPE = "info";

  var ANIMATION_DURATION = 150;

  exports.default = Ember.Component.extend(_hasTooltip.default, {
    actions: {
      close: function close() {
        this.set("visible", false);
      }
    },

    hidden: false,

    visible: true,

    showOrHide: observer("visible", function () {
      if (this.get("visible")) {
        this.show();
      } else {
        this.hide();
      }
    }),

    typeClass: computed("type", function () {
      return "alert-" + (this.get("type") || DEFAULT_TYPE);
    }),

    hide: function hide() {
      var _this = this;

      Ember.run.later(function () {
        _this.set("hidden", true);
        _this.notifyOnDismiss();
      }, ANIMATION_DURATION);
    },
    show: function show() {
      this.set("hidden", false);
    },
    notifyOnDismiss: function notifyOnDismiss() {
      var onDismiss = this.get("onDismiss");

      if (typeof onDismiss === "function") {
        onDismiss();
      }
    }
  });
});